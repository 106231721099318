import * as React from 'react';
import { observer } from "mobx-react";
import { action, observable } from 'mobx';
import { ProjectEntity } from "../../../Models/Entities";
import Tabs, { ITabConfig } from "../Tabs/Tabs";
import ProjectInformation from './ProjectInformation';
import { store } from '../../../Models/Store';
import RequestWrap from '../RequestWrap/RequestWrap';
import BreadcrumbsWrap from "../BreadcrumbsWrap/BreadcrumbsWrap";
import DetailsTab from './ProjectTabs/DetailsTab';
import ElementsTab from "./ProjectTabs/ElementsTab";
import { RouteComponentProps } from "react-router/index";
import axios from "axios";
import {SERVER_URL} from "../../../Constants";
import ShuttersTab from "./ProjectTabs/ShuttersTab";
import ProjectProvider, { IProjectContext, ProjectContext } from './ProjectContext';
import { useContext } from 'react';
import { Button, Colors } from '../Button/Button';
import formatPrice from '../../../Util/PriceUtils';
import OrdersTab from "./ProjectTabs/OrdersTab";
import SmartlookService from '../../../Services/SmartlookService';
import * as Enums from "../../../Models/Enums";
import WeightDropDown from '../WeightDropDown/WeightDropDown';

interface IProjectProps extends RouteComponentProps {
	projectId: string;
	defaultTab?: string;
}

@observer
export default class Project extends React.Component<IProjectProps, IProjectContext> {

	@observable
	private projectEntity: ProjectEntity;

	/**
	 * FIXME
	 * In order to resolve an issue with the tab component passing undefined props to the child component
	 * I create a new list each time I need to use the tabs
	 */
	private getTabs(): ITabConfig[] {
		return [
			{
				name: 'Elements',
				key: 'elements',
				component: (<ElementsTab project={this.projectEntity} {...this.props} />),
			},
			{
				name: 'Shutters',
				key: 'shutters',
				component: (<ShuttersTab project={this.projectEntity} {...this.props} />),
			},
			{
				name: 'Orders',
				key: 'orders',
				component: (<OrdersTab project={this.projectEntity} {...this.props} />),
			},
			{
				name: 'Details',
				key: 'details',
				component: (<DetailsTab project={this.projectEntity} />),
			}
		];
	}

	private renderTabs() {
		let defaultTabIndex = this.getTabs()
			.findIndex(({ key }) => key === this.props.defaultTab);

		// If it is an unknown tab, take the user to the elements page
		if (defaultTabIndex === -1) {
			defaultTabIndex = 0;
		}

		return <Tabs className='project-tab-nav'
					 tabs={this.getTabs()}
					 onTabClicked={this.changeTabUrl}
					 defaultTab={defaultTabIndex} />;
	}

	public render() {
		store.elementShutterEditMode = false;

		return (
			<RequestWrap request={this.performRequest} processData={this.setProject}>
				{() =>
					<ProjectProvider projectId={this.projectEntity.id}>
						<div className='project page'>
							<div className='project-content'>
								<CartStatus onClick={this.goToCartPage} country={this.projectEntity.country}/>
								{/* To do: pass in params when order page is ready */}
								<BreadcrumbsWrap {...this.props} project={this.projectEntity} />
								<ProjectInformation project={this.projectEntity} />
								{this.renderTabs()}
							</div>
						</div>
					</ProjectProvider>
				}
			</RequestWrap>
		);
	}
	
	private performRequest = async () => {
		const { data } =
			await axios.get(`${SERVER_URL}/api/entity/ProjectEntity/fetchProject/${this.props.projectId}`);

		let { project, assemblyCodeToDesc } = data;
		project = new ProjectEntity(project);
		store.setCodeDict(assemblyCodeToDesc);

		// Put the project in an array because it is the expected return value
		return [ project ];
	}

	@action
	private setProject = (project: ProjectEntity[]) => {
		this.projectEntity = project[0];
	};

	private goToCartPage = () => {
		// If navigating from element/shutter, prevent change if in edit mode
		let tabName = store.routerHistory.location.pathname.split('/').pop()?.slice(0, -1) ?? undefined;
		tabName = tabName == 'shutter' ? 'shutter' : 'element';

		if (tabName === 'element' || tabName === 'shutter') {
			if (store.showToastInEditMode(tabName)) {
				return;
			}
		}

		SmartlookService.triggerEvent('Delivery Instructions');
		store.routerHistory.push(`/project/${this.props.projectId}/cart`);
	}

	// Helper method to change the url when a user clicks on any of the tabbed navigations
	private changeTabUrl = (index: number) =>
		store.routerHistory.push(`/project/${this.props.projectId}/${this.getTabs()[index].key}`);
}

/**
 * Displays the current cart status
 *
 * Using a small component to prevent expensive re-renders when the context is updated
 */
export const CartStatus = (props: { onClick?: () => void, country: Enums.countries }) => {
	const context: IProjectContext = useContext(ProjectContext);

	const noItemsInCart = () => {
		return (
			<h5>
				<span className='cart-left'>Your order</span>
				<span className='grey'>(0 items)</span>
			</h5>
		);
	};

	const itemsInCart = () => {
		return (
			<>
				<h5 style={{whiteSpace: 'nowrap'}}>
					<span className='cart-left'>{context.productCount} items</span>
					<span>{formatPrice(context.price, props.country)}</span>
				</h5>

				<WeightDropDown
				totalWeight={context.totalWeight ?? 0}
				barWeight={context.barWeight ?? 0}
				componentWeight={context.componentWeight ?? 0}
				shutterWeight={context.shutterWeight ?? 0}
				containerStyle={{marginBottom: "10px"}}
			/>
				
				<Button onClick={props.onClick} colors={Colors.Tertiary}
						icon={{ icon: 'arrow-right', iconPos: 'icon-right' }}>
					Review order
				</Button>
			</>
		);
	};

	return (
		<div className='cart-status'>
			{context.productCount === 0 ? noItemsInCart() : itemsInCart()}
		</div>
	);
}