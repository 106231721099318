/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsOrderEntity } from 'Models/Security/Acl/VisitorsOrderEntity';
import { AptusAdminOrderEntity } from 'Models/Security/Acl/AptusAdminOrderEntity';
import { UserOrderEntity } from 'Models/Security/Acl/UserOrderEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IOrderEntityAttributes extends IModelAttributes {
	status: Enums.orderStatus;
	orderNumber: string;
	clientNote: string;
	aptusNote: string;
	orderDate: Date;
	totalPrice: number;
	isarchived: boolean;

	addressId?: string;
	address?: Models.AddressEntity | Models.IAddressEntityAttributes;
	priceVersionId?: string;
	priceVersion?: Models.PriceVersionEntity | Models.IPriceVersionEntityAttributes;
	projectId?: string;
	project?: Models.ProjectEntity | Models.IProjectEntityAttributes;
	productss: Array<Models.ProductEntity | Models.IProductEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OrderEntity', 'Order')
// % protected region % [Customise your entity metadata here] end
export default class OrderEntity extends Model implements IOrderEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsOrderEntity(),
		new AptusAdminOrderEntity(),
		new UserOrderEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	/**
	 * Order status
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.orderStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.orderStatusOptions),
		displayFunction: (attribute: Enums.orderStatus) => Enums.orderStatusOptions[attribute],
	})
	public status: Enums.orderStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order Number'] off begin
	/**
	 * Client job number - XXX
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Order Number',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public orderNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Order Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Note'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Client Note',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientNote: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Note'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aptus Note'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Aptus Note',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public aptusNote: string;
	// % protected region % [Modify props to the crud options here for attribute 'Aptus Note'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Order Date',
		displayType: 'datetimepicker',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public orderDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Order Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Total Price'] off begin
	/**
	 * Summed price of all producsts 
	 */
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Total Price',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public totalPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Total Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'IsArchived'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'IsArchived',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isarchived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'IsArchived'] end

	/**
	 * Order must have a delivery address
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Address'] off begin
		name: 'Address',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.AddressEntity,
		// % protected region % [Modify props to the crud options here for reference 'Address'] end
	})
	public addressId?: string;
	@observable
	@attribute({isReference: true})
	public address: Models.AddressEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Price Version'] off begin
		name: 'Price Version',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.PriceVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Price Version'] end
	})
	public priceVersionId?: string;
	@observable
	@attribute({isReference: true})
	public priceVersion: Models.PriceVersionEntity;

	/**
	 * Project has many orders
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project'] off begin
		name: 'Project',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.ProjectEntity,
		// % protected region % [Modify props to the crud options here for reference 'Project'] end
	})
	public projectId?: string;
	@observable
	@attribute({isReference: true})
	public project: Models.ProjectEntity;

	/**
	 * Order has many products (elements or shutters)
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Products'] off begin
		name: "Productss",
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.ProductEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'productss',
			oppositeEntity: () => Models.ProductEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Products'] end
	})
	public productss: Models.ProductEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrderEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrderEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.orderNumber !== undefined) {
				this.orderNumber = attributes.orderNumber;
			}
			if (attributes.clientNote !== undefined) {
				this.clientNote = attributes.clientNote;
			}
			if (attributes.aptusNote !== undefined) {
				this.aptusNote = attributes.aptusNote;
			}
			if (attributes.orderDate !== undefined) {
				if (attributes.orderDate === null) {
					this.orderDate = attributes.orderDate;
				} else {
					this.orderDate = moment(attributes.orderDate).toDate();
				}
			}
			if (attributes.totalPrice !== undefined) {
				this.totalPrice = attributes.totalPrice;
			}
			if (attributes.isarchived !== undefined) {
				this.isarchived = attributes.isarchived;
			}
			if (attributes.addressId !== undefined) {
				this.addressId = attributes.addressId;
			}
			if (attributes.address !== undefined) {
				if (attributes.address === null) {
					this.address = attributes.address;
				} else {
					if (attributes.address instanceof Models.AddressEntity) {
						this.address = attributes.address;
						this.addressId = attributes.address.id;
					} else {
						this.address = new Models.AddressEntity(attributes.address);
						this.addressId = this.address.id;
					}
				}
			}
			if (attributes.priceVersionId !== undefined) {
				this.priceVersionId = attributes.priceVersionId;
			}
			if (attributes.priceVersion !== undefined) {
				if (attributes.priceVersion === null) {
					this.priceVersion = attributes.priceVersion;
				} else {
					if (attributes.priceVersion instanceof Models.PriceVersionEntity) {
						this.priceVersion = attributes.priceVersion;
						this.priceVersionId = attributes.priceVersion.id;
					} else {
						this.priceVersion = new Models.PriceVersionEntity(attributes.priceVersion);
						this.priceVersionId = this.priceVersion.id;
					}
				}
			}
			if (attributes.projectId !== undefined) {
				this.projectId = attributes.projectId;
			}
			if (attributes.project !== undefined) {
				if (attributes.project === null) {
					this.project = attributes.project;
				} else {
					if (attributes.project instanceof Models.ProjectEntity) {
						this.project = attributes.project;
						this.projectId = attributes.project.id;
					} else {
						this.project = new Models.ProjectEntity(attributes.project);
						this.projectId = this.project.id;
					}
				}
			}
			if (attributes.productss !== undefined && Array.isArray(attributes.productss)) {
				for (const model of attributes.productss) {
					if (model instanceof Models.ProductEntity) {
						this.productss.push(model);
					} else {
						this.productss.push(new Models.ProductEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		address {
			${Models.AddressEntity.getAttributes().join('\n')}
		}
		priceVersion {
			${Models.PriceVersionEntity.getAttributes().join('\n')}
		}
		project {
			${Models.ProjectEntity.getAttributes().join('\n')}
		}
		productss {
			${Models.ProductEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			productss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'productss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.orderNumber;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public getOrderStatusDisplayText() {
		return Enums.orderStatusOptions[this.status];
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrderEntity.prototype, 'created');
CRUD(modifiedAttr)(OrderEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
