import React from 'react';
import {observer} from "mobx-react";
import { observable, runInAction } from "mobx";
import {RadioAlignment, RadioButtonGroup} from "../../RadioButton/RadioButtonGroup";
import {store} from "../../../../Models/Store";
import gql from 'graphql-tag';
import {IProjectContext, ProjectContext} from "../ProjectContext";

interface IRadioFilterOptions {
    value: string;
    display: string;
}

interface ICountQueryResult {
    all: {number: number };
    notOrdered: {number: number};
    requested: {number: number };
    confirmed: {number: number};
    hold: {number: number };
    dispatched: {number: number};
}

export interface IOrderStatusFilterProps {
    projectId: string;
    onAfterChange: (value: string) => void;
    productType: string;
}

@observer
export default class OrderStatusFilter extends React.Component<IOrderStatusFilterProps> {

    static contextType = ProjectContext;
    context!: IProjectContext;
    
    @observable
    private orderStatus = { status: 'all' };
    
    @observable 
    private countStatusResult:ICountQueryResult;

    componentDidMount() {
        this.fetchCountsByStatus();
    }

    async componentDidUpdate() {
        if (this.context.refetch) {
            this.fetchCountsByStatus();

            if (this.context.setProjectContext) {
                await this.context.setProjectContext(false, false);
            }
        }
    }
    
    private getProductCountQuery = () => {
        const queryName = this.props.productType === 'Element' ? 'countElementEntitys' : 'countShutterEntitys';

        return gql` query countProductStatus($projectId: [String]) {
            all: ${queryName}(where: [{path: "product.projectId", comparison: equal, value: $projectId}]) {
                number
            }
            notOrdered: ${queryName}(where: [{path: "product.projectId", comparison: equal, value: $projectId}, {path: "product.orderId", comparison: equal, value: null}]) {
                number
            }
            requested: ${queryName}(where: [{path: "product.projectId", comparison: equal, value: $projectId}, {path: "product.order.status", comparison: equal, value: "REQUESTED"}]) {
                number
            }
            confirmed: ${queryName}(where: [{path: "product.projectId", comparison: equal, value: $projectId}, {path: "product.order.status", comparison: equal, value: "CONFIRMED"}]) {
                number
            }
            hold: ${queryName}(where: [{path: "product.projectId", comparison: equal, value: $projectId}, {path: "product.order.status", comparison: equal, value: "HOLD"}]) {
                number
            }
            dispatched: ${queryName}(where: [{path: "product.projectId", comparison: equal, value: $projectId}, {path: "product.order.status", comparison: equal, value: "DISPATCHED"}]) {
                number
            }
        }`;
    }
    
    private fetchCountsByStatus = () => {
        store.apolloClient
            .query({
                query: this.getProductCountQuery(),
                variables: {
                    "projectId": this.props.projectId
                },
                fetchPolicy: 'network-only',
            })
            .then(results => 
                runInAction(() => this.countStatusResult = results.data)
            ).catch(err => console.error(err));
    }

    render() {
        let radioFilterOptions:IRadioFilterOptions[] = [
            { value: 'all', display: `All (${this.countStatusResult?.all.number?? '0'})` },
            { value: 'notOrdered', display: `Not Ordered (${this.countStatusResult?.notOrdered.number ?? '0'})` },
            { value: 'requested', display: `Order Requested (${this.countStatusResult?.requested.number ?? '0'})` },
            { value: 'confirmed', display: `Order Confirmed (${this.countStatusResult?.confirmed.number ?? '0'})` },
            { value: 'hold', display: `On Hold (${this.countStatusResult?.hold.number ?? '0'})` },
            { value: 'dispatched', display: `Dispatched (${this.countStatusResult?.dispatched.number ?? '0'})` },
        ];
        
        return (
            <div className='status-filter'>
                <h5>Status</h5>
                <div className="input-group input-group__radio input-group-block">
                    <RadioButtonGroup
                        model={this.orderStatus}
                        modelProperty={"status"}
                        alignment={RadioAlignment.VERTICAL}
                        options={radioFilterOptions}
                        isReadOnly={false}
                        onAfterChange={this.props.onAfterChange}
                    />
                </div>
            </div>
        );
    }

};