/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import {OrderCollection} from "../Components/Project/ProjectCollection/OrderCollection";
import {store} from "../../Models/Store";
import PurchaseOrder from "../Components/Order/PurchaseOrder";
import {Route, Switch} from 'react-router';

interface IOrderRouteProps {
	oid: string,
}
// % protected region % [Add any extra imports here] end

export interface OrderPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class OrderPage extends React.Component<OrderPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	private orderList = () => {
		return ( 
			<div className='project page'>
				<div className='orders-tab'>
					<div className='orders-list-block'>
						<h1 className="title">Orders</h1>
						<OrderCollection
							customFilter={{
								organisationId: store.organisationId ?? '',
							}}
							displayProject={true}
							displayClient={store.hasBackendAccess}
							isOrdersPage={true}				
							/>
					</div>
				</div> 
			</div>
		);
	}
	
	private purchaseOrderPage =  (pageProps: RouteComponentProps<IOrderRouteProps>) => {
		const { oid } = pageProps.match.params;

		return <PurchaseOrder orderId={oid}/>
	};
		
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'AptusAdmin', 'User']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					{
					// % protected region % [Add code for f5fc0d94-151b-41dc-bc7c-cbc512e616ab here] on begin
					}
					<Switch>
						<Route path={`${this.props.match.url}/:oid`} render={this.purchaseOrderPage} />
						<Route path={`${this.props.match.url}`} render={this.orderList} />
					</Switch>
					{
					// % protected region % [Add code for f5fc0d94-151b-41dc-bc7c-cbc512e616ab here] end
					}
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] off begin
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default OrderPage;
// % protected region % [Override export here] end
