import React from 'react';
import { TextField } from '../TextBox/TextBox';
import { IProjectEntityAttributes } from '../../../Models/Entities/ProjectEntity';
import { Combobox } from '../Combobox/Combobox';
import { countriesOptions } from '../../../Models/Enums';

export const ProjectInformationForm = ({ project, isCountryEditable } : { project: Partial<IProjectEntityAttributes>, isCountryEditable: boolean}) => {
	return (
		<>
			<TextField model={project} modelProperty='name' label='Project name' isRequired={true} />
			<TextField model={project} modelProperty='clientJobNumber' label='Job number' isRequired={true} />
			<TextField model={project} modelProperty='postcode' label='Postcode' isRequired={true} />
			<TextField model={project} modelProperty='location' label='Location' isRequired={true} />
			<Combobox
				model={project}
				modelProperty='country'
				label='Country/Region'
				isRequired={true}
				placeholder='Country/Region'
				isDisabled={!isCountryEditable}
				options={Object.entries(countriesOptions).map(([key, value]) =>
					({ display: value, value: key }))} />
		</>
	);
};
