import * as React from 'react';
import { store } from '../../../Models/Store';
import { Button, Colors, Display } from '../Button/Button';

export interface IConfirmModalOptions {
	/** The text to be placed in the confirm button */
	confirmText?: string;
	/** The text to be placed in the cancel button */
	cancelText?: string;

	beforeClose?: (confirmed: boolean) => Promise <boolean>;
}

/**
 * Opens a confirm modal which gives a user an option to confirm or cancel
 * @param title The title on the modal
 * @param message The message to display in the modal
 * @param options Extra options for the modal
 * @returns A promise that is resolved on the user confirming or rejected on the user cancelling
 */
export function confirmModal(title: string, message: React.ReactNode, options: IConfirmModalOptions = {}) {
	return new Promise<void>((resolve, reject) => {
		const onConfirm = async () => {
			if (options.beforeClose) {
				const close = await options.beforeClose(true);
				if (!close) {
					return;
				}
			}
			store.modal.hide();
			resolve();
		};

		const onCancel = () => {
			if (options.beforeClose && !options.beforeClose(false)) {
				return;
			}
			store.modal.hide();
			reject();
		};

		const confirmText = options.confirmText || 'Confirm';
		const cancelText = options.cancelText || 'Cancel';

		const confirmDom = (
			<>
				<div key="header" className="modal__header">
					<h3 key="header-item" className="modal__title">{title}</h3>
					<Button
						key="header-cancel"
						className="modal--close"
						icon={{icon: 'square-x', iconPos: 'icon-left'}}
						display={Display.Text}
						onClick={onCancel}
						labelVisible={false}>
						{cancelText}
					</Button>
				</div>
				<div key="message" className="modal__message">
					{message}
				</div>
				<div key="actions" className="modal__actions">
					<Button className="modal--cancel" key={"cancel"} onClick={onCancel} colors={Colors.Secondary}>
						{cancelText}
					</Button>
					<Button className="modal--confirm" key={"confirm"} onClick={onConfirm} colors={Colors.Primary}>
						{confirmText}
					</Button>
				</div>
			</>
		);

		store.modal.show(title, confirmDom, {
			className: 'confirm-modal',
			onRequestClose: () => {
				store.modal.hide();
				reject();
			},
		});
	});
}