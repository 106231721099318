import {observer} from "mobx-react";
import React from "react";
import ProductAPIQuery, { ApiQueryParams, IOrderByCondition, PaginatedData, QueryResult } from "../../ModelCollection/ProductAPIQuery";
import {IProductEntityAttributes, ProductEntity, ShutterEntity} from "../../../../Models/Entities";
import {action, observable, runInAction} from "mobx";
import {PaginationQueryOptions} from "../../../../Models/PaginationData";
import Spinner from "../../Spinner/Spinner";
import {ICollectionHeaderProps, transformFnEdit} from "../../Collection/CollectionHeaders";
import moment from "moment";
import {Button, Colors, Display} from "../../Button/Button";
import {IEntityContextMenuItemActionProps} from "../../EntityContextMenu/EntityContextMenu";
import ProductCollection from "./ProductCollection";
import {Alignment, ButtonGroup} from "../../Button/ButtonGroup";
import {Model} from "../../../../Models/Model";
import _ from "lodash";
import {confirmModal} from "../../Modal/CustomModalUtils";
import alert from '../../../../Util/ToastifyUtils';
import {ICollectionBulkActionProps} from "../../Collection/Collection";
import {state, stateOptions} from '../../../../Models/Enums';
import axios from 'axios';
import {IProjectContext, ProjectContext} from '../ProjectContext';
import formatPrice from '../../../../Util/PriceUtils';
import FileUpload from "../../FileUpload/FileUpload";
import {DisplayType} from "../../Models/Enums";
import {store} from "../../../../Models/Store";
import SmartlookService from '../../../../Services/SmartlookService';
import Axios from "axios";
import {SERVER_URL} from "../../../../Constants";
import * as Enums from "../../../../Models/Enums";

export interface IShutterCollectionProps {
    projectId: string; 
    customFilter: ApiQueryParams;
    isCompact?: boolean;
    isReadOnly?: boolean;
    onProductStateUpdated?: () => void;
    totalPrice?: number;
    country: Enums.countries;
    fetchWeight?: () => void;
}

interface ISearch {
    searchTerm: string;
}

@observer
export class ShutterCollection extends React.Component<IShutterCollectionProps> {

    static contextType = ProjectContext;
    context!: IProjectContext;

    @observable
    public search: ISearch = { searchTerm: '' };

    @observable
    public paginationQueryOptions: PaginationQueryOptions = new PaginationQueryOptions();

    @observable
    public allSelectedItemIds: string[] = new Array<string>();

    @observable
    public allExcludedItemIds: string[] = new Array<string>();

    @observable
    public allPagesSelected: boolean = false;

    @observable
    private products: ProductEntity[] = [];

    @observable
    private totalRecords: number;

    @observable
    private sortByIcon: string;

    @action
    private setSortByIcon() {
        switch (store.sortDirectionShutter) {
            case "NONE":
                this.sortByIcon = "minus";
                break;
            case "ASCENDING":
                this.sortByIcon = "chevron-up";
                break;
            case "DESCENDING":
                this.sortByIcon = "chevron-down";
                break;
        }
    }

    private sortParams : IOrderByCondition<any> = {
        path: "shutterId",
        descending: this.sortByDescending(),
    };

    @observable
    private editMode: boolean = false;

    private refetch: () => void;

    componentDidUpdate() {
        runInAction(() => {
            this.paginationQueryOptions.page = 0;
            this.editMode = false;
        });
    }

    public render() {
        this.setSortByIcon();
        runInAction(() => this.paginationQueryOptions.perPage = 50);

        return (
            <ProductAPIQuery
                model={ProductEntity}
                url={`/api/entity/ProductEntity/product_list/${this.props.projectId}`}
                searchStr={this.search.searchTerm}
                pagination={this.paginationQueryOptions}
                moreParams={this.props.customFilter}
                processData={this.setProducts}
                orderBy={this.sortParams}
            >
                {(result) => {
                    return this.renderCollection(result);
                }}
            </ProductAPIQuery>

        );
    };

    protected renderCollection = (result: QueryResult) => {
        const { loading, error, refetch } = result;

        this.refetch = refetch;

        if (error) {
            return (
                <div>
                    <h2>An unexpected error occurred:</h2>
                    {JSON.stringify(error)}
                </div>
            );
        }

        let menuCountFunction = () => {
            if(this.allPagesSelected){
                return this.totalRecords - this.allExcludedItemIds.length;
            }else{
                return this.allSelectedItemIds.length;
            }
        };

        // Table headers 
        const getHeaders = () => {
            let headers: Array<ICollectionHeaderProps<ProductEntity>> = [
                {
                    name: 'shutterId',
                    displayName: 'Shutter ID',
                    sortable: false,
                    editable: true,
                    transformItem: model => model.shutter.shutterId,
                    columnSize: 'medium',
                },
                {
                    name: 'width',
                    displayName: 'Width (mm)',
                    sortable: false,
                    editable: true,
                    transformItem: model => model.shutter.width ?? '-',
                    columnSize: 'small',
                },
                {
                    name: 'depth',
                    displayName: 'Depth (mm)',
                    sortable: false,
                    editable: true,
                    transformItem: model => model.shutter.depth ?? '-',
                    columnSize: 'small',
                },
                {
                    name: 'diameter',
                    displayName: 'Diameter (mm)',
                    sortable: false,
                    editable: true,
                    transformItem: model => model.shutter.diameter ?? '-',
                    columnSize: 'small',
                },
                {
                    name: 'sketchId',
                    displayName: () =>
                        <div
                            className='shutter-tip'
                        >
                            Shutter Sketch
                            <span className='icon-information icon-middle'>
                            <div className='sketch-tooltip'>You must upload a sketch</div>
                        </span>

                        </div>,
                    sortable: false,
                    transformItemWithEdit: this.transformShutterSketch,
                    columnSize: 'big',
                },
                {
                    name: 'orderStatus',
                    displayName: 'Order Status',
                    sortable: false,
                    transformItemWithEdit: this.transformOrderStatus,
                },
                {
                    name: 'date',
                    displayName: 'Date',
                    sortable: false,
                    transformItem: model => moment(model.modified).format('DD/MM/YYYY'),
                },
                {
                    name: 'price',
                    displayName: 'Cost',
                    sortable: false,
                    transformItem: model => model.shutter.price ? formatPrice(model.shutter.price, this.props.country) : '-',
                }
            ];

            // Remove order status for compact list
            if (this.props.isCompact) {
                headers.splice(5,1);
            }
            
            return headers; 
        }

        // Context menu actions
        const getTableActionsMore = () => {
            const tableActionsMore: Array<IEntityContextMenuItemActionProps<ProductEntity>> = [
                {
                    onEntityClick: async (args, entity) => {
                        // create a file copy
                        let newSketchId;
                        const sketchId = entity.shutter.sketchId;
                        
                        if (sketchId) {
                            await Axios.get(`${SERVER_URL}/api/files/copy/${sketchId}`)
                                .then(result => newSketchId = result.data["id"])
                                .catch(err => {
                                    console.error(err);
                                    alert('Error occured while copying the sketch file', 'error');
                                });
                        }
                        
                        if (!sketchId || !newSketchId) {
                            return; 
                        }
                        
                        // extract shutter attributes to copy
                        let entityDetails = entity.toJSON({shutter: {}}, false,
                            (input) => {

                                // By default set the product to wishlist
                                input['state'] = stateOptions.WISHLIST;

                                // Delete the ids from the entity so it will create new ones
                                delete input['id'];
                                delete input['shutterId'];
                                delete input['shutter']['id'];

                                // Strip created/modified dates from the entities
                                delete input['created'];
                                delete input['modified'];
                                delete input['orderId'];
                                delete input['shutter']['created'];
                                delete input['shutter']['modified'];
                                delete input['shutter']['sketch'];
                                delete input['shutter']['sketchId'];

                                return input;
                            });

                        let newEntity = new ProductEntity(entityDetails);
                        if (newSketchId) {
                           newEntity.shutter.sketchId = newSketchId;     
                        }

                        await newEntity.save({shutter: {}});

                        if (this.context.setProjectContext) {
                            await this.context.setProjectContext(false, true);
                        }
                        alert('Successfully duplicated shutter', 'success');
                    },
                    label: 'Duplicate'
                },
                {
                    onEntityClick: (args, entity) => {
                        if (entity.state == 'ORDER') {
                            alert('Can not delete already ordered shutter', 'error');
                            return;
                        }

                        confirmModal('Are you sure?',
                            `Are you sure you want to delete the shutter with ID ${entity.shutter.shutterId}?`)
                            .then(async () => {
                                try {
                                    await entity.shutter.delete();

                                    // We may have possibly deleted an item that was in the cart
                                    // Shutter status count should be updated
                                    if (this.context.setProjectContext) {
                                        await this.context.setProjectContext(true, true);
                                    }
                                    
                                } catch (e) {
                                    alert('Error occured while deleting shutter', 'error')
                                }
                            }).catch(() => {/* This is when the user responds no */
                        });
                    },
                    label: 'Delete'
                },
            ];

            // Remove 'delete' action and add 'remove from order'
            if (this.props.isCompact) {
                tableActionsMore.splice(1,1);
                tableActionsMore.push(
                    {
                        onEntityClick: async (args, entity) => {
                            await this.changeProductStates([entity], 'WISHLIST');
                        },
                        label: 'Remove from order'
                    },
                )
            }
            
            return tableActionsMore;
        }

        // Bulk actions
        const selectedBulkActions = () => {
            let bulkActions: Array<ICollectionBulkActionProps<ProductEntity>> = [
                {
                    bulkAction: async items => {
                        SmartlookService.triggerEvent('Shutter Bulk Add to Order');
                        await this.changeProductStates(items, 'CART');
                        this.cancelAllSelection();
                    },
                    label: 'Add to order',
                    colors: Colors.Tertiary,
                    buttonClass: 'btn--bulk-order',
                },
                {
                    bulkAction: async items => {
                        await this.changeProductStates(items, 'WISHLIST');

                        // Refetch the list if it is in compact mode
                        // (because some items will no longer be part of the list)
                        if (this.props.isCompact) {
                            this.refetch();
                        }
                        this.cancelAllSelection();
                    },
                    label: 'Remove from order',
                    colors: Colors.Primary,
                    buttonClass: 'btn--bulk-order',
                },
                {
                    bulkAction: () => {
                        confirmModal('Are you sure?',
                            `Are you sure you want to delete these shutters? You cannot delete ordered shutters.`)
                            .then(async () => {
                                try {
                                    let shutterIds = this.allSelectedItemIds.reduce((result: string[], productId) => {
                                        const product = this.products.find(x => x.id === productId);
                                        const shutterId = product?.shutterId;
                                        const notInOrder = !!product && product.state !== 'ORDER';

                                        // Only add the product if it's a shutter and not currently in an order
                                        if (!!shutterId && notInOrder) {
                                            result.push(shutterId);
                                        }
                                        return result;
                                    }, []);
                                    await (new ShutterEntity()).deleteWhere(undefined, shutterIds);

                                    // Check for any items that were deleted from the cart
                                    // Shutter status count should be updated
                                    if (this.context.setProjectContext) {
                                        await this.context.setProjectContext(true, true);
                                    }
                                    
                                    this.cancelAllSelection();
                                    alert('All selected items are deleted successfully', 'success');
                                } catch (e) {
                                    alert('Error occured while deleting element', 'error')
                                }
                            }).catch(() => {/* This is when the user responds no */
                        });
                    },
                    label: 'Delete',
                    buttonClass: "btn--bulk-select",
                    colors: Colors.None,
                },
            ];

            // Remove Add to order and delete action
            if (this.props.isCompact) {
                bulkActions.splice(0,1);
                bulkActions.splice(1,1);
            }
            
            return bulkActions;
        }

        const additionalActionsCompact: React.ReactNode[] = [
            <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group" key='additionalActions'>
                <Button
                    onClick={() => this.onSort(this.refetch)}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: this.sortByIcon, iconPos: 'icon-left' }}>
                    Sort By
                </Button>
            </ButtonGroup>
        ];

        const additionalActions: React.ReactNode[] = [
            <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group" key='additionalActions'>
                <Button
                    onClick={this.addNewShutter}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: "plus", iconPos: 'icon-left' }}>
                    Add Shutter
                </Button>
                <Button
                    onClick={() => this.onSort(this.refetch)}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: this.sortByIcon, iconPos: 'icon-left' }}>
                    Sort By
                </Button>
            </ButtonGroup>
        ];

        return (
            <>
                {loading && <Spinner/>}
                <ProductCollection
                    collection={this.products}
                    headers={getHeaders()}
                    actionsMore={this.props.isReadOnly ? undefined : getTableActionsMore()}
                    selectedBulkActions={selectedBulkActions()}
                    additionalActions={this.props.isCompact ? additionalActionsCompact : additionalActions}
                    onSearchTriggered={this.props.isCompact ? undefined : this.onSearchTriggered}
                    pageNo={this.paginationQueryOptions.page}
                    perPage={this.paginationQueryOptions.perPage}
                    onPageChange={this.paginationQueryOptions.gotoPage}
                    totalRecords={this.totalRecords}
                    hidePagination={!!this.props.isCompact}
                    menuCountFunction={menuCountFunction}
                    selectableItems={!this.props.isReadOnly}
                    itemSelectionChanged={this.itemSelectionChanged}
                    cancelAllSelection={this.cancelAllSelection}
                    getSelectedItems={this.getSelectedItems}
                    onCheckedAllPages={this.onCheckedAllPages}
                    showExpandButton={() => true}
                    refetch={() => this.updateTable()}
                    footer={this.props.totalPrice ? <h5> Shutter total {formatPrice(this.props.totalPrice, this.props.country)}</h5> : null}
                    isReadOnly={this.props.isReadOnly}
                    onEditModeUpdated={this.onEditModeUpdated}
                    fetchWeight={this.props.fetchWeight}
                />
            </>
        );
    };

    private filePreview = (shutter: ShutterEntity, editMode: boolean) => {
        // Due to the way icons are styled, we can't use css to truncate the text. so it is being done here:
        const maxFileNameLength = editMode ? 10 : 15;
        const sketchFileName = shutter.sketchFileName.substring(0, maxFileNameLength).trim()
            + (shutter.sketchFileName.length > maxFileNameLength ? '...' : '');

        return (
            <div className="btn btn--icon icon-download icon-right" 
                 onClick={() => !editMode && window.open(`${SERVER_URL}/api/files/${shutter.sketchId}?download=true`,
                     "_blank", "noopener noreferrer")}>
                <a title={shutter.sketchFileName}>
                    {sketchFileName}
                </a>
            </div>
        );
    }
    // Transform functions 
    private transformShutterSketch: transformFnEdit<ProductEntity> = (product:ProductEntity, editMode) => {
        const filePreview = () =>
            <>
                {this.filePreview(product.shutter, editMode)}
                {editMode ?
                    <Button
                        display={Display.Text}
                        icon={{icon: 'bin-delete'}}
                        className='icon-middle'
                        onClick={() => this.onAfterFileDelete(product)} 
                    /> : null
                }
            </>; 
        
        const fileUpload = () =>
            <FileUpload
                model={product.shutter}
                modelProperty='sketch'
                displayType={DisplayType.INLINE}
                onAfterChange={(file) => this.onUploadFile(file, product)}
                isDisabled={!product.shutter.shutterId || !editMode}
            />;
        
        return (
            <div className='sketch-upload'>
                {!!product.shutter.sketchFileName ? filePreview() : fileUpload()}
            </div>
        );
    }
    
    @action 
    private onAfterFileDelete = async (product: ProductEntity) => {
        const sketchId = product.shutter.sketchId;

        product.shutter.sketchFileName = '';
        product.shutter.sketchId = '';

        if (sketchId) {
            product.shutter.deleteSketchId = sketchId;
        }
    }
    
    @action
    private onUploadFile = (file: File, product: ProductEntity) => {
        if (!this.validateContentType(file)) {
            alert(`Content type ${file.type} is not valid. You can only upload pdf, dwg and image files.`, 'error');
            return;
        }

        product.shutter.sketchFileName = file.name;
        product.shutter.sketchId = '';
    }

    private validateContentType = (file: File) => {
        // file size limit of 10mb 
        if (file.size > 10000000) {
            return false; 
        }

        // file type constraint of image, pdf, dwg
        const types = ['image', '.pdf', '.dwg'];
        for (const type of types) {
            if (file.type === type) {
                return true;
            }

            // File extension match
            if (type.startsWith('.') && file.name.endsWith(type)) {
                return true;
            } else {
                if (file.type.startsWith(type)) {
                    return true;
                }
            }
        }

        return false;
    };
    
    private transformOrderStatus: transformFnEdit<ProductEntity> =
        (product: ProductEntity, editMode: boolean) => {
        let buttonText;
        let buttonColour;
        let ordered = false;

        switch (product.state) {
            case 'WISHLIST':
                buttonText = 'Add to order';
                buttonColour = Colors.Tertiary;
                break;
            case 'CART':
                buttonText = 'Remove from order';
                buttonColour = Colors.Primary;
                break;
            case 'ORDER':
                ordered = true;
                break;
            default:
                buttonText = '';
        }

        const changeElementState = async () => {
            let newState: state;
            if (product.state === 'WISHLIST') {
                newState = 'CART';
            } else if (product.state === 'CART') {
                newState = 'WISHLIST';
            } else {
                return;
            }

            try {
                await this.changeProductStates([product], newState);

                if (newState === 'CART') {
                    SmartlookService.triggerEvent('Single List Shutter Add');
                }
            } catch (err) {
                console.error(err);
                alert('Could not update element order status', 'error');
            }

        };

        if (ordered) {
            return (
                <div className='element-ordered'>
                    <b>Order {product.order.getOrderStatusDisplayText()}</b>
                    <i>PO# {product.order ? product.order.orderNumber : ''}</i>
                </div>
            );
        }

        return (
            <Button
                className='fixed-width'
                colors={buttonColour}
                onClick={() => runInAction(changeElementState)}
                disabled={editMode}
            >
                {buttonText}
            </Button>
        );
    }

    // Additional actions
    @action
    private setProducts = (result: PaginatedData<ProductEntity>) => {
        // if (store.sortDirectionShutter !== "NONE") {
        //     store.sortProducts("SHUTTER", result.data);
        // }

        this.products = result.data.map((e: IProductEntityAttributes) => new ProductEntity(e));
        this.totalRecords = result.totalCount
    }

    @action
    private onSearchTriggered = (searchTerm: string) => {
        this.search.searchTerm = searchTerm;
        store.elementShutterEditMode = false;
    }

    @action
    private onEditModeUpdated = (editMode: boolean) => {
        if (this.editMode && editMode) {
            alert('You have unsaved changes in another shutter, please save the changes before continuing', 'error');
            return false;
        }
        this.editMode = editMode;
        return true;
    }

    @action
    private addNewShutter = () => {
        // Cannot add new Shutter row if new shutter hasn't been saved
        if (store.showToastInEditMode("shutter")) return;

        store.elementShutterEditMode = true;

        let newProduct = this.products.some((product) => (product.elementId ?? product.shutterId) == null);
        if (newProduct || this.editMode)
        {
            alert('You have unsaved changes in another shutter, please save the changes before continuing', 'error');
            return;
        }

        SmartlookService.triggerEvent('Shutter Added');

        this.products.unshift(new ProductEntity({
            shutter: new ShutterEntity(),
            projectId: this.props.projectId,
            state: 'WISHLIST'
        }));
    }

    // Select items
    @action
    public itemSelectionChanged = (checked: boolean, changedItems: Model[]) => {
        let changedIds = changedItems.map(item => item.id);
        if (this.allPagesSelected) {
            if (!checked) {
                this.allExcludedItemIds = _.union(this.allExcludedItemIds, changedIds);
            } else {
                this.allExcludedItemIds = _.pull(this.allExcludedItemIds, ...changedIds);
            }
        } else {
            if (checked) {
                this.allSelectedItemIds = _.union(this.allSelectedItemIds, changedIds);
            } else {
                this.allSelectedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
            }
        }
        return this.products.filter((m: Model) => {
            if (this.allPagesSelected) {
                return !this.allExcludedItemIds.some(id => {
                    return m.id === id;
                }) ;
            } else {
                return this.allSelectedItemIds.some(id => {
                    return m.id === id;
                }) ;
            }
        });
    }

    @action
    private cancelAllSelection = () => {
        this.allPagesSelected = false;
        this.allSelectedItemIds = [];
        this.allExcludedItemIds = [];
    }

    private getSelectedItems = () => {
        return this.products.filter(model => {
            if (this.allPagesSelected) {
                return !this.allExcludedItemIds.some(id => {
                    return model.id === id;
                });
            } else {
                return this.allSelectedItemIds.some(id => {
                    return model.id === id;
                });
            }
        });
    };

    @action
    private onCheckedAllPages = (checked: boolean) => {
        this.allPagesSelected = checked;
        if(checked){
            this.allExcludedItemIds = [];
            let changedIds = this.products.map(item => item.id);
            if (checked) {
                this.allSelectedItemIds = _.union(this.allSelectedItemIds, changedIds);
            } else {
                this.allSelectedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
            }
            let selectedItems = (new Array<ProductEntity>());
            selectedItems.push(...this.products);
            return selectedItems;
        }else{
            this.allSelectedItemIds = [];
            let changedIds = this.products.map(item => item.id);
            if (!checked) {
                this.allExcludedItemIds = _.union(this.allSelectedItemIds, changedIds);
            } else {
                this.allExcludedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
            }
            return [];
        }
    }

    @action
    private async changeProductStates(items: ProductEntity[], newState: state) {
        const filteredItems = store.hasBackendAccess ? items : items.filter(item => item.state !== 'ORDER');

        await axios.put(`/api/entity/ProductEntity/updateState`, {
            Ids: filteredItems.map(item => item.id),
            State: newState,
        });

        runInAction(() => {
            filteredItems.forEach(item => {
                item.state = newState;
            });
        });

        // Once the state of the products have been updated, update the cart status
        if (this.context.setProjectContext) {
            await this.context.setProjectContext(true);
        }

        if (this.props.isCompact && this.props.onProductStateUpdated) {
            this.props.onProductStateUpdated();
        }

        if(this.props.fetchWeight){
            await this.props.fetchWeight();
        }
    }

    private async updateTable() {

        if (!this.props.isCompact && this.context.setProjectContext) {
            await this.context.setProjectContext(true);
        }

        if (this.props.isCompact && this.props.onProductStateUpdated) {
            this.props.onProductStateUpdated();
        }
    }

    @action
    private onSort(refetch: () => void) {
        switch (store.sortDirectionShutter) {
            case "NONE":
                store.setsortDirectionShutter("ASCENDING");
                break;
            case "ASCENDING":
                store.setsortDirectionShutter("DESCENDING");
                break;
            case "DESCENDING":
                store.setsortDirectionShutter("NONE");
                break;
        }

        this.sortParams.descending = this.sortByDescending();
        this.setSortByIcon();
    }

    private sortByDescending() {
        switch (store.sortDirectionShutter) {
            case "NONE": return undefined;
            case "ASCENDING": return false;
            case "DESCENDING": return true;
            default: return undefined;
        }
    }
}