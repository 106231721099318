import React, { FormEvent } from 'react';
import SidePanel from '../SidePanel/SidePanel';
import FileUpload from '../FileUpload/FileUpload';
import { Alignment, ButtonGroup } from '../Button/ButtonGroup';
import { Button, Colors, Display } from '../Button/Button';
import { DisplayType } from '../Models/Enums';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import alert from '../../../Util/ToastifyUtils';
import axios from 'axios';
import { LoadView, ProgressBarState } from '../LoadView/LoadView';
import RevitFile from '../../../Assets/images/revit-file.svg';
import { ProjectEntity } from '../../../Models/Entities';
import { cssTransition } from 'react-toastify';
import {IProjectContext, ProjectContext} from "./ProjectContext";
import SmartlookService from '../../../Services/SmartlookService';

interface ImportPartsProps {
	project: ProjectEntity;
	display: boolean;
	hidePanel?: () => void;
	handleImportErrors?: (errors: UploadPartsResponse) => void;
}

export interface ImportError {
	lineNumber: string;
	type: string;
}

export interface UploadPartsResponse
{
	partsWithInvalidDimensions: number;
	duplicateCount: number;
	nonAptusParts: number;
	previouslyOrdered: number;
	importedSuccessfully: number;
	partErrors: ImportError[];
}

@observer
export default class ImportParts extends React.Component<ImportPartsProps> {

	static contextType = ProjectContext;
	context!: IProjectContext;

	@observable
	private model: { file?: File } = {};

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';

	@observable
	private progressBar = ProgressBarState.Hidden;

	@computed get barStatus(): ProgressBarState {
		return this.progressBar
	}

	private loadView: LoadView | null = null;
	private estimatedTime = 0;

	@action
	private hidePanel = () => {
		// Clear the model
		this.model.file = undefined;

		if (this.props.hidePanel) {
			this.props.hidePanel();
		}
	}

	@action
	private uploadPartsFile = async (e: FormEvent) => {
		e.preventDefault();

		SmartlookService.triggerEvent('Added Bulk Import File to Project');

		if (this.model.file) {
			// Do a check to ensure the file is the type we need
			let fileParts = this.model.file?.name.split('.');
			if (fileParts.length > 1) {
				if (fileParts[1] !== 'csv' && fileParts[1] !== 'txt') {
					alert('File type needs to be a csv', 'error');
					return;
				}
			}

			const formData = new FormData();
			formData.append('csv', this.model.file);

			this.estimateTime(this.model.file.size);

			let response;
			try {
				this.showLoadingBar();

				response = await axios.post(
					`/api/entity/ProjectEntity/uploadParts/${this.props.project.id}`,
					formData,
					{
						headers: {
							'Content-Type': 'mulitipart/form-data',
						}
					});

				this.hidePanel();
			} catch(e) {
				alert('Error uploading CSV file. Please reload the page and try again', 'error')
				console.error('Error while uploading csv', e);
			}

			this.hideLoadingBar();
			if (response) {
				this.displayResponseMessages(response.data);
			}

			// Element status count should be updated
			if (this.context.setProjectContext) {
				await this.context.setProjectContext(false, true);
			}
		} else {
			alert('Please select a file to upload', 'error');
		}
	};

	private estimateTime = (fileSize: number) => {
		this.estimatedTime = Math.floor(fileSize / 1000 / 15);
	};

	@action
	private showLoadingBar = () => {
		this.progressBar = ProgressBarState.Loading
	};

	@action
	private hideLoadingBar = () => {
		this.progressBar = ProgressBarState.Hidden;
		if(this.loadView) {
			this.loadView.updateStyle(100);
		}
	};

	private displayResponseMessages = (data: UploadPartsResponse) => {
		// Add a delay to the messages to make the alerts easier to read
		let delay = 0;
		const increment = 400;


		if (data.partsWithInvalidDimensions !== 0) {
			alert(
				`${data.partsWithInvalidDimensions} Aptus part/s found with invalid dimensions`,
				'warning', { delay });
		}

		if (data.duplicateCount !== 0) {
			delay += increment;
			alert(
				`${data.duplicateCount} duplicates were found and were not imported`,
				'warning', { delay });
		}

		if (data.nonAptusParts !== 0) {
			delay += increment;
			alert(
				`${data.nonAptusParts} non-Aptus part/s were found, and not imported`,
				'warning', { delay });
		}

		if (data.previouslyOrdered !== 0) {
			delay += increment;
			alert(
				`${data.previouslyOrdered} previously ordered element/s with updated components were imported with a suffix.`,
				'success', { delay });
		}

		if (data.importedSuccessfully !== 0) {
			delay += increment;
			alert(
				`${data.importedSuccessfully} element/s imported successfully.`,
				'success', { delay });
		}

		if (this.props.handleImportErrors) {
			this.props.handleImportErrors(data);
		}
	}

	private static downloadBulkOrderTemplate() {
		SmartlookService.triggerEvent('Downloaded Bulk Order Template');
		window.location.replace('/templates/Bulk Order Import Template.csv');
	}

	render() {
		let fileText = 'No source file selected';
		if (!!this.model.file) {
			fileText = `${this.model.file?.name}`;
		}

		return (
			<SidePanel display={this.props.display} className='parts-import' hidePanel={this.hidePanel}>
				<h3>Import elements in bulk</h3>
				<img src={RevitFile}  alt='Revit file' />
				<p>
					To import elements in bulk, you need to provide the data in a CSV file using
					the <a onClick={ImportParts.downloadBulkOrderTemplate}>Bulk Order Import Template</a>.
				</p>

				<div className='row'>
					<FileUpload model={this.model}
								modelProperty='file'
								displayType={DisplayType.INLINE}
								label='CSV Source File'
								onAfterChange={() => SmartlookService.triggerEvent('Upload Bulk Import File')}
								isRequired={true} />
					<span>{fileText}</span>
				</div>
				<p>The maxiumum file upload size is 10.00 MB</p>

				<ButtonGroup alignment={Alignment.HORIZONTAL}>
					<Button colors={Colors.Secondary} onClick={this.hidePanel}>Cancel</Button>
					<Button display={Display.Solid} disabled={!this.model.file} onClick={this.uploadPartsFile}>
						Add to project
					</Button>
				</ButtonGroup>

				{this.progressBar !== ProgressBarState.Hidden
					? <LoadView text={"Bulk Importing Elements"}
								estimate={this.estimatedTime}
								completed={this.progressBar}
								ref={ref => this.loadView = ref} />
					: null
				}
			</SidePanel>
		);
	}

}