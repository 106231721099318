import * as React from 'react';
import { observer } from "mobx-react";
import { observable, runInAction } from 'mobx';
import axios from 'axios';
import { ApiQueryParams } from '../ModelCollection/ModelAPIQuery';
import { IProjectContext } from './ProjectContext';
import { ElementCollection } from './ProjectCollection/ElementCollection';
import Tabs, { ITabConfig } from '../Tabs/Tabs';
import { ShutterCollection } from './ProjectCollection/ShutterCollection';
import * as Enums from "../../../Models/Enums";

interface IProjectProps {
    projectId: string;
    orderId?: string;
    isReadOnly?: boolean;
    country: Enums.countries; 
    fetchWeight?: () => void;

    // Display content at the top of the table
    topContent?: () => React.ReactNode;

    // Content to be displayed at the bottom of the table
    bottomContent?: (status: IOrderStatusResult) => React.ReactNode;

    // Bubble up a refetch function so the parent component can rerender the list of products when needed
    bubbleUpRefetch?: (func: () => void) => void;
}

export interface IOrderStatusResult {
    elementCount: number;
    elementPrice: number;
    shutterCount: number;
    shutterPrice: number;
}

@observer
export default class CompactProjectItemsProject extends React.Component<IProjectProps, IProjectContext> {
    
    @observable
    private currentTab = 0;

    // Using a -1 to represent a undefined state with a valid type
    // Better to use this instead of undefined as it doesn't change how this object is accessed and used
    @observable
    private orderStatusResult: IOrderStatusResult = {
        elementCount: -1,
        shutterCount: -1,
        elementPrice: -1,
        shutterPrice: -1,
    };

    componentDidMount() {
        if (this.props.bubbleUpRefetch) {
            this.props.bubbleUpRefetch(this.fetchOrderStatus);
        }

        this.fetchOrderStatus();
    }

    private fetchOrderStatus = () => {
        let additional = this.props.orderId ?? ''; 
        axios.get(`/api/entity/OrderEntity/getOrderStatus/${this.props.projectId}/${additional}`)
            .then(result => {
                runInAction(() => {
                    this.orderStatusResult = result.data;
                });
            }).catch(err => console.error(err));
    }
    
    private getElementCustomFilter = (): ApiQueryParams => {
        if (this.props.orderId) {
            return {
                'orderStatus': 'all',
                'productType': 'Element',
                'productState': 'ORDER',
                'orderId': this.props.orderId,
            }
        } else {
            return {
                'orderStatus': 'notOrdered',
                'productType': 'Element',
                'productState': 'CART',
            }
        }
    }

    private getShutterCustomFilter = (): ApiQueryParams => {
        if (this.props.orderId) {
            return {
                'orderStatus': 'all',
                'productType': 'Shutter',
                'productState': 'ORDER',
                'orderId': this.props.orderId,
            }
        } else {
            return {
                'orderStatus': 'notOrdered',
                'productType': 'Shutter',
                'productState': 'CART',
            }
        }
    }
    
    private getTabs(): ITabConfig[] {
        return [
            {
                name: `Elements (${this.orderStatusResult.elementCount})`,
                key: 'elements',
                component: (
                    <ElementCollection
                        projectId={this.props.projectId}
                        customFilter={this.getElementCustomFilter()}
                        isCompact={true}
                        isReadOnly={this.props.isReadOnly}
                        onProductStateUpdated={this.fetchOrderStatus}
                        totalPrice={this.orderStatusResult.elementPrice}
                        country={this.props.country}
                        fetchWeight={this.props.fetchWeight}
                    />
                ),
            },
            {
                name: `Shutters (${this.orderStatusResult.shutterCount})`,
                key: 'shutters',
                component: (
                    <ShutterCollection
                        projectId={this.props.projectId}
                        customFilter={this.getShutterCustomFilter()}
                        isCompact={true}
                        isReadOnly={this.props.isReadOnly}
                        onProductStateUpdated={this.fetchOrderStatus}
                        totalPrice={this.orderStatusResult.shutterPrice}
                        country={this.props.country}
                        fetchWeight={this.props.fetchWeight}
                    />
                ),
            },
        ];
    }

    public render() {
        // Adding this line prevents updating the state whilst rendering
        if (this.orderStatusResult.elementCount === -1 && this.orderStatusResult.shutterCount === -1) {
            return <></>;
        }

        return (
            <div className='elements-list-block'>
                {this.props.topContent ? this.props.topContent() : undefined}

                <div className='fieldset'>
                    <Tabs className='project-tab-nav'
                          tabs={this.getTabs()}
                          currentTab={this.currentTab}
                          defaultTab={0} />

                    {this.props.bottomContent ? this.props.bottomContent(this.orderStatusResult) : undefined}
                </div>
            </div>
        );
    }
}