import React from 'react';
import { ProjectEntity } from '../../../Models/Entities';

export interface IProjectInformationProps {
	project: ProjectEntity;
}

export default function ProjectInformation(props: IProjectInformationProps) {
	return (
		<div className='project-information'>
			<span className='icon-location-pin icon-left'>{props.project.location}</span>
			<span>Job No. {props.project.clientJobNumber}</span>
		</div>
	);
};