import React from 'react';
import { Combobox, ComboboxOption } from '../Combobox/Combobox';
import { IProjectEntityAttributes } from '../../../Models/Entities';
import { computed, observable, runInAction } from 'mobx';
import If from '../If/If';
import { observer } from 'mobx-react';
import axios from 'axios';
import alert from '../../../Util/ToastifyUtils';

export interface ProjectContactDetails {
	id: string,
	name: string,
	email: string,
	phone: string,
}


interface IProjectContactFormProps {
	project: Partial<IProjectEntityAttributes>;
	onChange?: () => void;
	isDisabled?: boolean;
}

@observer
export default class ProjectContactForm extends React.Component<IProjectContactFormProps> {

	@observable contactList: {[key: string]: ProjectContactDetails} = {};

	componentDidMount() {
		this.fetchContactList();
	}

	private fetchContactList() {
		let params = {};
		if (this.props.project.id !== undefined) {
			params['organisationId'] = this.props.project.organisationId;
		}
		axios
			.get(`/api/entity/OrganisationEntity/contact-list/`, { params: params })
			.then(({ data }) => runInAction(() => {
				data.forEach((user: ProjectContactDetails) => {
					this.contactList[user.id] = user;
				});
			})).catch(() => alert('Could not fetch contact list', 'error'));
	}

	@computed public get buildProjectContactList(): ComboboxOption<string>[] {
		return Object.keys(this.contactList).map(userId => {
			const user = this.contactList[userId];
			return { display: user.name, value: user.id }
		});
	}

	public render() {
		const contactId = this.props.project.projectContactId;
		const currentContact = contactId ? this.contactList[contactId] : null

		return (
			<>
				<Combobox
					model={this.props.project}
					modelProperty='projectContactId'
					label='Project contact'
					placeholder='No contact selected'
					options={this.buildProjectContactList}
					searchable={false}
					isClearable={false}
					isDisabled={this.props.isDisabled}
					isRequired={true} onChange={this.props.onChange}/>
				<If condition={currentContact !== null}>
					<div className='input-details'>
						<i>Email: {currentContact?.email}</i><br/>
						<i>Phone: {currentContact?.phone}</i>
					</div>
				</If>
			</>
		);
	}
}

