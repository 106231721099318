import * as React from 'react';
import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { Model } from 'Models/Model';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { getModelName } from 'Util/EntityUtils';
import { observer } from 'mobx-react';
import { SERVER_URL } from 'Constants';
import { observable, action, runInAction, computed } from 'mobx';
import { lowerCaseFirst } from 'Util/StringUtils';
import { modelName as modelNameSymbol } from 'Symbols';
import { IWhereCondition } from './ModelQuery';

export interface IOrderByCondition<T> {
    path: string;
    descending?: boolean;
}

export interface IModelAPIQueryVariables<T> {
    skip?: number;
    take?: number;
    args?: Array<IWhereCondition<T>>;
    ids?: string[];
}

export interface QueryResult {
    error?: string;
    success?: boolean;
    loading: boolean;
    refetch: () => void;
}

export interface ApiQueryParams {
    [key: string]: Date | boolean | string | number
}

export interface PaginatedData<T extends Model> {
    data: Array<T>;
    totalCount: number; 
}

export interface IModelAPIQueryProps<T extends Model, TData = any> {
    children: (result: QueryResult) => React.ReactNode;
    model: { new(json?: {}): T };
    conditions?: Array<IWhereCondition<T>> | Array<Array<IWhereCondition<T>>>;
    moreParams?: ApiQueryParams;
    ids?: string[];
    searchStr?: string;
    orderBy?: IOrderByCondition<T>;
    url: string;
    pagination: PaginationQueryOptions;
    processData?: (result: PaginatedData<T>) => void;
}

@observer
class ModelAPIQuery<T extends Model, TData = any> extends React.Component<IModelAPIQueryProps<T, TData>> {
    @observable
    private requestState: 'loading' | 'error' | 'done' = 'loading';

    private oldProps: IModelAPIQueryProps<T, TData>;

    private requestData: PaginatedData<T>;

    private requestError?: string;

    public componentDidMount = () => {
        this.oldProps = _.cloneDeep(this.props);
        this.makeQuery();
    }

    public componentDidUpdate() {
        if (
            !_.isEqual(this.props, this.oldProps)
            ||
            !!this.props.pagination && !!this.oldProps.pagination && this.props.pagination.page !== this.oldProps.pagination.page
        ) {
            // if this query is not made due to the pageNo change, then the pageNo should be reset to 0
            // in case the new query result doesn't have this page no
            if (!!this.props.pagination && !!this.oldProps.pagination && this.props.pagination.page === this.oldProps.pagination.page) {
                runInAction(() => {
                    this.props.pagination.page = 0;
                });
            }
            this.makeQuery();
            this.oldProps = _.cloneDeep(this.props);
        }
    }

    @action
    private makeQuery = () => {
        this.requestState = 'loading';

        const modelName: string = this.props.model[modelNameSymbol];
        const lowerModelName = lowerCaseFirst(modelName);
        const url = this.props.url || `${SERVER_URL}/api/${lowerModelName}`;

        axios.get(url, { params: this.constructVariables })
            .then(this.onSuccess)
            .catch(this.onError);
    }

    @action
    private onSuccess = (data: AxiosResponse) => {
        this.requestData = data.data;
        this.requestState = 'done';
        
        if (this.props.processData) {
            this.props.processData(this.requestData); 
        }
    }

    @action
    private onError = (data: AxiosResponse) => {
        this.requestData = data.data;
        this.requestState = 'error';
        this.requestError = data.statusText;
    }

    public render() {
        const modelName = getModelName(this.props.model);

        if (this.props.pagination.page < 0) {
            return null;
        }

        return this.props.children({
            loading: this.requestState === 'loading',
            success: this.requestState === 'done',
            error: this.requestError,
            refetch: this.makeQuery,
        });
    }

    @computed
    private get constructVariables() {
        const { pagination, moreParams } = this.props;
        const { page, perPage } = pagination;
        
        return {
            pageNo: (!isNaN(page) && page >= 0) ? (page + 1) : undefined,  // matching the backend pagination which starts from page 1
            pageSize: perPage || undefined,
            searchStr: this.props.searchStr || undefined,
            orderByDescending: this.props.orderBy?.descending,
            ...moreParams
        };
    }
}

export default ModelAPIQuery;