import React from 'react';
import { ProjectEntity } from '../../../../Models/Entities';
import { observer } from 'mobx-react';
import {action, observable} from 'mobx';
import {RouteComponentProps} from "react-router/index";
import OrderStatusFilter from "./OrderStatusFilter";
import {ApiQueryParams} from "../../ModelCollection/ModelAPIQuery";
import {ShutterCollection} from "../ProjectCollection/ShutterCollection";
import { store } from 'Models/Store';

interface IShutterListTileProps extends RouteComponentProps {
    project: ProjectEntity;
}

@observer
export default class ShuttersTab extends React.Component<IShutterListTileProps> {

    @observable
    private orderStatusFilter: string = 'all';
    
    @action
    private onOrderStatusChanged = (status: string) => {
        store.elementShutterEditMode = false;
        this.orderStatusFilter = status;
    }

    render() {
        const queryParams: ApiQueryParams = {
            'orderStatus': this.orderStatusFilter,
            'productType': 'Shutter',
        };

        return (
            <div className='elements-tab'>
                <div className='left-info-panel'>
                    <OrderStatusFilter onAfterChange={this.onOrderStatusChanged} projectId={this.props.project.id} productType={'Shutter'}/>
                </div>
                <div className='elements-list-block'>
                    <ShutterCollection
                        projectId={this.props.project.id}
                        customFilter={queryParams}
                        country={this.props.project.country}
                    />
                </div>
            </div>
        );
    };

}