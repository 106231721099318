import * as React from 'react';
import { observer } from 'mobx-react';
import {action, observable} from "mobx";
import {store} from "Models/Store";
import {Alignment, ButtonGroup} from "Views/Components/Button/ButtonGroup";
import {Button, Colors, Display, Sizes} from "Views/Components/Button/Button";
import {getAttributeComponent} from "Views/Components/CRUD/Attributes/AttributeFactory";
import {EntityFormMode} from "Views/Components/Helpers/Common";
import {getModelName, isRequired} from "Util/EntityUtils";
import alert from "Util/ToastifyUtils";
import axios from "axios";
import {SERVER_URL} from "Constants";
import UserEntity from "Models/Entities/UserEntity";
import {RequestState} from "../../../Models/Enums";

@observer
export default class Personal extends React.Component {
    private userEntity: UserEntity;

    @observable
    private requestState: RequestState = 'loading';

    public componentDidMount() {
        UserEntity.fetch<UserEntity>({args: [[{path: 'id', comparison: "equal", value: store.userId}]]})
            .then(d => {
                this.changeRequestState('done', d[0]);
            })
            .catch(e => {
                this.changeRequestState('error');
            })
    }

    @action
    private changeRequestState = (state: RequestState, userEntity?: UserEntity) => {
        if (userEntity) {
            this.userEntity = userEntity;
        }
        this.requestState = state;
    };

    public render() {
        let contents = null;

        if (this.requestState === 'loading') {
            contents = <div></div>;
        } else if (this.requestState === 'error' || !this.userEntity) {
            contents = (
                <div className="update-profile">
                    <h3>There has been an error</h3>
                    <p>Unable to load the profile page at this time. Please contact us if the problem persists.</p>
                </div>
            );
        } else {
            contents = this.renderPersonal();
        }

        return contents;
    }

    private renderPersonal = () => {
        const entityAttrs = this.getUpdateFields();
        return (
            <form onSubmit={this.onSubmitUpdateClicked}>
                <div className="header">
                    <h4>Personal</h4>
                    <ButtonGroup alignment={Alignment.HORIZONTAL}>
                        <Button className="btn-reset-password" onClick={this.resetPassword} colors={Colors.Secondary}>Reset Password</Button>
                        <Button type='submit' className="submit-update-profile" display={Display.Solid} colors={Colors.Primary}>Update</Button>
                    </ButtonGroup>
                </div>
                <div className="entity-attrs">
                    {entityAttrs}
                </div>
            </form>
        );
    };

    private getUpdateFields = () => {
        // Used to show a custom list of fields, and to set the order they display in
        const registrationFieldList: string[] = [
            'firstName',
            'lastName',
            'phone',
            'email',
        ];

        let attributeOptions = this.userEntity.getAttributeCRUDOptions();
        const model = this.userEntity;
        return attributeOptions
            .filter(attributeOption =>
                registrationFieldList.indexOf(attributeOption.attributeName) >= 0)
            .sort((a, b) =>
                registrationFieldList.indexOf(a.attributeName) - registrationFieldList.indexOf(b.attributeName))
            .map(attributeOption =>
                getAttributeComponent(
                    attributeOption,
                    model,
                    model.getErrorsForAttribute(attributeOption.attributeName),
                    EntityFormMode.EDIT,
                    isRequired(model, attributeOption.attributeName),
                    undefined,
                    model ? model.validate.bind(model) : undefined
                ));
    };

    @action
    private onSubmitUpdateClicked = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (this.userEntity) {
            await this.userEntity.validate();
            if (this.userEntity.hasValidationError) {
                return;
            }
            this.userEntity.save().then(() => {
                alert(`Your profile has been saved`, 'success');
            });
        }
    };

    private resetPassword = async () => {
        try {
            const result = await axios.post(`${SERVER_URL}/api/account/reset-password-logged-in`);
            store.routerHistory.push(`/reset-password?token=${encodeURIComponent(result.data)}&username=${store.email}`);
        } catch (e) {
            alert(`Unable to change your password. Please refresh the page and try again.`, 'error');
        }
        
    };

}

