import React from 'react';
import BreadcrumbsWrap from '../BreadcrumbsWrap/BreadcrumbsWrap';
import { IProjectEntityAttributes, OrderEntity, PriceVersionEntity, ProjectEntity } from '../../../Models/Entities';
import If from '../If/If';
import { store } from '../../../Models/Store';
import { TextField } from '../TextBox/TextBox';
import { observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import { Combobox } from '../Combobox/Combobox';
import ProjectDeliveryDetailsForm from '../ProjectDetails/ProjectDeliveryDetailsForm';
import ProjectContactForm from '../ProjectDetails/ProjectContactForm';
import { TextArea } from '../TextArea/TextArea';
import CompactProjectItemsProject, { IOrderStatusResult } from '../Project/CompactProjectItems';
import { Button, Colors, Sizes, Widths } from '../Button/Button';
import _ from 'lodash';
import formatPrice from '../../../Util/PriceUtils';
import alert from '../../../Util/ToastifyUtils';
import axios from 'axios';
import { Alignment, ButtonGroup } from '../Button/ButtonGroup';
import OrderRequestExport from '../../../Util/OrderRequestExport';
import { orderStatusOptions } from "../../../Models/Enums";
import gql from 'graphql-tag';
import SmartlookService from '../../../Services/SmartlookService';
import buildPriceVersionList from "../../../Util/PriceVersionUtils";
import '../../../scss/dropdown-styles.scss';
import WeightDropDown from '../WeightDropDown/WeightDropDown';

interface OrderProps {
	project: ProjectEntity;
	order?: OrderEntity;
	isCart: boolean;
	isAdmin: boolean;
}


@observer
export default class Order extends React.Component<OrderProps> {
	@observable
	private weightDetails = {
		totalWeight: 0,
		barWeight: 0,
		componentWeight: 0,
		shutterWeight: 0
	};

	@observable
	private orderDetails = this.props.order ?? new OrderEntity();

	@observable
	private project: ProjectEntity = new ProjectEntity(this.props.project);

	@observable
	private priceVersions:  {[key: string]: PriceVersionEntity} = {};

	private isCart = this.props.isCart;
	private isAdmin = this.props.isAdmin;
	private isReadOnly = !this.isCart && !this.isAdmin;
	private isAdminOnly = !this.isCart && this.isAdmin;
	private originalPriceVersionId = this.isCart ? this.props.project.priceVersionId : this.props.order?.priceVersionId;

	private refetchCompactList: () => void = () => {};

	private validOrderStatus = [ 'REQUESTED', 'HOLD', 'CONFIRMED', 'DISPATCHED'];

	private fetchWeight = async () => {
		try {
			var url = '/api/entity/OrderEntity/';

			if (this.validOrderStatus.includes(this.orderDetails.status)){
				url += `calculateOrderWeight/${this.orderDetails.id}`;
			} else {
				url += `calculateWeights/${this.project.id}`;
			}

			const response = await axios.get(url);
			if (response && response.data) {
				runInAction(() => {
					this.weightDetails = response.data;
				});
			}
			
		} catch (error) {
			console.error('Error fetching weights:', error);
			// Handle error appropriately
		}
	}

	componentDidMount() {
		// Fetch the price list if the user is an admin
		if (this.isAdmin) {
			this.fetchPriceVersions();
		}
		this.fetchWeight();
	}

	private renderOrderStatus = () => {
		let contents;
		if (this.isCart) {
			contents = (
				<Button colors={Colors.Secondary}
						onClick={action(() => store.routerHistory.goBack())}>
					Close
				</Button>
			);
		} else {
			const updateOrderStatusDisabled = !this.isAdmin
				|| this.project.aptusJobNumber == null
				|| this.project.aptusJobNumber.trim() === '';

			contents = (
				<Combobox
					model={this.orderDetails}
					modelProperty='status'
					isDisabled={updateOrderStatusDisabled}
					label=''
					options={[
						{
							display: orderStatusOptions.REQUESTED,
							value: 'REQUESTED'
						},
						{
							display: orderStatusOptions.CONFIRMED,
							value: 'CONFIRMED'
						},
						{
							display: orderStatusOptions.HOLD,
							value: 'HOLD'
						},
						{
							display: orderStatusOptions.DISPATCHED,
							value: 'DISPATCHED'
						},
					]}
					searchable={false}
					isClearable={false}
					isRequired={true}
					onAfterChange={this.saveOrderStatus}
				/>
			);
		}

		return (
			<div className='order-status'>
				{contents}
			</div>
		);
	}

	private renderProjectDetails = () => {
		return (
			<div className='order-project-details'>
				<h4>Project Details</h4>

				<span>{this.props.project.organisation.name}</span>
				<span className='icon-location-pin icon-left'>{this.props.project.location}</span>
				<span>Job No. {this.props.project.clientJobNumber}</span>

				<If condition={this.isAdmin}>
					<span className='small'>Aptus Job Number</span>
					<TextField className='aptus-job-number'
							   onAfterChange={() => this.saveProject('aptusJobNumber')}
							   model={this.project}
							   modelProperty='aptusJobNumber' />
					<span className='small'>Price List</span>

					<Combobox
						label=''
						isClearable={false}
						searchable={false}
						isDisabled={this.orderDetails.status !== undefined && this.orderDetails.status !== 'REQUESTED'}
						model={this.isCart ? this.project : this.orderDetails}
						modelProperty='priceVersionId'
						onAfterChange={this.savePriceVersion}
						options={buildPriceVersionList(this.priceVersions)}/>
				</If>
			</div>
		);
	}

	private renderOrderInformation = () => {
		return (
			<div className='order-information'>
				<h4>Contact Information</h4>

				<div className='fieldset'>
					<ProjectDeliveryDetailsForm {...this.props} order={this.orderDetails} isRequired={true} isDisabled={this.isReadOnly} />
					<ProjectContactForm {...this.props} isDisabled={this.isReadOnly} />

					{/*	Purchase order number */}
					<TextField label='Client purchase order number'
							   isRequired={true}
							   model={this.orderDetails}
							   modelProperty='orderNumber' 
							   isDisabled={this.isReadOnly}/>
							  
					{this.isAdmin ?
						<>	
							<h5>Order Request Notes</h5>
							<p>{this.orderDetails.clientNote}</p>
							<h5>Notes from Aptus</h5>
							<TextArea model={this.orderDetails}
									modelProperty='aptusNote'
									placeholder="Are there any notes you'd like to add?" />
							<If condition={!this.isCart}>
								<Button className='submit-order-button'
										colors={Colors.Tertiary}
										widths={Widths.fullWidth}
										sizes={Sizes.Large}
										onClick={this.saveOrder}>
									Save
								</Button>
							</If>
						</> :
						<>
							<h5>Order Request Notes</h5>
							<TextArea model={this.orderDetails}
									  modelProperty='clientNote'
									  placeholder="Are there any notes you'd like to tell us?" 
									  isDisabled={this.isReadOnly}/>
						</>}
				</div>
			</div>
		);
	}

	
	private renderCartButton = (status: IOrderStatusResult) => {
		if (!this.isCart) {
			return <></>;
		}

		runInAction(() => {
			this.orderDetails.totalPrice = status.shutterPrice + status.elementPrice;
		});
		
		return (
			<span>
				<div className="order-container">
					{/* ------------------ Total Cost Display ------------------  */}
					<h5>
						{`Total ${formatPrice(this.orderDetails.totalPrice, this.project.country)}`}
					</h5>

					{/* ------------------ Weight Info Dropdown ------------------  */}
					<WeightDropDown
						totalWeight={this.weightDetails.totalWeight}
						barWeight={this.weightDetails.barWeight}
						componentWeight={this.weightDetails.componentWeight}
						shutterWeight={this.weightDetails.shutterWeight}
						dropdownPosition="top"
					/>

					{/* ------------------ Submit Button ------------------  */}
					<Button className='submit-order-button'
							disabled={status.elementCount + status.shutterCount === 0}
							colors={Colors.Tertiary}
							widths={Widths.auto}
							sizes={Sizes.Large}
							icon={{ icon: 'arrow-right', iconPos: 'icon-right' }}
							onClick={this.saveOrder}>
						Submit order request
					</Button>
				</div>

			</span>
		);
	}

	private renderProductListHeader = () => {
		return (
			<span>
			<div className='header-row'>
				<h4 style={{marginRight: '100px'}}>Order Summary</h4>

				<If condition={this.isAdminOnly}>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group" key='additionalActions'>
						<Button
							onClick={this.exportOrderRequest}
							className="action-btn"
							colors={Colors.Secondary}
							icon={{ icon: "download", iconPos: 'icon-left' }}>
							Export as Excel
						</Button>
					</ButtonGroup>
				</If>
			</div>
			
			<WeightDropDown
				totalWeight={this.weightDetails.totalWeight}
				barWeight={this.weightDetails.barWeight}
				componentWeight={this.weightDetails.componentWeight}
				shutterWeight={this.weightDetails.shutterWeight}
				containerStyle={{marginBottom: "20px"}}
			/>

			</span>
		);
	}

	public render() {
		return (
			<div className='project page'>
				<div className='project-content'>
					{this.renderOrderStatus()}
					<BreadcrumbsWrap project={this.props.project} isCart={this.isCart} order={this.props.order}/>
					{this.renderProjectDetails()}

					<div className='elements-tab order-content'>
						{this.renderOrderInformation()}
						<CompactProjectItemsProject
							projectId={this.project.id}
							orderId={this.orderDetails.id}
							topContent={this.renderProductListHeader}
							bottomContent={this.renderCartButton}
							isReadOnly={this.isReadOnly}
							bubbleUpRefetch={this.setCompactListRefetch}
							country={this.project.country}
							fetchWeight={this.fetchWeight}
						/>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Save the project, copying across the attributes
	 */
	private saveProject = _.debounce(action(async (attribute: string) => {
		// Copy the attribute to the original project
		this.props.project[attribute] = this.project[attribute];

		// Save the original project
		this.props.project.save()
			.catch(() => alert('Could not save the project', 'error'));
	}), 300);
	
	private saveOrderStatus = () => {
		this.orderDetails.save()
			.catch(() => alert('Could not save the order status', 'error'));
	}

	private saveOrder = async () => {
		if (!this.validateOrder()) {
			alert('Please complete the contact information', 'error');
			return;
		}

		this.orderDetails.assignAttributes({
			status: 'REQUESTED',
			orderDate: new Date(),
			projectId: this.project.id,
			priceVersionId: this.project.priceVersionId,
		});

		await this.project.validate();
		await this.orderDetails.validate();

		if (this.project.hasValidationError || this.orderDetails.hasValidationError) {
			if (this.orderDetails.validationErrors['orderNumber']) {
				alert('Order number is not unique', 'error');
			} else {
				alert('Could not create the order', 'error');
			}
			return;
		}
		
		// check for order number duplicates within the organisation
		const validatedOrderNumber = await axios.get(`/api/entity/OrderEntity/uniqueOrderId
						/${this.orderDetails.orderNumber}/${this.project.organisationId}/${this.orderDetails.id ?? ''}`);
		runInAction(() => this.orderDetails.orderNumber = validatedOrderNumber.data);

		try {
			// Save the project contact
			await this.project.save();
			await this.orderDetails.save();
			await axios.post(
				`/api/entity/OrderEntity/putCartItemsInOrder/${this.orderDetails.id}/${this.project.id}`);

			SmartlookService.triggerEvent('Order Request Submitted');
		} catch (e) {
			alert('Could not create the order', 'error');
			console.error(e);

			return;
		}

		if (this.isAdminOnly) {
			alert('Successfully saved purchase order details', 'success');
		} else {
			store.routerHistory.push(`/ordersuccess/${this.orderDetails.id}`);
		}
	}

	private validateOrder = () => {
		return this.orderDetails.addressId != null
			&& this.orderDetails.orderNumber != null && this.orderDetails.validationErrors
	}

	private exportOrderRequest = async () => {
		SmartlookService.triggerEvent('Excel Export');
		await OrderRequestExport.generateOrderRequestExport(this.orderDetails.id, store.codeDict);
	}

	private savePriceVersion = async () => {
		let priceVersionId = this.isCart ? this.project.priceVersionId : this.orderDetails.priceVersionId;
		try {
			await axios.post(`/api/entity/ProjectEntity/setDefaultPriceVersion/${this.project.id}/${priceVersionId}`);
			this.fetchOrderPrice();
			this.refetchCompactList();

			alert('Successfully updated the price version', 'success');
			this.originalPriceVersionId = priceVersionId;
		} catch (e) {
			alert('Could not set the price version', 'error');
			
			runInAction(() => {
				if (this.isCart) {
					this.project.priceVersionId = this.originalPriceVersionId;
				} else {
					this.orderDetails.priceVersionId = this.originalPriceVersionId;
				}
			});
		}
	}

	private fetchPriceVersions = () => {
		const query = gql`query fetchPriceVersions {
			priceVersionEntitys {
				${PriceVersionEntity.getAttributes()}
			}
		}`;

		if (store.apolloClient) {
			store.apolloClient.query({query}).then(({data}) =>
				data['priceVersionEntitys'].forEach((details: Partial<IProjectEntityAttributes>) => {
					const priceVersion = new PriceVersionEntity(details);
					runInAction(() => this.priceVersions[priceVersion.id] = priceVersion);
				}));
		}
	}

	/**
	 * Fetch the order price and set it into this orderEntity
	 *
	 * This method is to fix a bug where after updating a price version, the local order entity didn't have the updated
	 * price and when saving the order entity again, will overwrite the price to the old priceFelements tot
	 */
	private fetchOrderPrice = () => {
		const query = gql`query fetchOrderPrice($orderId: ID) {
			orderEntity(id: $orderId) {
				totalPrice
			}
		}`;

		if (store.apolloClient) {
			store.apolloClient
				.query({
					query,
					variables: {"orderId": this.orderDetails.id},
					fetchPolicy: 'network-only',
				}).then(({data}) => {
					const orderPrice = data['orderEntity']['totalPrice'];
					runInAction(() => this.orderDetails.totalPrice = orderPrice);
				});
		}
	}

	// Set the method which refetches the compact list
	private setCompactListRefetch = (func: () => void) => this.refetchCompactList = func;

}