/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import Card from '../../Components/Card/Card';
import CardGroup from '../../Components/Card/CardGroup';
// % protected region % [Add any extra imports here] on begin
import AddUserToOrganisation from "../../Components/AdminHome/AddUserToOrganisation";
import UploadPartsList from "../../Components/AdminHome/UploadPartsList";
// % protected region % [Add any extra imports here] end

@observer
export default class AdminPage extends React.Component<RouteComponentProps> {

	public render() {
		// % protected region % [Override contents here] on begin
		return (
			<PageWrapper {...this.props}>
				<div className={'dashboard'}>
					<div className={'dashboard-heading'}>
						<h2>Admin Dashboard</h2>
						<p>Welcome to the admin section. You can ....</p>
					</div>
					<UploadPartsList {...this.props}/>
					<AddUserToOrganisation {...this.props}/>
				</div>
				
			</PageWrapper>
		);
		// % protected region % [Override contents here] end
	}
}
