/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsElementPartEntity } from 'Models/Security/Acl/VisitorsElementPartEntity';
import { AptusAdminElementPartEntity } from 'Models/Security/Acl/AptusAdminElementPartEntity';
import { UserElementPartEntity } from 'Models/Security/Acl/UserElementPartEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { store } from '../Store';
// % protected region % [Add any further imports here] end

export interface IElementPartEntityAttributes extends IModelAttributes {
	partCode: string;
	quantity: number;
	rate: number;
	price: number;
	dimensionA: number;
	dimensionB: number;
	dimensionC: number;
	dimensionD: number;
	pinDiameter: number;
	length: number;

	elementId?: string;
	element?: Models.ElementEntity | Models.IElementEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ElementPartEntity', 'Element Part')
// % protected region % [Customise your entity metadata here] end
export default class ElementPartEntity extends Model implements IElementPartEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsElementPartEntity(),
		new AptusAdminElementPartEntity(),
		new UserElementPartEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Part Code'] off begin
	/**
	 * Part code to order
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Part Code',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public partCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Part Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Quantity'] off begin
	/**
	 * Part order quantity
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Quantity',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public quantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Rate'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Rate',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public rate: number;
	// % protected region % [Modify props to the crud options here for attribute 'Rate'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	/**
	 * Final part price
	 */
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dimension A'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Dimension A',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public dimensionA: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dimension A'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dimension B'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Dimension B',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public dimensionB: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dimension B'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dimension C'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Dimension C',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public dimensionC: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dimension C'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dimension D'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Dimension D',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public dimensionD: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dimension D'] end

	// % protected region % [Modify props to the crud options here for attribute 'Pin Diameter'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Pin Diameter',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public pinDiameter: number;
	// % protected region % [Modify props to the crud options here for attribute 'Pin Diameter'] end

	// % protected region % [Modify props to the crud options here for attribute 'Length'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Length',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public length: number;
	// % protected region % [Modify props to the crud options here for attribute 'Length'] end

	/**
	 * Element to element part one to many
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Element'] off begin
		name: 'Element',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.ElementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Element'] end
	})
	public elementId?: string;
	@observable
	@attribute({isReference: true})
	public element: Models.ElementEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IElementPartEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IElementPartEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.partCode !== undefined) {
				this.partCode = attributes.partCode;
			}
			if (attributes.quantity !== undefined) {
				this.quantity = attributes.quantity;
			}
			if (attributes.rate !== undefined) {
				this.rate = attributes.rate;
			}
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.dimensionA !== undefined) {
				this.dimensionA = attributes.dimensionA;
			}
			if (attributes.dimensionB !== undefined) {
				this.dimensionB = attributes.dimensionB;
			}
			if (attributes.dimensionC !== undefined) {
				this.dimensionC = attributes.dimensionC;
			}
			if (attributes.dimensionD !== undefined) {
				this.dimensionD = attributes.dimensionD;
			}
			if (attributes.pinDiameter !== undefined) {
				this.pinDiameter = attributes.pinDiameter;
			}
			if (attributes.length !== undefined) {
				this.length = attributes.length;
			}
			if (attributes.elementId !== undefined) {
				this.elementId = attributes.elementId;
			}
			if (attributes.element !== undefined) {
				if (attributes.element === null) {
					this.element = attributes.element;
				} else {
					if (attributes.element instanceof Models.ElementEntity) {
						this.element = attributes.element;
						this.elementId = attributes.element.id;
					} else {
						this.element = new Models.ElementEntity(attributes.element);
						this.elementId = this.element.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		element {
			${Models.ElementEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	/**
	 * Ensure none of the dimensions of the part are negatives
	 */
	public hasInvalidDimensions(): boolean {
		return (this.quantity == undefined ||
			this.quantity <= 0 ||
			this.dimensionA < 0 || 
			this.dimensionB < 0 ||
			this.dimensionC < 0 || 
			this.dimensionD < 0 || 
			this.pinDiameter < 0);
	}

	/**
	 * Retrieve the description for this part from the code -> description dictionary in the store
	 *
	 * It will return null if the part doesn't exist in the list
	 */
	public getDescription(): string | null {
		const partCode = this.partCode ?? '';
		if (!!store.codeDict) {
			return store.codeDict[partCode.toUpperCase()];
		}

		return null;
	}

	/**
	 * Checks if fields should be editable based on type of this part
	 *
	 * @param attribute of the PartEntity to check if it should be enabled/disabled
	 */
	public shouldFieldBeEditable(attribute: string): boolean {
		// The part code should always be enabled (so a user can enter the part type)
		if (attribute === 'partCode') {
			return true;
		}

		// They should all be disabled until there a part code is entered
		if (this.partCode == null || this.partCode.trim() === '') {
			return false;
		}

		const dimensions = this.getDimensionCount();
		if (dimensions <= 1 && attribute === 'pinDiameter') {
			return false;
		} else if (dimensions < 1 && attribute === 'dimensionA') {
			return false;
		} else if (dimensions < 2 && attribute === 'dimensionB') {
			return false;
		} else if (dimensions < 3 && attribute === 'dimensionC') {
			return false;
		} else if (dimensions < 4 && attribute === 'dimensionD') {
			return false;
		}

		return true;
	}

	/**
	 * Return the number of dimensions for this part type
	 */
	public getDimensionCount(): number {
		if (this.getDescription() == null) {
			return 0;
		}

		// NOTE: This should reflect the `DimensionCount` method in `ElementPartEntity.cs`
		const partDimensions = {
			'SF': 1,
			'SM': 1,
			'SX': 1,
			'AM': 1,
			'AF': 1,
			'CF': 2,
			'HF': 2,
			'VF': 4,
			'ZF': 4,
		};

		const codeParts = this.partCode.split('-');

		// A part with a rebar always has 4 values seperated by '-'
		if (codeParts.length < 4){
			return 0;
		}

		const barType = codeParts[2].toUpperCase();
		const dimensions = partDimensions[barType];

		return dimensions ?? 0;
	}

	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ElementPartEntity.prototype, 'created');
CRUD(modifiedAttr)(ElementPartEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
