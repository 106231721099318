import React from 'react';
import { AddressEntity, IProjectEntityAttributes, OrderEntity, ProjectEntity } from '../../../Models/Entities';
import { Combobox, ComboboxOption } from '../Combobox/Combobox';
import If from '../If/If';
import { store } from '../../../Models/Store';
import { action, computed, observable, runInAction } from 'mobx';
import alert from '../../../Util/ToastifyUtils';
import { observer } from 'mobx-react';
import { type } from 'os';
import { DropdownProps } from 'semantic-ui-react';
import Modal from '../Modal/Modal';
import { confirmModal } from '../Modal/CustomModalUtils';
import { DuplicateElements } from '../Project/DuplicateElements';
import { getAttributeComponent } from '../CRUD/Attributes/AttributeFactory';
import { EntityFormMode } from '../Helpers/Common';
import { isRequired } from '../../../Util/EntityUtils';
import { Model } from '../../../Models/Model';
import { Button, Colors } from '../Button/Button';
import { sortAddress } from '../Profile/Company';

interface IProjectDeliveryDetailsFormProps {
	project: Partial<IProjectEntityAttributes> | ProjectEntity;
	order?: OrderEntity;

	isDisabled?: boolean;
	isRequired?: boolean;
	onChange?: () => void;
}

@observer
export default class ProjectDeliveryDetailsForm extends React.Component<IProjectDeliveryDetailsFormProps> {

	// Address entity model that is used when creating a new address (the modal will display if this has a value)
	@observable
	addressEntity?: AddressEntity = undefined;

	@observable addressList: {[key: string]: AddressEntity} = {};

	private addressListLength = 0;

	@observable
	private model = this.props.order ? this.props.order : this.props.project;

	componentDidMount() {
		this.fetchAddressList();
	}

	@action
	private fetchAddressList() {
		// If the user is an admin, use the organisation Id for the project
		const organisationId = store.organisationId ?? this.props.project.organisationId
		let addressId = undefined;

		AddressEntity.fetch<AddressEntity>({
			args: [[{path: 'organisationId', comparison: "equal", value: organisationId}]]
		}).then(data => runInAction(() => {
			data.forEach((address: AddressEntity) => {
				if (address.name.indexOf('#1') > 0) {
					addressId = address.id;
				}

				this.addressList[address.id] = address;
			});

			this.addressListLength = data.length;
		})).catch(() => alert('Could not fetch address list', 'error'));

		const project = this.props.project;
		const order = this.props.order;

		// Set the default on the project if it doesn't have an address id yet
		if (project.addressId === undefined) {
			project.addressId = addressId;
		}

		// If we are setting the address for an order entity, the default is set to the project address id
		if (order != null && order.addressId == null) {
			order.addressId = project.addressId ?? addressId;
		}
	}

	@computed private get buildAddressList(): ComboboxOption<string>[] {
		let addresses = Object.values(this.addressList)
			.sort(sortAddress)
			.map((address) : ComboboxOption<string> => {
				const addressName = `${address.name}: ${address.addressLine1}`;
				return { display: addressName, value: address.id };
			});

		// Insert this option at the start of the array unless a value is required
		if (!this.props.isRequired) {
			addresses.unshift({ display: 'No address selected', value: 'empty' });
		}

		if (!store.hasBackendAccess) {
			addresses.push({
				display: <div className='icon-plus icon-left'>Add address</div>,
				value: 'new address'
			});
		}

		return addresses;
	}

	private handleOnChange = async () => {
		if (this.model.addressId == 'new address') {
			const addressCount = (this.addressListLength == 0 ? 1 : this.addressListLength);
			this.addressEntity = new AddressEntity({
				name: 'Delivery Address #' + addressCount,
				organisationId: store.organisationId,
			});
		}
	}

	public render() {
		const addressId = this.model.addressId;
		const currentAddress = addressId ? this.addressList[addressId] : null;

		return (
			<>
				<Combobox
					model={this.model}
					modelProperty='addressId'
					label='Delivery address'
					placeholder='No address selected'
					options={this.buildAddressList}
					searchable={false}
					isDisabled={this.props.isDisabled}
					isRequired={this.props.isRequired}
					onChange={this.props.onChange}
					onAfterChange={this.handleOnChange} />
				<If condition={currentAddress !== null}>
					<div className='input-details'>
						<i>{ProjectDeliveryDetailsForm.formatAddressLine(currentAddress)}</i>
					</div>
				</If>
				{!!this.addressEntity ?
					<NewDeliveryAddressForm
						addressEntity={this.addressEntity}
						onSave={this.onSaveAddress}
						onClose={this.onModalClose} /> : undefined}
			</>
		);
	}

	@action
	private onModalClose = () => {
		const model = this.props.order ? this.props.order : this.props.project;

		// Set the addressId to anything except for the add address
		// This is a workaround so the user can reselect the add address and trigger a value change to show the modal
		const options = Object.keys(this.addressList);
		model.addressId = options.length !== 0 ? options[0] : undefined;

		this.addressEntity = undefined;
	}

	@action
	private onSaveAddress = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!this.addressEntity) {
			return;
		}

		await this.addressEntity.validate();

		if (!this.addressEntity.hasValidationError) {
			await this.addressEntity.save();

			this.fetchAddressList();

			const addressId = this.addressEntity.id;
			runInAction(() => {
				this.model.addressId = addressId;
				this.addressEntity = undefined;
			});
		} else {
			alert('Missing required fields', 'error');
		}
	}

	private static formatAddressLine(address: AddressEntity | null): string {
		if (!address) {
			return '';
		}

		const addressLine2 = address.addressLine2 === null ? '' : `${address.addressLine2}, `;
		return `${address.addressLine1}, ${addressLine2}${address.suburb}, ${address.postcode}, ${address.country}`;
	}
}

interface NewDeliveryAddressProps {
	addressEntity: AddressEntity;
	onClose: () => void;
	onSave: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

/**
 * Private component which handles the delivery address form
 * It's a class so it will re-render when validation fails
 */
@observer
class NewDeliveryAddressForm extends React.Component<NewDeliveryAddressProps> {
	render() {
		const registrationFieldList: string[] = [
			'addressLine1',
			'addressLine2',
			'suburb',
			'postcode',
			'state',
			'country',
		];

		const form = this.props.addressEntity.getAttributeCRUDOptions()
			.filter(attributeOption =>
				registrationFieldList.indexOf(attributeOption.attributeName) >= 0)
			.sort((a, b) =>
				registrationFieldList.indexOf(a.attributeName) - registrationFieldList.indexOf(b.attributeName))
			.map(attributeOption =>
				getAttributeComponent(
					attributeOption,
					this.props.addressEntity,
					this.props.addressEntity.getErrorsForAttribute(attributeOption.attributeName),
					EntityFormMode.EDIT,
					isRequired(this.props.addressEntity, attributeOption.attributeName)));

		return (
			<Modal isOpen={true} onRequestClose={this.props.onClose} label='New Delivery Address' className='new-address-modal'>
				<h3>
					New Delivery Address
				</h3>
				<form onSubmit={this.props.onSave}>
					<div className='modal-form'>
						{form}
					</div>

					<div key="actions" className="modal__actions">
						<Button className="modal--cancel" key={"cancel"} onClick={this.props.onClose} colors={Colors.Secondary}>
							Cancel
						</Button>
						<Button className="modal--confirm" key={"confirm"} type={'submit'} colors={Colors.Primary}>
							Add address
						</Button>
					</div>
				</form>
			</Modal>
		);
	}
}