/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsElementEntity } from 'Models/Security/Acl/VisitorsElementEntity';
import { AptusAdminElementEntity } from 'Models/Security/Acl/AptusAdminElementEntity';
import { UserElementEntity } from 'Models/Security/Acl/UserElementEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IElementEntityAttributes extends IModelAttributes {
	elementId: string;
	price: number;

	partOrders: Array<Models.ElementPartEntity | Models.IElementPartEntityAttributes>;
	product?: Models.ProductEntity | Models.IProductEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ElementEntity', 'Element')
// % protected region % [Customise your entity metadata here] end
export default class ElementEntity extends Model implements IElementEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsElementEntity(),
		new AptusAdminElementEntity(),
		new UserElementEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Element Id'] off begin
	/**
	 * Element Id
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Element Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public elementId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Element Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	/**
	 * Summed price of all parts
	 */
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	/**
	 * Element to element part one to many
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Part Order'] off begin
		name: "Part Orders",
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.ElementPartEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'partOrders',
			oppositeEntity: () => Models.ElementPartEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Part Order'] end
	})
	public partOrders: Models.ElementPartEntity[] = [];

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Product'] off begin
		name: 'Product',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ProductEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'products',
			oppositeEntity: () => Models.ProductEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Product'] end
	})
	public product?: Models.ProductEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IElementEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IElementEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.elementId !== undefined) {
				this.elementId = attributes.elementId;
			}
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.partOrders !== undefined && Array.isArray(attributes.partOrders)) {
				for (const model of attributes.partOrders) {
					if (model instanceof Models.ElementPartEntity) {
						this.partOrders.push(model);
					} else {
						this.partOrders.push(new Models.ElementPartEntity(model));
					}
				}
			}
			if (attributes.product !== undefined) {
				if (attributes.product === null) {
					this.product = attributes.product;
				} else {
					if (attributes.product instanceof Models.ProductEntity) {
						this.product = attributes.product;
					} else {
						this.product = new Models.ProductEntity(attributes.product);
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		partOrders {
			${Models.ElementPartEntity.getAttributes().join('\n')}
		}
		product {
			${Models.ProductEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			partOrders: {},
			product: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'partOrders',
							'product',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.elementId;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ElementEntity.prototype, 'created');
CRUD(modifiedAttr)(ElementEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
