import React from "react";
import { observable } from "mobx";
import { Alignment, ButtonGroup } from "../Button/ButtonGroup";
import { Button, Colors, Display } from "../Button/Button";
import { store } from "../../../Models/Store";
import { observer } from "mobx-react";
import { ProjectEntity } from "../../../Models/Entities";
import alert from "../../../Util/ToastifyUtils";
import { IProjectEntityAttributes } from "../../../Models/Entities/ProjectEntity";
import { ProjectInformationForm } from '../ProjectDetails/ProjectInformationForm';
import ProjectContactForm from '../ProjectDetails/ProjectContactForm';
import ProjectDeliveryDetailsForm from '../ProjectDetails/ProjectDeliveryDetailsForm';

@observer
export default class NewProject extends React.Component {

	@observable projectDetails: Partial<IProjectEntityAttributes> = {};

	private async createNewProject() {
		const project = new ProjectEntity({
			...this.projectDetails,
			organisationId: store.organisationId,
			addressId: this.projectDetails.addressId === 'empty' ? undefined : this.projectDetails.addressId
		});

		await project.validate();
		if (project.hasValidationError) {
			alert('Project validation failed', 'error');
			console.error(project.validationErrors);
			return;
		}

		try {
			await project.save();
			store.routerHistory.push(`/project/${project.id}`);
		} catch (e) {
			alert('Could not save project', 'error');
		}
	}

	private isFormValid() {
		const project = this.projectDetails;
		return !!(project.name
			&& project.clientJobNumber
			&& project.postcode
			&& project.country
			&& project.projectContactId);
	}

	private renderProjectInformation() {
		return (
			<div className='form-section'>
				<h3 className='section-title'>
					Project Information
				</h3>
				<ProjectInformationForm project={this.projectDetails} isCountryEditable={true}/>
			</div>
		);
	}

	private renderProjectContact() {
		return (
			<div className='form-section'>
				<h3 className='section-title'>
					Project Contact
				</h3>
				<ProjectContactForm project={this.projectDetails}/>
			</div>
		);
	}

	private renderDeliveryDetails() {
		return (
			<div className='form-section'>
				<h3 className='section-title'>
					Delivery Details
				</h3>
				<ProjectDeliveryDetailsForm project={this.projectDetails}/>
			</div>
		);
	}

	public render() {
		return (
			<div className='newproject-page'>
				<div className='project-sidebar'>
					<h1 className='summary-title'>Project Details</h1>
					<p className='summary-description'>
						Set up the basic information for your Aptus project.
					</p>
				</div>

				<div className='main-content'>
					<div className='form-area'>
						{this.renderProjectInformation()}
						<div className='form-row'>
							{this.renderProjectContact()}
							{this.renderDeliveryDetails()}
						</div>
					</div>

					<div className='bottom-bar'>
						<ButtonGroup alignment={Alignment.HORIZONTAL}>
							<Button
								colors={Colors.Secondary}
								onClick={() => store.routerHistory.goBack()}
							>
								Cancel
							</Button>
							<Button
								display={Display.Solid}
								icon={{icon: 'arrow-right', iconPos: 'icon-right'}}
								onClick={this.createNewProject.bind(this)}
								disabled={!this.isFormValid()}
							>
								Create Project
							</Button>
						</ButtonGroup>
					</div>
				</div>
			</div>
		);
	}

}