

interface WeightDropDownProps {
  totalWeight: number;
  barWeight: number;
  componentWeight: number;
  shutterWeight: number;
	containerStyle?: any;
	dropdownPosition?: "top" | "bottom"
}

const WeightDropDown = (props: WeightDropDownProps) => {
  const {totalWeight, barWeight, componentWeight, shutterWeight, containerStyle, dropdownPosition = "bottom"} = props

  return (
    <div className="weight-dropdown" style={containerStyle}>
			{/* --------- Dropdown Display --------- */}
			<div className="weight-dropdown-row">
					<span>Order Mass</span>
					<span><b>{totalWeight.toFixed(2)}T</b></span>
				</div>
			
			{/* --------- Dropdown Hidden --------- */}
			<div className={dropdownPosition === "bottom" ? "weight-dropdown-container" : "weight-dropdown-container dropbow-top-display"}>
				<div className="weight-dropdown-row">
					<span>Bar Mass</span>
					<span><b>{barWeight.toFixed(2)}T</b></span>
				</div>
				<div className="weight-dropdown-row">
					<span>Components</span>
					<span><b>{componentWeight.toFixed(2)}T</b></span>
				</div>

				<div className="weight-dropdown-row">
					<span>Shutters</span>
					<span><b>{shutterWeight.toFixed(2)}T</b></span>
				</div>						
		
			</div>
		</div>
  )
}

export default WeightDropDown