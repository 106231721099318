import React from 'react';
import { ProjectEntity } from '../../../../Models/Entities';
import { observer } from 'mobx-react';
import {action, observable} from 'mobx';
import {RouteComponentProps} from "react-router/index";
import OrderStatusFilter from "./OrderStatusFilter";
import {ApiQueryParams} from "../../ModelCollection/ModelAPIQuery";
import ImportParts, { ImportError, UploadPartsResponse } from '../ImportParts';
import {ElementCollection} from "../ProjectCollection/ElementCollection";
import AptusCodeGuide from "./AptusCodeGuide";

interface IElementListTileProps extends RouteComponentProps {
    project: ProjectEntity;
}

@observer
export default class ElementsTab extends React.Component<IElementListTileProps> {
    
    @observable
    private orderStatusFilter: string = 'all';

    @observable
    public isUploadPartsDisplayed: boolean = false;

    @observable
    private importStatus: UploadPartsResponse | undefined = undefined;

    @action
    private onOrderStatusChanged = (status: string) => {
        this.orderStatusFilter = status;
    }
    
    @action
    private setUploadPartsDisplay = (display: boolean) => {
        this.isUploadPartsDisplayed = display;
    }

    render() {
        const queryParams: ApiQueryParams = {
            'orderStatus': this.orderStatusFilter, 
            'productType': 'Element', 
        }; 
        
        return ( 
            <div className='elements-tab'>
                <div className='left-info-panel'>
                    <OrderStatusFilter onAfterChange={this.onOrderStatusChanged} projectId={this.props.project.id} productType={'Element'}/>
                    <h5>Aptus Code Guide</h5>
                    <AptusCodeGuide />
                </div>
                <div className='elements-list-block'>
                    <ElementCollection
                        projectId={this.props.project.id}
                        customFilter={queryParams}
                        showPanel={() => this.setUploadPartsDisplay(true)}
                        importErrors={this.importStatus}
                        country={this.props.project.country}
                    />
                </div>
                <ImportParts 
                    project={this.props.project} 
                    display={this.isUploadPartsDisplayed}
                    hidePanel={() => this.setUploadPartsDisplay(false)}
                    handleImportErrors={action(status => this.importStatus = status)}
                />
            </div>
        );
    };

}