import * as React from 'react';
import {observer} from 'mobx-react';
import {action, computed, observable, runInAction} from 'mobx';
import * as uuid from 'uuid';
import classNames from 'classnames';
import {ICollectionHeaderProps, ICollectionHeaderPropsPrivate} from "../../Collection/CollectionHeaders";
import ProductCollectionRow from "./ProductCollectionRow";
import {ElementEntity, ElementPartEntity, ProductEntity, ShutterEntity} from "../../../../Models/Entities";
import {Alignment, ButtonGroup} from "../../Button/ButtonGroup";
import {Button, Colors} from "../../Button/Button";
import alert from "../../../../Util/ToastifyUtils";
import {attachSketchModal} from "../../Modal/AttachSketchModal";
import If from '../../If/If';
import {store} from "../../../../Models/Store";


interface IValidationResult {
    errorMessage: string;
    hasValidationError: boolean;
}

export interface IShutterListCollectionProps {
    product: ProductEntity;

    /** Pass through for any props to pass to the top level section */
    innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

    onUpdateShutter: (shutter: ShutterEntity) => void;

    editMode?: boolean;

    isReadOnly?: boolean;

    onEditModeUpdated: (editMode: boolean) => boolean;

    onCancelEdit: () => void;
}

/**
 * Displays a collection of shutter quantity collection (only holds one row)
 * shutter to render are passed in as props (part of product)
 * some attributes such as quantity dimensions are editable.
 * When changes are saved, it is passed back up to ProductCollectionRow to handle
 */
@observer
export default class ShutterExpandedCollection extends React.Component<IShutterListCollectionProps> {
    
    @observable
    private shutter = this.props.product.shutter;

    @observable
    private editMode = this.props.editMode ?? false;

    private shutterQuantityHeaders: Array<ICollectionHeaderProps<ShutterEntity>> = [
        {
            name: 'topQuantity',
            displayName: 'Top Shutter Qty',
            sortable: false,
            transformItem: model => model.topQuantity ?? '-',
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'bottomQuantity',
            displayName: 'Bottom Shutter Qty',
            sortable: false,
            transformItem: model => model.bottomQuantity ?? '-',
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'taperedBottomQuantity',
            displayName: 'Tapered Bottom Qty',
            sortable: false,
            transformItem: model => model.taperedBottomQuantity ?? '-',
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'siteTemplateQuantity',
            displayName: 'Site Template Qty',
            sortable: false,
            transformItem: model => model.siteTemplateQuantity ?? '-',
            editable: true,
            columnSize: 'small',
        },
    ];
    
    /**
     * The headers of the collection
     */
    @computed
    private get headers(): Array<ICollectionHeaderPropsPrivate<ShutterEntity>> {
        let headers = this.shutterQuantityHeaders;

        return headers.map(header => {
            const computedHeader: ICollectionHeaderPropsPrivate<ShutterEntity> = {...header};

            if (typeof header.displayName === 'string') {
                computedHeader.headerName = header.displayName;
            } else if (typeof header.displayName === 'function') {
                computedHeader.headerName = header.displayName(header.name);
            }

            return computedHeader;
        });
    }

    public render() {
        return (
            <section
                {...this.props.innerProps}>
                <div className='parts-list'>
                    {this.list()}
                </div>
                <If condition={!this.props.isReadOnly}>
                    <div className='parts-actions'>
                        {this.renderButtonGroups()}
                    </div>
                </If>
            </section>
        );
    }

    /**
     * The table list component
     */
    private list = () => {
        const collectionId = uuid.v4();
        const className = classNames('collection__list');
        return (
            <section aria-label="collection list" className={className}>
                <table>
                    {this.header()}
                    <tbody>
                        <ProductCollectionRow
                            item={this.shutter}
                            headers={this.headers}
                            key={`01-${collectionId}`}
                            keyValue={`01-${collectionId}`}
                            className={'expand__item'}
                            editMode={this.editMode}
                            showExpandButton={() => false}
                            onEditModeUpdated={this.props.onEditModeUpdated}
                        />
                    </tbody>
                </table>
            </section>
        );
    }
    /**
     * The header row component
     */
    private header = () => {
        return (
            <thead>
            <tr className="parts-list__header">
                {this.headers.map((header, idx) => {
                    return (
                        <th key={idx} scope="col" >
                            {header.headerName}
                        </th>
                    );
                })}
            </tr>
            </thead>
        );
    }

    private renderButtonGroups = () => {
        const hasBeenOrdered = this.props.product.orderId != null;
        const isAdmin = store.hasBackendAccess;

        let contents;
        if (this.editMode) {
            contents =
                <>
                    <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group">
                        <Button colors={Colors.Secondary} onClick={this.cancelEdit}>Cancel</Button>
                        <Button colors={Colors.Tertiary} onClick={this.updateShutter}>Save</Button>
                    </ButtonGroup>
                </>;
        } else {
            contents =
                <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group">
                    <Button colors={Colors.Tertiary} onClick={this.onEdit} disabled={hasBeenOrdered && !isAdmin}>
                        Edit
                    </Button>
                </ButtonGroup>;

        }

        return contents;
    }

    @action
    private updateShutter = async () => {
            await this.shutter.validate();
            if (this.shutter.hasValidationError) {
                alert(`shutter has validation error`, 'error');
                console.error(this.shutter.validationErrors);
                return;
            }
        
            if (this.shutter.hasInvalidDimensions()) {
                alert(`${this.shutter.shutterId} has invalid dimensions`, 'error');
                return;
            }
            const additionalValidation:IValidationResult = this.validateShutter();
            if (additionalValidation.hasValidationError) {
                if (!!additionalValidation.errorMessage) {
                    alert(additionalValidation.errorMessage, 'error');
                }
                return; 
            }


            this.toggleEditMode();
            this.props.onUpdateShutter(this.shutter);
    }
    
    private validateShutter = () => {
        if (!this.shutter.shutterId) {
            return {
                errorMessage: 'Shutter Id needs to be filled in',
                hasValidationError: true
            };
        }

        // Only Shutter diameter can be provided
        // Otherwise it is width and depth
        // Cannot have all depth, width and diameter
        const isRoundAndSquare = (this.shutter.width || this.shutter.depth) && this.shutter.diameter;
        const isNeitherRoundOrSquare  = !(this.shutter.width && this.shutter.depth) && !this.shutter.diameter;
        if (isRoundAndSquare || isNeitherRoundOrSquare) {
            return {
                errorMessage: 'Either shutter width AND depth OR just the diameter needs to be filled in',
                hasValidationError: true
            };
        }
        
        if (!this.shutter.topQuantity && !this.shutter.bottomQuantity 
            && !this.shutter.taperedBottomQuantity && !this.shutter.siteTemplateQuantity) {
            return {
                errorMessage: 'At least one quantity field needs to be filled in',
                hasValidationError: true
            };
        }
        
        if (!this.shutter.sketchFileName) {
            attachSketchModal();
            return {
                errorMessage: '',
                hasValidationError: true
            };
        }
        
        return {errorMessage: '', hasValidationError: false};
    }

    @action
    private toggleEditMode = () => {
        this.editMode = !this.editMode;
        store.elementShutterEditMode = this.editMode;
        
        this.props.onEditModeUpdated(this.editMode);
    }

    @action
    private onEdit = () => {
        if (!store.showToastInEditMode("shutter")) {
            this.toggleEditMode();
        }
    }

    @action
    private cancelEdit = () => {
        this.toggleEditMode();

        this.props.onCancelEdit();
    }
}