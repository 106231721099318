import React, { Component } from 'react';
import RequestWrap from '../RequestWrap/RequestWrap';
import { store } from '../../../Models/Store';
import {
    OrderEntity,
    ProjectEntity
} from '../../../Models/Entities';
import Order from './Order';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import Project from "../Project/Project";

interface PurchaseOrderProps {
    orderId: string;
}

export default class PurchaseOrder extends Component<PurchaseOrderProps> {

    public render() {
        return (
            <RequestWrap request={this.performRequest} processData={this.processData}>
                {(order) => <Order project={order.project} order={order} isCart={false} isAdmin={store.hasBackendAccess}/>}
            </RequestWrap>
        );
    }

    private performRequest = async () => {
        const {data} =
            await axios.get(`${SERVER_URL}/api/entity/OrderEntity/fetchOrder/${this.props.orderId}`);

        let { order, assemblyCodeToDesc } = data;
        order = new OrderEntity(order);
        store.setCodeDict(assemblyCodeToDesc);

        // Put the project in an array because it is the expected return value
        return order;
    };

    private processData = (order: OrderEntity) => order;

}