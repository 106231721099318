import React, { Component } from 'react';
import RequestWrap from '../RequestWrap/RequestWrap';
import { store } from '../../../Models/Store';
import {
	ProjectEntity
} from '../../../Models/Entities';
import Order from './Order';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';

interface ReviewOrderProps {
	projectId: string;
}

export default class ReviewOrder extends Component<ReviewOrderProps> {

	public render() {
		return (
			<RequestWrap request={this.performRequest} processData={this.processData}>
				{(project) => <Order project={project} isCart={true} isAdmin={store.hasBackendAccess}/>}
			</RequestWrap>
		);
	}

	private performRequest = async () => {
		const {data} =
			await axios.get(`${SERVER_URL}/api/entity/ProjectEntity/fetchProject/${this.props.projectId}`);

		let { project, assemblyCodeToDesc } = data;
		project = new ProjectEntity(project);
		store.setCodeDict(assemblyCodeToDesc);

		// Put the project in an array because it is the expected return value
		return project;
	};

	private processData = (entity: ProjectEntity) => entity;

}