import Card from '../Card/Card';
import React from 'react';
import { UploadPartsResponse } from '../Project/ImportParts';
import SidePanel from '../SidePanel/SidePanel';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Alignment, ButtonGroup } from '../Button/ButtonGroup';
import { Button, Colors } from '../Button/Button';

interface ImportErrorReportProps {
	importReport: UploadPartsResponse;
}

@observer
export default class ImportErrorReport extends React.Component<ImportErrorReportProps> {

	@observable
	private showPanel = false;

	@action
	private setShowPanel(show: boolean) {
		this.showPanel = show;
	}

	private downloadErrorReport = () => {
		const contents = 'line number,error\n'
			+ this.props.importReport?.partErrors
				.map(error => `${error.lineNumber},${this.getErrorText(error.type)}`).join('\n');

		// This is to force the browser to download the file
		const dl = document.createElement('a');
		dl.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(contents));
		dl.setAttribute('download', 'import_error_report.csv');
		dl.click();
	};

	/**
	 * The server responds with the enum value as a string, this function turns it into a message to display to the user
	 */
	private getErrorText(error: string) {
		if (error === 'MissingMark') {
			return 'Missing Element ID';
		}

		if (error === 'InvalidDimensions') {
			return 'Invalid Part Dimensions';
		}

		return 'Unknown Error';
	}

	render() {
		const importErrorLength = this.props.importReport?.partErrors.length ?? 0;
		return (
			<>
				<Card icon='icon-warning' className='card--yellow' onClick={() => this.setShowPanel(true)}>
					<span>
						<span className='bold'>{importErrorLength} errors</span>
						{' '}found in the imported file.
						{' '}<span className='bold underline'>View error report</span>
					</span>
				</Card>

				<SidePanel display={this.showPanel} hidePanel={() => this.setShowPanel(false)} className='error-report'>
					<h3>Error Report</h3>
					<Card icon='icon-check-circle' className='card--blue full-width'>
						<span>
							<span className='bold'>{importErrorLength} elements</span> successfully imported.
						</span>
					</Card>
					<Card icon='icon-warning' className='card--yellow full-width'>
						<span>
							<span className='bold'>{importErrorLength} errors</span>
							{' '}were found in the imported file. Some elements were not imported.
						</span>
					</Card>

					<div className='part-error-table'>
						<table>
							<thead>
								<tr><th>Line</th><th>Error</th></tr>
							</thead>
							<tbody>
								{this.props.importReport.partErrors.map(e =>
									<tr key={e.lineNumber}>
										<td>{e.lineNumber}</td>
										<td>{this.getErrorText(e.type)}</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						<Button colors={Colors.Secondary} onClick={this.downloadErrorReport}>
							Download Report
						</Button>
						<Button colors={Colors.Primary} onClick={() => this.setShowPanel(false)}>
							Close
						</Button>
					</ButtonGroup>
				</SidePanel>
			</>
		);
	}
}