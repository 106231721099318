/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import Dashboard from "../Components/Dashboard/Dashboard";
import {ProjectEntity} from "../../Models/Entities";
import {action, observable} from "mobx";
import {RequestState} from "../../Models/Enums";
// % protected region % [Add any extra imports here] end

export interface DashboardPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class DashboardPage extends React.Component<DashboardPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
	public projectList: ProjectEntity[];

	@observable
	private requestState: RequestState = 'loading';

	public componentDidMount() {
		ProjectEntity.fetchActiveProjects().then(results => {
			this.changeRequestState('done', results);
		}).catch(e => {
			this.changeRequestState('error');
		});
	}

	@action
	private changeRequestState = (state: RequestState, projectList?: ProjectEntity[]) => {
		if (projectList) {
			this.projectList = projectList;
		}
		this.requestState = state;
	};
	
	private renderContents = () => {
		let contents = null;

		if (this.requestState === 'loading') {
			contents = <></>;
		} else if (this.requestState === 'error') {
			contents = (
				<div className="update-profile">
					<h3>There has been an error</h3>
					<p>Unable to load the project page at this time. Please contact us if the problem persists.</p>
				</div>
			);
		} else {
			contents = <Dashboard projectList={this.projectList}/>
		}

		return contents;
	}
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'AptusAdmin', 'User']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					{
					// % protected region % [Add code for e2c5ac79-554d-471a-b493-d89319d6649d here] on begin
					}
					{this.renderContents()}
					{
					// % protected region % [Add code for e2c5ac79-554d-471a-b493-d89319d6649d here] end
					}
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] off begin
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default DashboardPage;
// % protected region % [Override export here] end
