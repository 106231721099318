import React, {useContext} from 'react';
import { confirmModal } from '../Modal/CustomModalUtils';
import alert from '../../../Util/ToastifyUtils';
import { ElementEntity, ProductEntity } from '../../../Models/Entities';
import { TextField } from '../TextBox/TextBox';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Colors } from '../Button/Button';
import axios from 'axios';
import {IProjectContext, ProjectContext} from "./ProjectContext";
import SmartlookService from '../../../Services/SmartlookService';

type IDuplicateElements = {
	elementId: string,
	isDuplicate: boolean
};

export interface DuplicateElementsProps {
	product: ProductEntity,
	model: IDuplicateElements[],
}

export default function createDuplicateElementsModal(entity: ProductEntity, refetch: () => void, context: IProjectContext) {
	let model: IDuplicateElements[] = observable([]);

	if (entity.element == null) {
		return;
	}

	const onSave = async (confirmed: boolean) => {
		if (!confirmed) {
			return true;
		}

		// Send a request to the server
		const duplicateResult = await performRequest(entity, model);
		if (!duplicateResult) {
			alert('Element IDs are not unique', 'error');
			return false;
		}

		// Element status count should be updated
		if (context.setProjectContext) {
			await context.setProjectContext(false, true);
		}

		SmartlookService.triggerEvent('Saved Duplicated Elements');

		return true;
	};

	confirmModal('Duplicate Element', <DuplicateElements product={entity} model={model} />,
		{ confirmText: 'Duplicate', beforeClose: onSave })
		.then(async () => {
			alert('Successfully duplicated element', 'success');
		})
		.catch(() => {/* This is when the user responds no */})
}

@observer
export class DuplicateElements extends React.Component<DuplicateElementsProps> {

	componentDidMount() {
		// Start with at least one new element to duplicate
		this.addNewElementId();
	}

	private createListForElementIds = (model: IDuplicateElements[]) => {
		return model.map((model, i) => {
			let { isDuplicate } = model;
			let element = (i != 0) ? <div className='space'>{}</div> : <TextField
				model={this.props.product.element}
				modelProperty='elementId'
				label='Existing element name'
				isDisabled={true} />;
			return (
				<div key={i} className='row'>
					{element}
					<div className='icon-arrow-right icon-middle' />
					<TextField
						className={isDuplicate ? 'error-label' : ''}
						model={model}
						modelProperty={'elementId'}
						autoFocus={this.props.model.length - 1 == i}
						label='New element name'
					/>
				</div>
			);
		});
	}

	@action
	private addNewElementId = () => {
		this.props.model.push({
			elementId: this.props.product.element.elementId,
			isDuplicate: false,
		});
	}

	render() {
		/**
		 * FIXME Remove the hidden input
		 *
		 * Currently the component was not picking up changes to the model (this is because it is a nested object)
		 * To work around this, I tell the component to use the model's length, so it knows when it is changed
		 */
		return (
			<div className='duplicate-elements'>
				{this.createListForElementIds(this.props.model)}
				<div className='add-new-element'>
					<Button onClick={this.addNewElementId}
							icon={{icon: 'plus', iconPos: 'icon-left'}}
							colors={Colors.Secondary}>
						Duplicate again
					</Button>
				</div>
			</div>
		);
	}
}

const performRequest = async (entity: ProductEntity, model: IDuplicateElements[]): Promise<boolean> => {
	try {
		let { data } = await axios.post(`/api/entity/ProjectEntity/createElementDuplicates/`, {
			ProjectId: entity.projectId,
			ProductId: entity.id,
			NewElementIds: model.map(e => e.elementId),
		});

		const duplicatesFound: string[] = data['foundDuplicates'];
		if (duplicatesFound === null || duplicatesFound.length === 0) {
			return true;
		}

		runInAction(() => model.map(m => {
			m.isDuplicate = duplicatesFound.includes(m.elementId);
			return m;
		}));
		return false;
	} catch (e) {
		return false;
	}
};
