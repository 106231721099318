import * as XLSX from '@sheet/image';
import { Border, CellAddress, Style, Range, WorkSheet, CellObject, ColInfo, RowInfo } from "@sheet/image";
import {
	AddressEntity,
	ElementEntity,
	ElementPartEntity,
	OrderEntity,
	OrganisationEntity, ProductEntity,
	ProjectEntity, ShutterEntity
} from '../Models/Entities';
import gql from 'graphql-tag';
import { ICodeDict, store } from '../Models/Store';
import AptusLogo from '../Assets/images/aptus-logo-full-colour.png';
import axios from 'axios';
import moment from 'moment';
import * as Enums from "../Models/Enums";

interface Header<T, K> {
	title: string,
	columnWidth?: number,
	getValue?: (item: T, param?: K) => string,
	isNumber?: boolean;
	isPrice?: boolean;
	textAlignLeft?: boolean;
}

interface DataHeader<T, K> extends Header<T, K> {
	subHeadings?: Header<T, K>[]
}

type ShutterType = 'top' | 'bottom' | 'tapered' | 'site template';
const ShutterTypeList: ShutterType[] = [ 'top', 'bottom', 'tapered', 'site template' ];

// This type is used only when the value of the inner type doesnt matter
type AllDataHeaders = DataHeader<any, any>;

type ElementDataHeaders = DataHeader<ElementPartEntity, ICodeDict>;
type ShutterDataHeaders = DataHeader<ShutterEntity, ShutterType>;

export default class OrderRequestExport {

	// List of headers used for the elements table
	static elementHeaders: ElementDataHeaders[] = [
		{ title: 'Element\nNumber', getValue: part => `${part.element.product?.order.project.aptusJobNumber}-${part.element.elementId}` }, 
		{ title: 'Aptus Assembly\nName', textAlignLeft: true, getValue: (part, dictionary) =>
				dictionary ? dictionary[part.partCode] : ''},
		{ title: 'Assembly Code', textAlignLeft: true, getValue: part => part.partCode },
		{ title: 'Dim A', isNumber: true, getValue: part => numberToString(part.dimensionA) },
		{ title: 'Dim B', isNumber: true, getValue: part => numberToString(part.dimensionB) },
		{ title: 'Dim C', isNumber: true, getValue: part => numberToString(part.dimensionC) },
		{ title: 'Dim D', isNumber: true, getValue: part => numberToString(part.dimensionD) },
		{ title: 'Pin Dia', isNumber: true, getValue: part => numberToString(part.pinDiameter) },
		{ title: 'Length\nO/A', isNumber: true, getValue: part => numberToString(part.length) },
		{ title: 'Qty', isNumber: true, getValue: part => numberToString(part.quantity) },
		{ title: 'Rate', isPrice: true, getValue: part => numberToString(part.rate) },
		{ title: 'Amount', isPrice: true, getValue: part => numberToString(part.price) },
	];

	// List of headers used in the shutter table
	static shutterHeaders: ShutterDataHeaders[] = [
		{ title: 'Shutters & Site Templates', subHeadings: [
			{ title: 'Description', getValue: shutter => shutter.shutterId },
			{ title: 'Type', getValue: (shutter, type) => getShutterDisplayValue(type) }
		] },
		{ title: 'Element Dimensions', subHeadings: [
			{ title: 'Width(mm)', isNumber: true, getValue: shutter => numberToString(shutter.diameter ?? shutter.width) },
			{ title: 'Depth(mm)', isNumber: true, getValue: shutter => numberToString(shutter.diameter ?? shutter.depth) }
		] },
		{ title: 'Qty', isNumber: true, getValue: (shutter, type) =>
				getShutterQuantity(shutter, type).toString() },
		{ title: 'Rate', isPrice: true, getValue: (shutter, type) =>
				getShutterRate(shutter, type).toString() },
		{ title: 'Amount', isPrice: true, getValue: (shutter, type) =>
				(getShutterRate(shutter, type) * getShutterQuantity(shutter, type)).toString() },
	];

	// Constants that are used to position items on the spreadsheet
	static spreadsheetConstants = {
		customerDetailsRowOffset: 6,
		orderDetailsRowOffset: 4,
		orderDetailsColOffset: 15,
		productTableRowOffset: 14
	};

	// Styles that are used multiple times through the spreadsheet
	static styles = {
		border: {
			style: 'thin',
			color: {
				rgb: '#000000'
			}
		} as Border,
	}

	/**
	 * Fetch the order details we need to generate the excel file
	 *
	 * @param orderId is the id of the order to fetch data for
	 */
	static async fetchOrderRequestData(orderId: string): Promise<OrderEntity> {
		const orderQuery = gql`query orderRequestDetails($args: [WhereExpressionGraph]) {
			orderEntitys(where: $args) {
				${OrderEntity.getAttributes()}
				address {
					${AddressEntity.getAttributes()}
				},
				project {
					${ProjectEntity.getAttributes()}
					organisation {
						${OrganisationEntity.getAttributes()}
					}
				},
				productss (orderBy: { path: "Created", descending: false }) {
					element {
						${ElementEntity.getAttributes()}
						partOrders {
							${ElementPartEntity.getAttributes()}
						}
					}
					shutter {
						${ShutterEntity.getAttributes()}
					}
				}
			}
		}`;

		const orderDetailsData = await store.apolloClient.query({
			query: orderQuery,
			variables: { args: [{path: "id", comparison: "equal", value: orderId}] },
			fetchPolicy: 'network-only'
		});

		const orderDetails = orderDetailsData.data['orderEntitys'][0];

		return new OrderEntity(orderDetails);
	}

	/**
	 * This is the main entry point to generating the excel file
	 *
	 * @param orderId to generate an order request for
	 * @param partsDictionary a dictionary of parts and their description
	 */
	static async generateOrderRequestExport(orderId: string, partsDictionary: ICodeDict) {
		const order = await this.fetchOrderRequestData(orderId);

		const workbook = XLSX.utils.book_new();
		const worksheet = await this.generateSheet(order, partsDictionary);

		XLSX.utils.book_append_sheet(workbook, worksheet, 'Order Request');

		// This will force a download of the excel file
		const filename = `${order.orderNumber}_${getDateString(order.orderDate)}.xlsx`;
		XLSX.writeFile(workbook, filename, { cellStyles: true, bookImages: true });
	}

	static async generateSheet(order: OrderEntity, partsDictionary: ICodeDict): Promise<WorkSheet> {
		const tableOffset = this.spreadsheetConstants.productTableRowOffset;

		// Add an offset at the top to give room for the customer and order details
		let sheetCells: string[][] = Array(tableOffset).fill([['']], 0, tableOffset);

		// Add all the data to the table (it is much simpler to do this while in array form)
		this.addProductTables(sheetCells, order, partsDictionary, tableOffset);
		this.addCustomerDetails(sheetCells, order);
		this.addOrderDetails(sheetCells, order);

		// This converts the sheetCells into an array that can now be styled
		let sheet = XLSX.utils.aoa_to_sheet(sheetCells);

		// Now the sheet can be styled
		this.styleCustomerDetails(sheet);
		this.styleOrderRequestDetails(sheet);
		this.styleProductTable(sheet, order.productss);
		this.setRowColumnWidths(sheet);
		await this.addHeaderImage(sheet);

		return sheet;
	}

	/**
	 * Add the products into their correct position on the spreadsheet
	 *
	 * @param sheetCells is the 2 dimensional array where the data will be inserted
	 * @param products the list of products in the order
	 * @param partsDictionary dictionary of part codes to description
	 * @param topOffset the number of rows offset from the top
	 */
	static addProductTables(sheetCells: string[][], order: OrderEntity,
							partsDictionary: ICodeDict,
							topOffset: number) {
		this.addProductHeaders(sheetCells);

		// Add two because the table header uses two rows
		const tableDataOffset = topOffset + 2;

		const products = order.productss;

		// Get products with elements
		const elements = products.filter(product => product.element);
		
		// Order the elements if order applied
		if (store.sortDirectionElement !== "NONE") {
			store.sortProducts("ELEMENT", elements);
		};

		// Filter the elements in the list of products and add each part as a new row to the table
		elements.forEach(product => {
			const element = product.element;

			// Attach product and order back to element
			element.product = product;
			element.product.order = order;

			element.partOrders.forEach(part => {
				part.element = element;

				sheetCells.push(this.handleProductRow(part, partsDictionary, this.elementHeaders));
			});
		});

		// Add an empty column to each row with data in it
		// (this is for the small gap between the element and shutters table)
		sheetCells.forEach(row => {
			if (row.length !== 0) {
				row.push('');
			}
		});

		// Calculate the length of headers for elements (this is how many cells the shutters table is offset by)
		const elementHeadersLength = getHeaderLength(this.elementHeaders);

		// Add the shutters to the table
		let shutterIndex = 0;

		// Get products with shutters
		const shutters = products.filter(product => product.shutter);
		
		// Order the elements if order applied
		if (store.sortDirectionShutter !== "NONE") {
			store.sortProducts("SHUTTER", shutters);
		};

		shutters.forEach(product => {
			const shutter = product.shutter;

			ShutterTypeList.forEach(type => {
				const quantity = getShutterQuantity(shutter, type);
				if (quantity == null || quantity === 0) {
					return;
				}

				const row: string[] = this.handleProductRow(shutter, type, this.shutterHeaders);

				const oldRow = sheetCells[tableDataOffset + shutterIndex];
				if (oldRow === undefined) {
					// Create a new row with an offset to put it into the shutters table (+1 to take into account
					// the space between the element and shutter table)
					sheetCells.push(Array(elementHeadersLength + 1).concat(row));
				} else {
					// Alter the old row to put this shutter row after the element table
					sheetCells[tableDataOffset + shutterIndex] = oldRow.concat(row);
				}

				shutterIndex += 1;
			});
		});
	}

	/**
	 * Create a row of data for each header
	 */
	static handleProductRow<T, K>(product: T, param: K, headers: DataHeader<T, K>[]): string[] {
		let row: string[] = [];

		const handleHeader = (header: DataHeader<T, K>) => {
			if (!header.getValue) {
				row.push('-');
				return;
			}

			let value = header.getValue(product, param);
			if (value === '' && header.isPrice) {
				value = '0';
			}

			row.push(value);
		};

		// Add a value for each header (and also for sub headings)
		headers.forEach(header => {
			if (header.subHeadings) {
				header.subHeadings.forEach(handleHeader)
			} else {
				handleHeader(header);
			}
		});

		return row;
	}

	/**
	 * Add the headers for the product table to the array
	 */
	static addProductHeaders(sheetCells: string[][]) {
		let topRow: string[] = [];
		let bottomRow: string[] = [];

		// Using any because it doesn't matter what the type of the header is
		const handleHeader = (header: AllDataHeaders) => {
			if (header.subHeadings) {
				header.subHeadings.forEach((subheading, index) => {
					topRow.push((index === 0) ? header.title : '');

					bottomRow.push(subheading.title);
				});
			} else {
				topRow.push(header.title);
				bottomRow.push('');
			}
		};

		this.elementHeaders.forEach(header => {
			handleHeader(header);
		});

		// Add an empty row between the element and shutter headers
		topRow.push('');
		bottomRow.push('');

		this.shutterHeaders.forEach(header => {
			handleHeader(header);
		});

		sheetCells.push(topRow);
		sheetCells.push(bottomRow);
	}

	/**
	 * Add the customer details into the array
	 */
	static addCustomerDetails(sheetCells: string[][], order: OrderEntity) {
		const customerDetailsOffset = this.spreadsheetConstants.customerDetailsRowOffset;

		const address = order.address;
		let addressDetails = ['Customer:'];

		addressDetails.push(order.project.organisation.name);
		addressDetails.push(address.addressLine1);

		const addressLine2 = address.addressLine2;
		if (addressLine2 != null && addressLine2 != "") {
			addressDetails.push(addressLine2);
		}

		addressDetails.push(`${address.suburb} ${address.state} ${address.postcode}`);
		
		addressDetails.push(`Project Country: ${Enums.countriesOptions[order.project.country]}`);

		addressDetails.forEach((text, index) => {
			sheetCells[customerDetailsOffset + index] = [text];
		});
	}

	/**
	 * Add the order details to the cells
	 */
	static addOrderDetails(sheetCells: string[][], order: OrderEntity) {
		const orderDetailsColOffset = this.spreadsheetConstants.orderDetailsColOffset;
		const orderDetailsRowOffset = this.spreadsheetConstants.orderDetailsRowOffset;

		let orderDetails = [];

		orderDetails.push(['Order Request']);
		// Need to add an empty row because the above title takes up two rows
		orderDetails.push([]);

		orderDetails.push(['Purchase Order No.',
			'', // Add empty columns to account for cells that will be merged later
			'',
			'Order Date']);
		orderDetails.push([order.orderNumber, '', '', getDateString(order.orderDate)]);

		orderDetails.forEach((row, index) => {
			let currentRow = sheetCells[orderDetailsRowOffset + index];
			if (currentRow == null) {
				sheetCells[orderDetailsRowOffset + index] = [];
			}
			sheetCells[orderDetailsRowOffset + index] =
				currentRow.concat(Array(orderDetailsColOffset - currentRow.length), row);
		});
	}

	/**
	 * Add the Aptus logo to the top of the spreadsheet
	 *
	 * @param worksheet to add the image to
	 */
	static async addHeaderImage(worksheet: WorkSheet) {
		if (!worksheet['!images']) {
			worksheet['!images'] = [];
		}

		if (!worksheet['!merges']) {
			worksheet['!merges'] = [];
		}

		const image = await this.readImageToBase64(AptusLogo);
		if (!image) {
			return;
		}

		worksheet['!images'].push({
			'!pos': { r: 2, c: 0, x: 10, y: 5, h: 110, w: 380 },
			'!datatype': 'base64',
			'!data': image,
		});

		worksheet['!merges'].push({
			s: { c: 0, r: 2 },
			e: { c: 1, r: 5 },
		});
	}

	/**
	 * Request the image at the url and return the base64 encoded version of it
	 *
	 * @param url of the image
	 * @returns a base64 encode
	 */
	static async readImageToBase64(url: string): Promise<string | null> {
		return new Promise(async (resolve, reject) => {
			const { data } = await axios.get(url, { responseType: 'blob' });

			const reader = new FileReader();
			reader.onload = (event) => {
				const result = event?.target?.result;

				if (typeof result === 'string') {
					resolve(result);
				} else {
					reject();
				}
			};

			reader.readAsDataURL(data);
		});
	}

	/**
	 * Style the products table (both the elements and shutters)
	 */
	static styleProductTable(sheet: WorkSheet, products: ProductEntity[]) {
		this.styleTableHeaders(sheet);

		const tableOffset = this.spreadsheetConstants.productTableRowOffset + 2;

		let row = 0;
		const styleRow = (rowOffset: number, headers: AllDataHeaders[]) => {
			let column = 0;

			const styleCell = (header: AllDataHeaders) => {
				const cell = { c: rowOffset + column, r: tableOffset + row };
				createBorderForCell(sheet, cell, this.styles.border);
				setCellStyles(sheet, cell , {
					sz: 14,
					alignment: {
						horizontal: header.textAlignLeft ? 'left' : 'center',
						vertical: 'center',
					}
				});

				if (header.isNumber) {
					setNumberFormattingForCell(sheet, cell);
				}
				
				if (header.isPrice) {
					setPriceFormattingForCell(sheet, cell);
				}

				column += 1;
			}

			headers.forEach(header => {
				if (header.subHeadings) {
					header.subHeadings.forEach(styleCell);
				} else {
					styleCell(header);
				}
			});
		};

		products.filter(product => product.element).forEach(product => {
			product.element.partOrders.forEach(_ => {
				styleRow(0, this.elementHeaders);
				row += 1;
			});
		});

		const shutterRowOffset = getHeaderLength(this.elementHeaders) + 1;
		row = 0;
		products.filter(product => product.shutter).forEach(product => {
			ShutterTypeList.forEach(type => {
				const quantity = getShutterQuantity(product.shutter, type);
				if (quantity == null || quantity === 0) {
					return;
				}

				styleRow(shutterRowOffset, this.shutterHeaders);
				row += 1;
			});
		});
	}

	/**
	 * Style the headers for the tables
	 *
	 * @param sheet to style
	 */
	static styleTableHeaders(sheet: WorkSheet) {
		const tableOffset = this.spreadsheetConstants.productTableRowOffset;

		const addHeaderStyling = (from: CellAddress, to: CellAddress, header: AllDataHeaders) => {
			mergeCells(sheet, from, to);
			setCellStyles(sheet, from, {
				sz: 16,
				bold: true,
				alignment: {
					wrapText: true,
					horizontal: header.textAlignLeft ? 'left' : 'center', // Certain columns are left aligned
					vertical: 'center'
				}
			});

			createBorderForRange(sheet, createRange(from, to), this.styles.border);
		}

		let column = 0;
		const handleHeader = (header: AllDataHeaders) => {
			if (header.subHeadings) {
				const from = { c: column, r: tableOffset };
				const to = { c: column + header.subHeadings.length - 1, r: tableOffset };

				addHeaderStyling(from, to, header);

				header.subHeadings.forEach(subheading => {
					const from = { c: column, r: tableOffset + 1 };
					const to = { c: column, r: tableOffset + 1 };

					addHeaderStyling(from, to, subheading);

					column += 1;
				});
			} else {
				const from = { c: column, r: tableOffset };
				const to = { c: column, r: tableOffset + 1 };

				addHeaderStyling(from, to, header);

				column += 1;
			}
		}

		// Merge header rows and set font style
		this.elementHeaders.forEach(handleHeader);

		// Add the column for the gap
		column += 1;

		this.shutterHeaders.forEach(handleHeader);
	}

	/**
	 * Merge the cells of the customer details and add a border
	 *
	 * @param sheet to style
	 */
	static styleCustomerDetails(sheet: WorkSheet) {
		let width = 9;
		for (let i = 6; i < 12; i++) {
			// Apply a merge to the relevant cells
			mergeCells(sheet, { c: 0, r: i }, { c: width - 1, r: i });

			setCellStyles(sheet, { c: 0, r: i }, {
				sz: 18,
			});
		}

		const range: Range = {
			s: { r: 6, c: 0 },
			e: { r: 11, c: width - 1 }
		};
		createBorderForRange(sheet, range, this.styles.border);

		// Setting these borders are to fix a small bug when importing into excel wont show these borders
		setCellStyles(sheet, { r: 5, c: 0 }, {
			bottom: this.styles.border,
		});
		setCellStyles(sheet, { r: 5, c: 1 }, {
			bottom: this.styles.border,
		});
	}

	/**
	 * Style the order request details that appear on the top right of the export
	 */
	static styleOrderRequestDetails(sheet: WorkSheet) {
		const border = this.styles.border;

		const cells: Range[] = [
			// 'Order Request' title
			createRange({ c: 15, r: 4 }, { c: 19, r: 5 }),

			// Purchase order No. title
			createRange({ c: 15, r: 6 }, { c: 17, r: 6 }),

			// Order Date title
			createRange({ c: 18, r: 6 }, { c: 19, r: 6 }),

			// Purchase order value
			createRange({ c: 15, r: 7 }, { c: 17, r: 7 }),

			// Order date value
			createRange({ c: 18, r: 7 }, { c: 19, r: 7 }),

			// Blank space
			createRange({ c: 15, r: 8 }, { c: 19, r: 11 }),
		];

		cells.forEach((range, index) => {
			mergeRange(sheet, range);
			setCellStyles(sheet, range.s, {
				sz: (index === 0) ? 48 : 18,
				bold: (index === 0),
				alignment: {
					horizontal: 'center',
					vertical: 'center'
				}
			});

			createBorderForRange(sheet, range, border);
		});
	}

	/**
	 * Sets the dimensions of the cells based on a list of hardcoded column widths and row heights
	 * (these values can be derived from the template excel)
	 */
	static setRowColumnWidths(sheet: WorkSheet) {
		// For each column in the spreadsheet
		const columns = [
			131, // A
			379, // B
			167, // C
			75, // D
			73, // E
			73, // F
			73, // G
			105, // H
			90, // I
			71, // J
			111, // K
			155, // L
			19, // M
			154, // N
			196, // O
			114, // P
			114, // Q
			95, // R
			127, // S
			119, // T
		];

		sheet['!cols'] = columns.map(column => ({ wpx: column } as ColInfo));

		// For each row in the spreadsheet
		let rows = [
			82, // 1
			39, // 2
			39, // 3
			20, // 4
			40, // 5
			40, // 6
			40, // 7
			39, // 8
			32, // 9
			31, // 10
			31, // 11
			31, // 12
			31, // 13
			19, // 14
			19, // 15
			28, // 16
			24, // 17
		];

		sheet['!rows'] = rows.map(row => ({ hpx: row } as RowInfo));

		// Set the default dimensions of the cells
		sheet['!sheetFormat'] = {
			col: { wpx: 31 },
			row: { hpx: 24 },
		};
	}
}

/**
 * Helper method to get the length of the headers
 *
 * @param headers the headers to count (any is used because it doesn't matter what type the inner value is)
 */
function getHeaderLength(headers: AllDataHeaders[]): number {
	return headers
		.reduce(
			(total: number, header) => total + (header.subHeadings ? header.subHeadings?.length : 1),
			0);
}

/**
 * Helper method to convert a number value to string
 * (this is slightly nicer than using the ?? operator)
 */
function numberToString(value?: number) {
	if (value) {
		return value.toString();
	}

	return '';
}

/**
 * Private helper method to convert date object into the format needed
 */
function getDateString(date: Date) {
	return moment(date).format('D-MMM-YYYY');
}

/**
 * Add styles to a particular cell
 */
function setCellStyles(sheet: WorkSheet, cell: CellAddress, style: Style) {
	const cellItem = getCell(sheet, cell);

	// Merge the current styles and the new styles together
	cellItem.s = {
		...cellItem.s,
		...style
	};
}

/**
 * Create a border for a cell
 *
 * @param sheet to alter
 * @param cell to add the border to
 * @param border styles
 */
function createBorderForCell(sheet: WorkSheet, cell: CellAddress, border: Border) {
	setCellStyles(sheet, cell, {
		left: border,
		top: border,
		right: border,
		bottom: border,
	})
}

/**
 * Create a border around a group of cells
 *
 * @param sheet to alter
 * @param range of cells to create a border around
 * @param border styles to use as the border
 */
function createBorderForRange(sheet: WorkSheet, range: Range, border: Border) {
	const yStart = range.s.r;
	const yEnd = range.e.r;
	const xStart = range.s.c;
	const xEnd = range.e.c;

	// Loop vertically and apply a border to each side of the box
	for (let i = yStart; i <= yEnd; i++) {
		setCellStyles(sheet, { c: xStart, r: i }, {
			left: border
		});
		setCellStyles(sheet, { c: xEnd, r: i }, {
			right: border
		});
	}

	// Loop horizontally and add a border to the top and bottom of the box
	for (let i = xStart; i <= xEnd; i++) {
		setCellStyles(sheet, { c: i, r: yStart }, {
			top: border
		});

		setCellStyles(sheet, { c: i, r: yEnd }, {
			bottom: border
		});
	}
}

/**
 * Merge a range of cells together into a single cell
 *
 * @param sheet to use
 * @param from this cell
 * @param to this cell
 */
function mergeCells(sheet: WorkSheet, from: CellAddress, to: CellAddress) {
	mergeRange(sheet, createRange(from, to));
}

function mergeRange(sheet: WorkSheet, range: Range) {
	let merge = sheet['!merges'] || [];
	sheet['!merges'] = merge;

	merge.push(range);
}

/**
 * Create a Range type used in the sheetjs library
 *
 * This is to hide the inner library type
 *
 * @param from this cell
 * @param to this cell
 */
function createRange(from: CellAddress, to: CellAddress): Range {
	// s = start, e = end (this is the data type the library exposes)
	return { s: from, e: to };
}

/**
 * Sets the cell to use the pricing format given in the template excel
 */
function setPriceFormattingForCell(sheet: WorkSheet, cell: CellAddress) {
	const cellObject = getCell(sheet, cell);

	cellObject.t = 'n';

	// This format was copied from the template excel file that was given by the client
	cellObject.z = '_-$* #,##0.00_-;-$* #,##0.00_-;_-$* "-"??_-;_-@_-';
}

function setNumberFormattingForCell(sheet: WorkSheet, cell: CellAddress) {
	const cellObject = getCell(sheet, cell);

	cellObject.t = 'n';
	cellObject.z = '0';
}

/**
 * Retrieves a cell given it's address; Creating a default value if it does not exist yet
 *
 * @param sheet
 * @param cell
 */
function getCell(sheet: WorkSheet, cell: CellAddress): CellObject {
	let cellRef = XLSX.utils.encode_cell(cell);

	// Create an empty cell object if it doesn't exist already
	if (!sheet[cellRef]) {
		sheet[cellRef] = {
			v: '',
			t: 's'
		};
	}

	return sheet[cellRef];
}

function getShutterQuantity(shutter: ShutterEntity, type?: ShutterType): number {
	switch (type) {
		case 'top':
			return shutter.topQuantity;
		case 'site template':
			return shutter.siteTemplateQuantity;
		case 'bottom':
			return shutter.bottomQuantity;
		case 'tapered':
			return shutter.taperedBottomQuantity;
		default:
			return 0;
	}
}

function getShutterRate(shutter: ShutterEntity, type?: ShutterType): number {
	switch (type) {
		case 'top':
			return shutter.topRate;
		case 'site template':
			return shutter.siteTemplateRate;
		case 'bottom':
		case 'tapered':
			return shutter.bottomRate;
		default:
			return 0;
	}
}

function getShutterDisplayValue(type?: ShutterType): string {
	switch (type) {
		case 'top':
			return 'Top Shutter';
		case 'site template':
			return 'Site Template';
		case 'bottom':
			return 'Bottom Shutter'
		case 'tapered':
			return 'Tapered Bottom Shutter';
		default:
			return '';
	}
}