import * as React from 'react';
import { observer } from 'mobx-react';
import {action, computed, observable, runInAction} from 'mobx';
import Pagination from '../../Pagination/Pagination';
import * as uuid from 'uuid';
import classNames from 'classnames';
import { union, intersectionWith, isEqual, pullAllWith } from 'lodash';
import CollectionHeaders, {ICollectionHeaderProps, ICollectionHeaderPropsPrivate} from "../../Collection/CollectionHeaders";
import CollectionMenu from "../../Collection/CollectionMenu";
import ProductCollectionRow from "./ProductCollectionRow";
import { ICollectionProps } from "../../Collection/Collection";
import {ProductEntity} from "../../../../Models/Entities";

export interface IProductCollectionProps<T> extends ICollectionProps<T> {
	/** Callback for toggling edit mode */
    onEditModeUpdated: (editMode: boolean) => boolean;
    fetchWeight?: () => void;
}

/**
 * Displays a collection of products (elements and shutters)
 */
@observer
export default class ProductCollection extends React.Component<IProductCollectionProps<ProductEntity>> {
    @observable
    private expandAll = false; 
    /**
     * The currently selected items on the page
     */
    @observable
    private _selectedItems: ProductEntity[] = [];
    @computed
    private get selectedItems() {
        if (this.props.getSelectedItems) {
            return this.props.getSelectedItems();
        }
        return this._selectedItems;
    }

    /**
     * The headers of the collection
     */
    @computed
    private get headers(): Array<ICollectionHeaderPropsPrivate<ProductEntity>> {
        return this.props.headers.map(header => {
            const computedHeader: ICollectionHeaderPropsPrivate<ProductEntity> = {...header};

            if (typeof header.displayName === 'string') {
                computedHeader.headerName = header.displayName;
            } else if (typeof header.displayName === 'function') {
                computedHeader.headerName = header.displayName(header.name);
            }

            return computedHeader;
        });
    }

    /**
     * Should the all items checkbox be selected
     */
    @computed
    private get allChecked() {
        return ((intersectionWith(this.selectedItems, this.props.collection, isEqual).length === this.props.collection.length) && (this.props.collection.length > 0));
    }

    /**
     * The total number of items that exist over all pages
     */
    @computed
    private get totalItems() {
        return this.props.totalRecords
            ? this.props.totalRecords
            : this.props.collection.length;
    }

    /**
     * Gets the number of items that have been checked
     */
    @computed
    private get selectedItemsCount() {
        if (this.props.menuCountFunction) {
            return this.props.menuCountFunction(this.selectedItems);
        }
        return this.selectedItems.length;
    }

    /**
     * Should the select all items on all pages button be shown
     */
    @computed
    private get showSelectAll() {
        return this.allChecked && this.totalItems > this.selectedItemsCount;
    };

    public render() {
        const bulkActionClass =  this.selectedItemsCount !== 0 ? 'bulk-actions-enabled' : null;

        return (
            <section
                className={classNames('collection-component', this.props.className)}
                data-selected-count={this.selectedItemsCount}
                data-total={this.totalItems}
                {...this.props.innerProps}>
                <CollectionMenu
                    selectedItems={this.selectedItems}
                    search={!!this.props.onSearchTriggered}
                    filterConfig={this.props.menuFilterConfig}
                    onSearchTriggered={this.props.onSearchTriggered}
                    additionalActions={this.props.additionalActions}
                    cancelAllSelection={() => {
                        this.cancelAllSelection();
                    }}
					totalRecords={this.props.totalRecords}
                    showSelectAll={this.showSelectAll}
                    onSelectAll={this.checkAllPages}
                    totalSelectedItems={this.selectedItemsCount}
                    selectedBulkActions={this.props.selectedBulkActions}
                    filterOrientationRow={this.props.filterOrientationRow}
                />
                <div className={classNames('elements-list', bulkActionClass)}>
                    {this.list()}
                    {this.props.footer ?? null}
                </div>
                
                <this.pagination />
            </section>
        );
    }


    /**
     * The collection pagination component
     */
    public pagination = () => {
        const { perPage, pageNo, showGoToPageBox, hidePagination } = this.props;

        if (hidePagination || perPage === undefined || pageNo === undefined) {
            return null;
        }

        return (
            <section className="collection__load">
                <Pagination
					pageNo={pageNo!}
					perPage={perPage!}
					totalRecords={this.totalItems}
					onPageChange={this.onPageChange}
					showGoToPageBox={showGoToPageBox ?? false}
				/>
            </section>
        );
    }

    /**
     * The table list component
     */
    private list = () => {
        const collectionId = uuid.v4();
        const className = classNames('collection__list', `${this.props.expandList ? 'collection__list--expandable' : null}`);
        const filter = this.props.filter || (() => true);
        return (
            <section aria-label="collection list" className={className}>
                <table>
                    {this.header()}
                    <tbody>
                    {this.row({id: collectionId}) }
                    </tbody>
                </table>
            </section>
        );
    }

    /**
     * The table row component
     * @param props Contains the id of the row
     */
    private row = (props: {id: string}) => {
        const filter = this.props.filter || (() => true);
        return (
            <>
                {this.props.collection.filter(filter).map((item, idx) => {
                    let isNewProduct = false;
                    if (!!item.element && item.elementId == null || !!item.shutter && item.shutterId == null) {
                        isNewProduct = true;
                    }
                    
                    // Render product collection row item and expandDom (dom to display under the row)
                    // For elements, row contains PartsListExpandedCollection which is a table of parts on each element
                    // For shutters, row contains ShutterExpandedCollection which is a table of shutter quantity details
                    return (
                        <ProductCollectionRow
                            selectableItems={this.props.selectableItems}
                            item={item}
                            headers={this.headers}
                            actions={this.props.actions}
                            actionsMore={this.props.actionsMore}
                            checked={this.selectedItems.some(i => isEqual(i, item))}
                            onChecked={this.onRowChecked}
                            showExpandButton={this.props.showExpandButton}
                            key={this.props.idColumn ? item[this.props.idColumn] : `${idx}-${props.id}`}
                            keyValue={this.props.idColumn ? item[this.props.idColumn] : `${idx}-${props.id}`}
                            idColumn={this.props.idColumn}
                            dataFields={this.props.dataFields}
                            expanded={this.expandAll || isNewProduct}
                            editMode={isNewProduct}
                            refetch={this.props.refetch}
                            isReadOnly={this.props.isReadOnly}
                            onEditModeUpdated={this.props.onEditModeUpdated}
                            fetchWeight={this.props.fetchWeight}
                        />
                    );
                })}
            </>
        );
    }

    /**
     * The header row component
     */
    private header = () => {
        return (
            <CollectionHeaders<ProductEntity>
                headers={this.headers}
                selectableItems={this.props.selectableItems}
                actions={this.props.actions}
                allChecked={this.allChecked}
                onCheckedAll={this.onCheckedAll}
                orderBy={this.props.orderBy} 
                actionsMore={this.props.actionsMore}
                onToggleExpandAll={this.onToggleExpandAll}
                displayExpandAll={true}
            />
        );
    }
    
    @action
    private onToggleExpandAll = () => {
        this.expandAll = !this.expandAll;
    }

    /**
     * Selects or deselects items from the collection
     * @param checked Weather to select or deselect the items
     * @param items The items to change
     */
    @action
    private selectItems(checked: boolean, items: ProductEntity[]) {
        if (this.props.itemSelectionChanged) {
            this.props.itemSelectionChanged(checked, items);
        } else {
            if (checked) {
                this._selectedItems = union(this._selectedItems, items);
            } else {
                pullAllWith(this._selectedItems, items, isEqual)
            }
        }

    }

    /**
     * The callback for when a row is checked
     * @param event The checkbox change event
     * @param checked Weather the checkbox was checked or unchecked
     * @param checkedItem The item that was checked
     */
    @action
    private onRowChecked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, checkedItem: ProductEntity) => {
        this.selectItems(checked, [checkedItem]);
    }

    /**
     * Callback when the checkbox in the collection header was changed
     * @param event The checkbox change event
     * @param checked Weather the checkbox was checked or unchecked
     */
    private onCheckedAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.selectItems(checked, this.props.collection);
    }

    /**
     * Callback for when the select all pages button was pressed
     */
    private checkAllPages = () => {
        if (this.props.onCheckedAllPages) {
            this.selectItems(true, this.props.onCheckedAllPages(true));
        }
    }

    /**
     * Callback when the cancel selection button was pressed
     */
    private cancelAllSelection() {
        this.selectItems(false, this.props.collection);
        if(this.props.cancelAllSelection) {
            this.props.cancelAllSelection();
        }
    }

    /**
	 * The current page number. The first page is page 0.
	 */
	@observable
	private _pageNo: number = 0;
	@computed
	get pageNo() {
		return this.props.pageNo ?? this._pageNo;
	}
	set pageNo(value) {
		// Providing a page number makes this a managed component, so call the appropriate function on the props
		// Otherwise fallback to unmanaged functionality
		if (this.props.pageNo !== undefined) {
			this.props.onPageChange?.(value);
		} else {
			this._pageNo = value;
		}
	}

	/**
	 * The number of items per page
	 */
	get perPage() {
		return this.props.perPage ?? 10;
	}

    /**
	 * Callback for page changes.
	 * @param pageNo The page number to change to.
	 */
	onPageChange = (pageNo: number) => {
		this.pageNo = pageNo;
	}
}