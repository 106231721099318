import {observer} from "mobx-react";
import React from "react";
import {IOrderEntityAttributes, OrderEntity,} from "../../../../Models/Entities";
import {action, observable, runInAction} from "mobx";
import Spinner from "../../Spinner/Spinner";
import {ICollectionHeaderProps} from "../../Collection/CollectionHeaders";
import moment from "moment";
import Collection, {ICollectionItemActionProps} from "../../Collection/Collection";
import formatPrice from '../../../../Util/PriceUtils';
import {PaginationQueryOptions} from "../../../../Models/PaginationData";
import ModelAPIQuery, {ApiQueryParams, PaginatedData, QueryResult} from "../../ModelCollection/ProductAPIQuery";
import * as Enums from "../../../../Models/Enums";
import {store} from "../../../../Models/Store";
import {Button, Colors} from "../../Button/Button";
import { Alignment, ButtonGroup } from "Views/Components/Button/ButtonGroup";
import classNames from "classnames";
import alert from '../../../../Util/ToastifyUtils';

type orderArchivedState = 'Active' | 'Archived';

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);

export interface IOrderCollectionProps {
    customFilter?: ApiQueryParams;
    displayClient?: boolean; 
    displayProject?: boolean;
    isOrdersPage?: boolean; 
}

interface ISearch {
    searchTerm: string;
}

@observer 
export class OrderCollection extends React.Component<IOrderCollectionProps> {
    @observable
    public search: ISearch = { searchTerm: '' };

    @observable
    public paginationQueryOptions: PaginationQueryOptions = new PaginationQueryOptions();

    @observable
    public allSelectedItemIds: string[] = new Array<string>();

    @observable
    public allExcludedItemIds: string[] = new Array<string>();

    @observable
    public allPagesSelected: boolean = false;
    
    @observable
    private orders: OrderEntity[] = [];
    
    private totalRecords: number; 

    @observable
    private orderFilter: orderArchivedState = 'Active';

    private refetch: () => void;

    componentDidUpdate() {
        runInAction(() => {
            this.paginationQueryOptions.page = 0;
        });
    }

    public render() {
        runInAction(() => this.paginationQueryOptions.perPage = 50);

        return (
            <ModelAPIQuery
                model={OrderEntity}
                url={`/api/entity/OrderEntity/order_list`}
                searchStr={this.search.searchTerm}
                pagination={this.paginationQueryOptions}
                moreParams={this.props.customFilter}
                processData={this.setOrders}
            >
                {(result) => {
                    return this.renderCollection(result);
                }}
            </ModelAPIQuery>

        );
    };

    protected renderCollection = (result: QueryResult) => {
        const { loading, error, refetch } = result;

        this.refetch = refetch;

        if (error) {
            return (
                <div>
                    <h2>An unexpected error occurred:</h2>
                    {JSON.stringify(error)}
                </div>
            );
        }

        let menuCountFunction = () => {
            if(this.allPagesSelected){
                return this.totalRecords - this.allExcludedItemIds.length;
            }else{
                return this.allSelectedItemIds.length;
            }
        };

        // Table headers 
        const getHeaders = () => {
            let headers: Array<ICollectionHeaderProps<OrderEntity>> = [
                {
                    name: 'orderNumber',
                    displayName: 'PO No.',
                    transformItem: model => model.orderNumber,
                },
                {
                    name: 'orderDate',
                    displayName: 'Date Ordered',
                    transformItem: model => moment(model.orderDate).format('DD/MM/YYYY'),
                },
                {
                    name: 'price',
                    displayName: 'Cost',
                    transformItem: model => model.totalPrice ? formatPrice(model.totalPrice, model.project.country) : '-',
                },
                {
                    name: 'status',
                    displayName: 'Order Status',
                    transformItem: model => Enums.orderStatusOptions[model.status],
                },
            ];

            if (this.props.displayProject) {
                headers.unshift({
                    name: 'name',
                    displayName: 'Project',
                    transformItem: model => model.project.name,
                })
            }
            
            if (this.props.displayClient) {
                headers.unshift(
                    {
                        name: 'name',
                        displayName: 'Client',
                        transformItem: model => model.project.organisation.name,
                    },
                    {
                        name: 'aptusJobNumber',
                        displayName: 'Aptus Job No.',
                        transformItem: model => model.project.aptusJobNumber,
                    },
                )
            }
            
            return headers; 
        }
        
        const tableActions: Array<ICollectionItemActionProps<OrderEntity>> = [
            {
                action: (entity: OrderEntity) => {
                    store.routerHistory.push(`/order/${entity.id}`);
                },
                label: "View",
                colors: Colors.None,
            },
            {
                action: (entity: OrderEntity) => {
                    this.toggleOrderArchived(entity);
                },
                label: this.orderFilter == 'Active' ? 'Archive' : 'Activate',
                colors: Colors.None,
                buttonClass: 'archive-button',
            }
        ]

        var filteredOrders = this.filterOrderList();
        
        return (
            <>
                {loading && <Spinner/>}
                <Collection 
                    collection={filteredOrders} 
                    headers={getHeaders()}
                    pageNo={this.paginationQueryOptions.page}
					perPage={this.paginationQueryOptions.perPage}
                    onPageChange={this.paginationQueryOptions.gotoPage}
					totalRecords={this.totalRecords}
                    menuCountFunction={menuCountFunction}
                    actions={tableActions}
                    listClassName={'orders-list'}
                    onSearchTriggered={this.onSearchTriggered}
                    additionalActions={[this.renderArchiveButtons()]}
                />
            </>
        );
    };

    // [APSD-157] Added order archiving
    private filterOrderList = () => {
		let filteredList = this.orders
			.filter(order => {
				return (this.orderFilter === 'Archived' ? order.isarchived : !order.isarchived);
			})
			.sort((one, two) => {
                return this.orderOrders(one, two);
			}); 

		return filteredList;
	}
	
    private orderOrders (num1: OrderEntity, num2: OrderEntity){
        if(num1.modified != null && num2.modified != null)
            return num2.modified.valueOf()- num1.modified.valueOf();
        else
            return 0;
    }

    // Additional actions
    @action
    private setOrders = (result: PaginatedData<OrderEntity>) => {
        this.orders = result.data.map((e: IOrderEntityAttributes) => new OrderEntity(e));
        this.totalRecords = result.totalCount;
    }

    @action
    private onSearchTriggered = (searchTerm: string) => {
        this.search.searchTerm = searchTerm;
    }

    // [APSD-157] Added order archiving
    protected renderArchiveButtons(): React.ReactNode {
        return (
            <ButtonGroup key="ArchiveButtonGroup" alignment={Alignment.HORIZONTAL}>
                <Button onClick={() => this.applyFilter('Active')} className={classNames("filter-btn archive-btn", {"selected": this.orderFilter === 'Active'})} colors={Colors.Secondary}>Active</Button>
		        <Button onClick={() => this.applyFilter('Archived')} className={classNames("filter-btn archive-btn", {"selected": this.orderFilter === 'Archived'})} colors={Colors.Secondary}>Archived</Button>
            </ButtonGroup>
        );
	}

    @action
    private applyFilter = (orderState: orderArchivedState) => {
        this.orderFilter = orderState;
    }

    @action
	private toggleOrderArchived = (order: OrderEntity) => {
		order.isarchived = !order.isarchived;
		const newState = order.isarchived ? 'archived' : 'activated'; 
		order.save().then(() => {
			alert(`${order.orderNumber} has been ${newState}`, 'success');
		}).catch(error => {
			alert(`${order.orderNumber} cannot be ${newState}`, 'error');
		});
	}
}