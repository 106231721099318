import { observer } from "mobx-react";
import React from "react";
import ProductAPIQuery, { ApiQueryParams, IOrderByCondition, PaginatedData, QueryResult } from "../../ModelCollection/ProductAPIQuery";
import {
    ElementEntity,
    IProductEntityAttributes,
    ProductEntity
} from "../../../../Models/Entities";
import { action, observable, runInAction } from "mobx";
import { PaginationQueryOptions } from "../../../../Models/PaginationData";
import Spinner from "../../Spinner/Spinner";
import { ICollectionHeaderProps, transformFnEdit } from "../../Collection/CollectionHeaders";
import moment from "moment";
import { Button, Colors } from "../../Button/Button";
import { IEntityContextMenuItemActionProps } from "../../EntityContextMenu/EntityContextMenu";
import ProductCollection from "./ProductCollection";
import { Alignment, ButtonGroup } from "../../Button/ButtonGroup";
import { Model } from "../../../../Models/Model";
import _ from "lodash";
import { confirmModal } from "../../Modal/CustomModalUtils";
import alert from '../../../../Util/ToastifyUtils';
import { ICollectionBulkActionProps } from "../../Collection/Collection"
import ElementPartEntity from "../../../../Models/Entities/ElementPartEntity";
import { state } from '../../../../Models/Enums';
import axios from 'axios';
import createDuplicateElementsModal from '../DuplicateElements';
import { IProjectContext, ProjectContext } from '../ProjectContext';
import formatPrice from '../../../../Util/PriceUtils';
import { UploadPartsResponse } from '../ImportParts';
import ImportErrorReport from '../../ImportErrorReport/ImportErrorReport';
import {store} from "../../../../Models/Store";
import SmartlookService from '../../../../Services/SmartlookService';
import * as Enums from "../../../../Models/Enums";

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);

export interface IElementCollectionProps {
    projectId: string; 
    customFilter: ApiQueryParams;
    showPanel?: () => void;
    isCompact?: boolean; 
    isReadOnly?: boolean;
    onProductStateUpdated?: () => void;
    totalPrice?: number;
    importErrors?: UploadPartsResponse;
    country: Enums.countries;
    fetchWeight?: () => void;
}

interface ISearch {
    searchTerm: string;
}

/**
 * Fetch and display list of products with elements
 */
@observer
export class ElementCollection extends React.Component<IElementCollectionProps> {

    static contextType = ProjectContext;
    context!: IProjectContext;

    @observable
    public search: ISearch = { searchTerm: '' };

    @observable
    public paginationQueryOptions: PaginationQueryOptions = new PaginationQueryOptions();
    
    @observable
    public allSelectedItemIds: string[] = new Array<string>();

    @observable
    public allExcludedItemIds: string[] = new Array<string>();

    @observable
    public allPagesSelected: boolean = false;
    
    @observable
    private products: ProductEntity[] = [];

    @observable
    private totalRecords: number; 

    @observable
    private sortByIcon: string;

    @action
    private setSortByIcon() {
        switch (store.sortDirectionElement) {
            case "NONE":
                this.sortByIcon = "minus";
                break;
            case "ASCENDING":
                this.sortByIcon = "chevron-up";
                break;
            case "DESCENDING":
                this.sortByIcon = "chevron-down";
                break;
        }
    }

    private sortParams : IOrderByCondition<any> = {
        path: "ElementId",
        descending: this.sortByDescending(),
    };

    @observable
    private editMode: boolean = false;

    private refetch: () => void;

    componentDidUpdate() {
        runInAction(() => {
            this.paginationQueryOptions.page = 0;
            this.editMode = false;
        });
    }

    public render() {
        this.setSortByIcon();
        runInAction(() => this.paginationQueryOptions.perPage = 50);

        return (
            <ProductAPIQuery 
                model={ProductEntity}
                url={`/api/entity/ProductEntity/product_list/${this.props.projectId}`}
                searchStr={this.search.searchTerm}
                pagination={this.paginationQueryOptions}
                moreParams={this.props.customFilter}
                processData={this.setProducts}
                orderBy={this.sortParams}
            >
                {(result) => {
                    return this.renderCollection(result); 
                }}
            </ProductAPIQuery>
            
        ); 
    };
    
    protected renderCollection = (result: QueryResult) => {
        const { loading, error, refetch } = result;
        
        this.refetch = refetch; 

        if (error) {
            return (
                <div>
                    <h2>An unexpected error occurred:</h2>
                    {JSON.stringify(error)}
                </div>
            );
        }

        store.elementShutterEditMode = false;

        let menuCountFunction = () => {
            if (this.allPagesSelected) {
                return this.totalRecords - this.allExcludedItemIds.length;
            } else {
                return this.allSelectedItemIds.length;
            }
        };

        // Table headers
        const getHeaders = () => {
            let headers: Array<ICollectionHeaderProps<ProductEntity>> = [
                {
                    name: 'elementId',
                    displayName: 'Element ID',
                    sortable: false,
                    editable: true,
                    transformItem: model => model.element.elementId,
                    columnSize: 'big',
                },
                {
                    name: 'orderStatus',
                    displayName: 'Order Status',
                    sortable: false,
                    transformItemWithEdit: this.transformOrderStatus,
                },
                {
                    name: 'date',
                    displayName: 'Date',
                    sortable: false,
                    transformItem: model => moment(model.modified).format('DD/MM/YYYY'),
                },
                {
                    name: 'price',
                    displayName: 'Cost',
                    sortable: false,
                    transformItem: this.transformElementPrice,
                }
            ];
            
            // Remove order status for compact list
            if (this.props.isCompact) {
                headers.splice(1,1);
            }
            
            return headers;
        }

        // Context menu actions 
        const getTableActionsMore = () => {
            let tableActionsMore: Array<IEntityContextMenuItemActionProps<ProductEntity>> = [
                {
                    onEntityClick: (args, entity) => {
                        SmartlookService.triggerEvent('Element Duplicated');

                        createDuplicateElementsModal(entity, refetch, this.context);
                    },
                    label: 'Duplicate'
                },
                {
                    onEntityClick: (args, entity) => {
                        if (entity.state == 'ORDER') {
                            alert('Can not delete already ordered element', 'error');
                            return;
                        }

                        confirmModal('Delete Element?',
                            `Are you sure you want to delete the element with ID ${entity.element.elementId}?`)
                            .then(async () => {
                                try {
                                    await entity.element.delete();
                                    
                                    if (this.context.setProjectContext) {
                                        await this.context.setProjectContext(true, true);
                                    }

                                } catch (e) {
                                    alert('Error occured while deleting element', 'error')
                                }
                            }).catch(() => {/* This is when the user responds no */
                        });
                    },
                    label: 'Delete'
                },
            ];

            // Remove 'delete' action and add 'remove from order'
            if (this.props.isCompact) {
                tableActionsMore.splice(1,1);
                tableActionsMore.push(
                    {
                        onEntityClick: async (args, entity) => {
                            await this.changeProductStates([entity], 'WISHLIST');
                        },
                        label: 'Remove from order'
                    },
                )
            }
            
            return tableActionsMore;
        }

        // Bulk actions
        const selectedBulkActions = () => {
            let bulkActions: Array<ICollectionBulkActionProps<ProductEntity>> = [
                {
                    bulkAction: async items => {
                        SmartlookService.triggerEvent('Add to Order');
                        await this.changeProductStates(items, 'CART');
                        this.cancelAllSelection();
                    },
                    label: 'Add to order',
                    colors: Colors.Tertiary,
                    buttonClass: 'btn--bulk-order',
                },
                {
                    bulkAction: async items => {
                        await this.changeProductStates(items, 'WISHLIST');

                        // If the list is compact, refetch the list and cancel the selection
                        // (since we need the new costs)
                        if (this.props.isCompact) {
                            this.refetch();
                        }
                        this.cancelAllSelection();
                    },
                    label: 'Remove from order',
                    colors: Colors.Primary,
                    buttonClass: 'btn--bulk-order',
                },
                {
                    bulkAction: () => {
                        confirmModal('Are you sure?',
                            `Are you sure you want to delete these elements? You cannot delete ordered elements.`)
                            .then(async () => {
                                try {
                                    let elementIds = this.allSelectedItemIds.reduce((result: string[], productId) => {
                                        const product = this.products.find(x => x.id === productId);
                                        const elementId = product?.elementId;
                                        const notInOrder = !!product && product.state !== 'ORDER';

                                        // Only remove an element if it has not already been ordered
                                        if (!!elementId && notInOrder) {
                                            result.push(elementId);
                                        }

                                        return result;
                                    }, []);
                                    await (new ElementEntity()).deleteWhere(undefined, elementIds);

                                    // Check for any items that were deleted from the cart
                                    // Element status count should be updated
                                    if (this.context.setProjectContext) {
                                        await this.context.setProjectContext(true, true);
                                    }
                                    
                                    this.cancelAllSelection();
                                    alert('All selected items are deleted successfully', 'success');
                                } catch (e) {
                                    alert('Error occured while deleting element', 'error')
                                }
                            }).catch(() => {/* This is when the user responds no */
                        });
                    },
                    label: 'Delete',
                    buttonClass: "btn--bulk-select",
                    colors: Colors.None,
                },
            ];

            // Remove Add to order and delete action
            if (this.props.isCompact) {
                bulkActions.splice(0,1);
                bulkActions.splice(1,1);
            }
            
            return bulkActions;
        }

        const additionalActionsCompact: React.ReactNode[] = [
            <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group" key='additionalActions'>
                <Button
                    onClick={() => this.onSort(this.refetch)}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: this.sortByIcon, iconPos: 'icon-left' }}>
                    Sort By
                </Button>
            </ButtonGroup>
        ];

        const additionalActions: React.ReactNode[] = [
            this.displayImportErrorMessage(),
            <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group" key='additionalActions'>
                <Button
                    onClick={this.addNewElement}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: "plus", iconPos: 'icon-left' }}>
                    Add Element
                </Button>
                <Button
                    onClick={() => this.onSort(this.refetch)}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: this.sortByIcon, iconPos: 'icon-left' }}>
                    Sort By
                </Button>
                <Button
                    onClick={this.showPanel}
                    className="action-btn"
                    colors={Colors.Secondary}
                    icon={{ icon: "files", iconPos: 'icon-left' }}>
                    Bulk Import
                </Button>
            </ButtonGroup>
        ];
        
        // Pass elements list specific attributes and actions as props to ProductCollection
        // ProductCollection is a custom collection for elements and shutters 
        return (
            <>
                {loading && <Spinner/>}
                <ProductCollection
                    collection={this.products}
                    headers={getHeaders()}
                    actionsMore={this.props.isReadOnly ? undefined : getTableActionsMore()}
                    selectedBulkActions={selectedBulkActions()}
                    additionalActions={this.props.isCompact ? additionalActionsCompact : additionalActions}
                    onSearchTriggered={this.props.isCompact ? undefined : this.onSearchTriggered}
					pageNo={this.paginationQueryOptions.page}
					perPage={this.paginationQueryOptions.perPage}
                    onPageChange={this.paginationQueryOptions.gotoPage}
					totalRecords={this.totalRecords}
                    hidePagination={false}
                    menuCountFunction={menuCountFunction}
                    selectableItems={!this.props.isReadOnly}
                    itemSelectionChanged={this.itemSelectionChanged}
                    cancelAllSelection={this.cancelAllSelection}
                    getSelectedItems={this.getSelectedItems}
                    onCheckedAllPages={this.onCheckedAllPages}
                    showExpandButton={() => true}
                    refetch={() => this.updateTable()}
                    footer={this.props.totalPrice ? <h5> Elements total {formatPrice(this.props.totalPrice, this.props.country)}</h5> : null}
                    isReadOnly={this.props.isReadOnly}
                    onEditModeUpdated={this.onEditModeUpdated}
                    fetchWeight={this.props.fetchWeight}
                />
            </>
        );
    };

    // Transform functions 
    private transformOrderStatus: transformFnEdit<ProductEntity> =
        (product: ProductEntity, editMode: boolean) => {
        let buttonText;
        let buttonColour;
        let ordered = false;

        switch (product.state) {
            case 'WISHLIST':
                buttonText = 'Add to order';
                buttonColour = Colors.Tertiary;
                break;
            case 'CART':
                buttonText = 'Remove from order';
                buttonColour = Colors.Primary;
                break;
            case 'ORDER':
                ordered = true;
                break;
            default:
                buttonText = '';
        }

        const changeElementState = async () => {

            // Cannot change element state if active edit window open
            if (store.showToastInEditMode('element')) return;

            let newState: state;
            if (product.state === 'WISHLIST') {
                newState = 'CART';
            } else if (product.state === 'CART') {
                newState = 'WISHLIST';
            } else {
                return;
            }

            try {
                await this.changeProductStates([product], newState);

                if (newState === 'CART') {
                    SmartlookService.triggerEvent('Single Element Add to Order');
                }
            } catch (err) {
                console.error(err);
                alert('Could not update element order status', 'error');
            }
           
        };

        if (ordered) {
            return (
                <div className='element-ordered'>
                    <b>{product.order.getOrderStatusDisplayText()}</b>
                    <i>PO# {product.order ? product.order.orderNumber : ''}</i>
                </div>
            );
        }

        return (
            <Button
                className='fixed-width'
                colors={buttonColour}
                onClick={() => runInAction(changeElementState)}
                disabled={editMode}
            >
                {buttonText}
            </Button>
        );
    }

    private transformElementPrice: transformFn<ProductEntity> = (product: ProductEntity) => {
        if (!!product.element.id) {
            let price = 0;
            product.element.partOrders.forEach(part => price += part.price ?? 0);
            price = Math.ceil(price * 100) / 100;
            return formatPrice(price, this.props.country);
        }

        return '-';
    }

    // Additional actions
    @action 
    private setProducts = (result: PaginatedData<ProductEntity>) => {
        // if (store.sortDirectionElement !== "NONE") {
        //     store.sortProducts("ELEMENT", result.data);
        // }

        this.products = result.data.map((e: IProductEntityAttributes) => new ProductEntity(e));
        this.totalRecords = result.totalCount;
    }
    
    @action
    private showPanel = () => {
        SmartlookService.triggerEvent('Elements Bulk Import');

        if (this.props.showPanel) {
            this.props.showPanel();
        }
    }
    
    @action
    private onSearchTriggered = (searchTerm: string) => {
        this.search.searchTerm = searchTerm;
    }

    @action
    private onEditModeUpdated = (editMode: boolean) => {
        if (this.editMode && editMode) {
            alert('You have unsaved changes in another element, please save the changes before continuing', 'error');
            return false;
        }
        this.editMode = editMode;
        return true;
    }

    @action
    private addNewElement = () => {
        // Cannot add new Element row if new element hasn't been saved
        if (store.showToastInEditMode('element')) return;

        store.elementShutterEditMode = true;

        let newProduct = this.products.some((product) => (product.elementId ?? product.shutterId) == null);
        if (newProduct || this.editMode)
        {
            alert('You have unsaved changes in another element, please save the changes before continuing', 'error');
            return;
        }

        SmartlookService.triggerEvent('Add Element');

        let newElement = new ElementEntity({
            partOrders: [new ElementPartEntity()],
        });
        
        this.products.unshift(new ProductEntity({
            element: newElement,
            projectId: this.props.projectId,
            state: 'WISHLIST'
        }));
    }
    
    // Select items
    @action
    public itemSelectionChanged = (checked: boolean, changedItems: Model[]) => {
        let changedIds = changedItems.map(item => item.id);
        if (this.allPagesSelected) {
            if (!checked) {
                this.allExcludedItemIds = _.union(this.allExcludedItemIds, changedIds);
            } else {
                this.allExcludedItemIds = _.pull(this.allExcludedItemIds, ...changedIds);
            }
        } else {
            if (checked) {
                this.allSelectedItemIds = _.union(this.allSelectedItemIds, changedIds);
            } else {
                this.allSelectedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
            }
        }
        return this.products.filter((m: Model) => {
            if (this.allPagesSelected) {
                return !this.allExcludedItemIds.some(id => {
                    return m.id === id;
                }) ;
            } else {
                return this.allSelectedItemIds.some(id => {
                    return m.id === id;
                }) ;
            }
        });
    }

    @action
    private cancelAllSelection = () => {
        this.allPagesSelected = false;
        this.allSelectedItemIds = [];
        this.allExcludedItemIds = [];
    }
    
    private getSelectedItems = () => {
        return this.products.filter(model => {
            if (this.allPagesSelected) {
                return !this.allExcludedItemIds.some(id => {
                    return model.id === id;
                });
            } else {
                return this.allSelectedItemIds.some(id => {
                    return model.id === id;
                });
            }
        });
    };

    @action
    private onCheckedAllPages = (checked: boolean) => {
        this.allPagesSelected = checked;
        if(checked){
            this.allExcludedItemIds = [];
            let changedIds = this.products.map(item => item.id);
            if (checked) {
                this.allSelectedItemIds = _.union(this.allSelectedItemIds, changedIds);
            } else {
                this.allSelectedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
            }
            let selectedItems = (new Array<ProductEntity>());
            selectedItems.push(...this.products);
            return selectedItems;
        }else{
            this.allSelectedItemIds = [];
            let changedIds = this.products.map(item => item.id);
            if (!checked) {
                this.allExcludedItemIds = _.union(this.allSelectedItemIds, changedIds);
            } else {
                this.allExcludedItemIds = _.pull(this.allSelectedItemIds, ...changedIds);
            }
            return [];
        }
    }

    @action
    private async changeProductStates(items: ProductEntity[], newState: state) {
        const filteredItems = store.hasBackendAccess && this.props.isCompact? items : items.filter(item => item.state !== 'ORDER');
        await axios.put(`/api/entity/ProductEntity/updateState`, {
            Ids: filteredItems.map(item => item.id),
            State: newState,
        });

        runInAction(() => {
            filteredItems.forEach(item => {
                item.state = newState;
            });
        });

        // Once the state of the products have been updated, update the cart status
        if (this.context.setProjectContext) {
            await this.context.setProjectContext(true);
        }
        
        if (this.props.isCompact && this.props.onProductStateUpdated) {
            this.props.onProductStateUpdated();
        }

        if(this.props.fetchWeight){
            await this.props.fetchWeight();
        }
    }


    private async updateTable() {

        if (!this.props.isCompact && this.context.setProjectContext) {
            await this.context.setProjectContext(true);
        }

        if (this.props.isCompact && this.props.onProductStateUpdated) {
            this.props.onProductStateUpdated();
        }
    }

    private displayImportErrorMessage() {
        if (!this.props.importErrors) {
            return undefined;
        }

        const importErrorLength = this.props.importErrors?.partErrors.length ?? 0;
        if (importErrorLength == 0) {
            return undefined;
        }

        return <ImportErrorReport key='import-report'
                                  importReport={this.props.importErrors} />;
    }

    @action
    private onSort(refetch: () => void) {
        switch (store.sortDirectionElement) {
            case "NONE":
                store.setSortDirectionElement("ASCENDING");
                break;
            case "ASCENDING":
                store.setSortDirectionElement("DESCENDING");
                break;
            case "DESCENDING":
                store.setSortDirectionElement("NONE");
                break;
        }

        this.sortParams.descending = this.sortByDescending();
        this.setSortByIcon();
    }

    private sortByDescending() {
        switch (store.sortDirectionElement) {
            case "NONE": return undefined;
            case "ASCENDING": return false;
            case "DESCENDING": return true;
            default: return undefined;
        }
    }
}