/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsShutterEntity } from 'Models/Security/Acl/VisitorsShutterEntity';
import { AptusAdminShutterEntity } from 'Models/Security/Acl/AptusAdminShutterEntity';
import { UserShutterEntity } from 'Models/Security/Acl/UserShutterEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IShutterEntityAttributes extends IModelAttributes {
	shutterId: string;
	width: number;
	depth: number;
	diameter: number;
	sketchId: string;
	sketch: Blob;
	price: number;
	topQuantity: number;
	topRate: number;
	bottomQuantity: number;
	taperedBottomQuantity: number;
	bottomRate: number;
	siteTemplateQuantity: number;
	siteTemplateRate: number;

	product?: Models.ProductEntity | Models.IProductEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	sketchFileName: string; 
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ShutterEntity', 'Shutter')
// % protected region % [Customise your entity metadata here] end
export default class ShutterEntity extends Model implements IShutterEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsShutterEntity(),
		new AptusAdminShutterEntity(),
		new UserShutterEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Shutter Id'] off begin
	/**
	 * Shutter Id
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Shutter Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public shutterId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Shutter Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Width'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Width',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public width: number;
	// % protected region % [Modify props to the crud options here for attribute 'Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Depth'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Depth',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public depth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Depth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Diameter'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Diameter',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public diameter: number;
	// % protected region % [Modify props to the crud options here for attribute 'Diameter'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sketch'] off begin
	/**
	 * Shutter bespoke sketch
	 */
	@observable
	@attribute({file: 'sketch'})
	@CRUD({
		name: 'Sketch',
		displayType: 'file',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'sketch',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public sketchId: string;
	@observable
	public sketch: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Sketch'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	/**
	 * Final element price
	 */
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Top Quantity'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Top Quantity',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public topQuantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Top Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Top Rate'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Top Rate',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public topRate: number;
	// % protected region % [Modify props to the crud options here for attribute 'Top Rate'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bottom Quantity'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Bottom Quantity',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public bottomQuantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bottom Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tapered Bottom Quantity'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Tapered Bottom Quantity',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public taperedBottomQuantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Tapered Bottom Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bottom Rate'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Bottom Rate',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bottomRate: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bottom Rate'] end

	// % protected region % [Modify props to the crud options here for attribute 'Site Template Quantity'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Site Template Quantity',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public siteTemplateQuantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Site Template Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Site Template Rate'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Site Template Rate',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public siteTemplateRate: number;
	// % protected region % [Modify props to the crud options here for attribute 'Site Template Rate'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Product'] off begin
		name: 'Product',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.ProductEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'products',
			oppositeEntity: () => Models.ProductEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Product'] end
	})
	public product?: Models.ProductEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public sketchFileName: string;

	// This is the id of a sketch that will be deleted when saved
	@observable
	public deleteSketchId: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IShutterEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IShutterEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.shutterId !== undefined) {
				this.shutterId = attributes.shutterId;
			}
			if (attributes.width !== undefined) {
				this.width = attributes.width;
			}
			if (attributes.depth !== undefined) {
				this.depth = attributes.depth;
			}
			if (attributes.diameter !== undefined) {
				this.diameter = attributes.diameter;
			}
			if (attributes.sketch !== undefined) {
				this.sketch = attributes.sketch;
			}
			if (attributes.sketchId !== undefined) {
				this.sketchId = attributes.sketchId;
			}
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.topQuantity !== undefined) {
				this.topQuantity = attributes.topQuantity;
			}
			if (attributes.topRate !== undefined) {
				this.topRate = attributes.topRate;
			}
			if (attributes.bottomQuantity !== undefined) {
				this.bottomQuantity = attributes.bottomQuantity;
			}
			if (attributes.taperedBottomQuantity !== undefined) {
				this.taperedBottomQuantity = attributes.taperedBottomQuantity;
			}
			if (attributes.bottomRate !== undefined) {
				this.bottomRate = attributes.bottomRate;
			}
			if (attributes.siteTemplateQuantity !== undefined) {
				this.siteTemplateQuantity = attributes.siteTemplateQuantity;
			}
			if (attributes.siteTemplateRate !== undefined) {
				this.siteTemplateRate = attributes.siteTemplateRate;
			}
			if (attributes.product !== undefined) {
				if (attributes.product === null) {
					this.product = attributes.product;
				} else {
					if (attributes.product instanceof Models.ProductEntity) {
						this.product = attributes.product;
					} else {
						this.product = new Models.ProductEntity(attributes.product);
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.sketchFileName !== undefined) {
				this.sketchFileName = attributes.sketchFileName;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		product {
			${Models.ProductEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			product: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'product',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.shutterId;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public hasInvalidDimensions(): boolean {
		return (this.width < 0 ||
			this.depth < 0 ||
			this.diameter < 0 ||
			this.topQuantity < 0 ||
			this.bottomQuantity < 0 ||
			this.siteTemplateQuantity < 0 ||
			this.taperedBottomQuantity < 0);
	}

	public shouldFieldBeEditable(attribute: string): boolean {
		if ((this.depth || this.width) && attribute === 'diameter') {
			return false;
		} else if (this.diameter && (attribute === 'depth' || attribute === 'width')) {
			return false;
		}

		return true;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ShutterEntity.prototype, 'created');
CRUD(modifiedAttr)(ShutterEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
