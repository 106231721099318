import * as React from "react";
import {store} from "../../../Models/Store";
import {Button, Display} from "../Button/Button";
import gif_attach_sketch from "../../../Assets/images/attach-sketch.gif";

export function attachSketchModal() {
    return new Promise<void>((resolve, reject) => {
        const onConfirm = () => {
            store.modal.hide();
            resolve();
        };
        
        const title = 'Attach a shutter sketch';
        const body =
            <>
                <p>Your shutters will be custom made. To accurately assemble them, we need a copy of your shutter specs.</p>
                <p>Attach your shutter specifications or sketches to the project by uploading a PDF file format.</p>
            </>;
        const confirmText = 'Confirm';
        
        const confirmDom = (
            <>
                <div key="iamge" className="modal__image">
                    <img src={gif_attach_sketch}/>
                </div>
                <div key="header" className="modal__header">
                    <h3 key="header-item" className="modal__title">{title}</h3>
                </div>
                <div key="message" className="modal__message">
                    {body}
                </div>
                <div key="actions" className="modal__actions">
                    <Button className="modal--confirm" key={"confirm"} onClick={onConfirm} display={Display.Solid}>{confirmText}</Button>
                </div>
            </>
        );

        store.modal.show(title, confirmDom, {
            className: 'attach-sketch-modal',
            onRequestClose: () => {
                store.modal.hide();
                resolve();
            },
        });
    });
}
