import React from 'react';
import {HeaderBreadcrumbs, IHeaderBreadCrumbsTag} from "./HeaderBreadcrumbs";
import { OrderEntity, ProjectEntity } from '../../../Models/Entities';

interface IBreadcrumbsWrapProps {
    project: ProjectEntity;
    order?: OrderEntity;
    isCart?: boolean;
}
    
export default function BreadcrumbsWrap(props: IBreadcrumbsWrapProps) {
    
    const generateBreadcrumbs = () => {
        let isCurrentPage = true;
        let crumbs: IHeaderBreadCrumbsTag[] = [];

        if (props.isCart || !!props.order) {
            const label = props.order ? `Purchase order #${props.order.orderNumber}` : 'Review order';

            crumbs.unshift({
                label: label,
                className: isCurrentPage ? 'current' : 'not-current',
                isHomeTag: false,
                isCurrentTag: isCurrentPage,
            });

            isCurrentPage = false;
        }

        if (!!props.project){
            crumbs.unshift({
                label: `${props.project.name}`,
                link: `/project/${props.project.id}`,
                className: isCurrentPage ? 'current' : 'not-current',
                isHomeTag: false,
                isCurrentTag: isCurrentPage,
            });
            
            isCurrentPage = false;
        }

        crumbs.unshift({
            label: 'Projects',
            link: '/project',
            className: isCurrentPage ? 'current' : 'not-current',
            isHomeTag: true,
            isCurrentTag: isCurrentPage,
        });
        
        return crumbs;
    }

    return (
        <HeaderBreadcrumbs tags={generateBreadcrumbs()}/>
    );
}