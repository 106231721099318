import React from 'react';
import classNames from 'classnames';

interface SidePanelProps {
	display?: boolean;
	className?: string;
	hidePanel?: () => void;
}

export default class SidePanel extends React.Component<SidePanelProps> {

	render() {
		// Remove this object from the dom
		if (!this.props.display) {
			return <></>;
		}

		return (
			<div className='sidebar'>
				<div className='sidebar-click-interceptor' onClick={this.props.hidePanel}> </div>
				<div className={classNames('sidebar-info', this.props.className)}>
					{this.props.children}
				</div>
			</div>
		);
	}

}