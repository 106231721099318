/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsProductEntity } from 'Models/Security/Acl/VisitorsProductEntity';
import { AptusAdminProductEntity } from 'Models/Security/Acl/AptusAdminProductEntity';
import { UserProductEntity } from 'Models/Security/Acl/UserProductEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProductEntityAttributes extends IModelAttributes {
	state: Enums.state;

	orderId?: string;
	order?: Models.OrderEntity | Models.IOrderEntityAttributes;
	projectId?: string;
	project?: Models.ProjectEntity | Models.IProjectEntityAttributes;
	elementId?: string;
	element?: Models.ElementEntity | Models.IElementEntityAttributes;
	shutterId?: string;
	shutter?: Models.ShutterEntity | Models.IShutterEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProductEntity', 'Product')
// % protected region % [Customise your entity metadata here] end
export default class ProductEntity extends Model implements IProductEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsProductEntity(),
		new AptusAdminProductEntity(),
		new UserProductEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.stateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.stateOptions),
		displayFunction: (attribute: Enums.state) => Enums.stateOptions[attribute],
	})
	public state: Enums.state;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	/**
	 * Order has many products (elements or shutters)
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Order'] off begin
		name: 'Order',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.OrderEntity,
		// % protected region % [Modify props to the crud options here for reference 'Order'] end
	})
	public orderId?: string;
	@observable
	@attribute({isReference: true})
	public order: Models.OrderEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project'] off begin
		name: 'Project',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.ProjectEntity,
		// % protected region % [Modify props to the crud options here for reference 'Project'] end
	})
	public projectId?: string;
	@observable
	@attribute({isReference: true})
	public project: Models.ProjectEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Element'] off begin
		name: 'Element',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ElementEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'elements',
			oppositeEntity: () => Models.ElementEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Element'] end
	})
	public elementId?: string;
	@observable
	@attribute({isReference: true})
	public element: Models.ElementEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Shutter'] off begin
		name: 'Shutter',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.ShutterEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'shutters',
			oppositeEntity: () => Models.ShutterEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Shutter'] end
	})
	public shutterId?: string;
	@observable
	@attribute({isReference: true})
	public shutter: Models.ShutterEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProductEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProductEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.orderId !== undefined) {
				this.orderId = attributes.orderId;
			}
			if (attributes.order !== undefined) {
				if (attributes.order === null) {
					this.order = attributes.order;
				} else {
					if (attributes.order instanceof Models.OrderEntity) {
						this.order = attributes.order;
						this.orderId = attributes.order.id;
					} else {
						this.order = new Models.OrderEntity(attributes.order);
						this.orderId = this.order.id;
					}
				}
			}
			if (attributes.projectId !== undefined) {
				this.projectId = attributes.projectId;
			}
			if (attributes.project !== undefined) {
				if (attributes.project === null) {
					this.project = attributes.project;
				} else {
					if (attributes.project instanceof Models.ProjectEntity) {
						this.project = attributes.project;
						this.projectId = attributes.project.id;
					} else {
						this.project = new Models.ProjectEntity(attributes.project);
						this.projectId = this.project.id;
					}
				}
			}
			if (attributes.elementId !== undefined) {
				this.elementId = attributes.elementId;
			}
			if (attributes.element !== undefined) {
				if (attributes.element === null) {
					this.element = attributes.element;
				} else {
					if (attributes.element instanceof Models.ElementEntity) {
						this.element = attributes.element;
						this.elementId = attributes.element.id;
					} else {
						this.element = new Models.ElementEntity(attributes.element);
						this.elementId = this.element.id;
					}
				}
			}
			if (attributes.shutterId !== undefined) {
				this.shutterId = attributes.shutterId;
			}
			if (attributes.shutter !== undefined) {
				if (attributes.shutter === null) {
					this.shutter = attributes.shutter;
				} else {
					if (attributes.shutter instanceof Models.ShutterEntity) {
						this.shutter = attributes.shutter;
						this.shutterId = attributes.shutter.id;
					} else {
						this.shutter = new Models.ShutterEntity(attributes.shutter);
						this.shutterId = this.shutter.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.projectId) {
				this.projectId = attributes.projectId;
			}

			if (attributes.elementId) {
				this.elementId = attributes.elementId;
			}

			if (attributes.shutterId) {
				this.shutterId = attributes.shutterId;
			}

			if (attributes.orderId) {
				this.orderId = attributes.orderId;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		order {
			${Models.OrderEntity.getAttributes().join('\n')}
		}
		project {
			${Models.ProjectEntity.getAttributes().join('\n')}
		}
		element {
			${Models.ElementEntity.getAttributes().join('\n')}
		}
		shutter {
			${Models.ShutterEntity.getAttributes().join('\n')}
			${Models.ShutterEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'element',
							'shutter',
						]
					},
				],
			}
		);
	}

	public async saveShutterFromProduct() {
		const relationPath = {
			shutter: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'shutter',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProductEntity.prototype, 'created');
CRUD(modifiedAttr)(ProductEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
