import * as React from 'react';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import axios from 'axios';
import * as Enums from "../../../Models/Enums";
import cloneDeep from 'lodash/cloneDeep';

interface ProjectProviderProps {
	projectId: string;
}

export interface IProjectContext {
	productCount?: number;
	price?: number;
	projectCountry?: Enums.countries;
	refetch?: boolean;
	totalWeight? : number;
	barWeight?: number;
	componentWeight?: number;
	shutterWeight ?: number;

	setProjectContext?: (fetchCartStatus: boolean, setRefetch?: boolean) => Promise<void>;
}

export const ProjectContext = React.createContext({});

@observer
export default class ProjectProvider extends React.Component<ProjectProviderProps> {
	
	setProjectContext = async (fetchCartStatus: boolean, setRefetch?: boolean) => {
		let tempContext = cloneDeep(this.projectContext); 
		
		if (fetchCartStatus) {
			const { data } = await axios.get(`/api/entity/ProjectEntity/getCartStatus/${this.props.projectId}`);
			const { price, productCount, projectCountry, totalWeight, barWeight, componentWeight, shutterWeight } = data;
			
			tempContext = {
				...tempContext,
				productCount,
				price,
				projectCountry,
				totalWeight,
				barWeight, 
				componentWeight,
				shutterWeight,
			};
		}
		
		if (setRefetch != undefined) {
			tempContext.refetch = setRefetch;
		}

		runInAction(() => {
			this.projectContext = tempContext;
		});
	}

	@observable
	private projectContext: IProjectContext = {
		refetch: false,
		productCount: 0,
		price: 0,
		setProjectContext: this.setProjectContext,
	};

	async componentDidMount() { 
		await this.setProjectContext(true);
	}

	public render() {
		// This will tell components that rely on this component to rerender
		const newContext = { ...this.projectContext };
		return (
			<ProjectContext.Provider value={newContext}>
				{this.props.children}
			</ProjectContext.Provider>
		);
	}

}