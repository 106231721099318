import { ProjectEntity } from "../../../Models/Entities";
import React from "react";
import If from "../If/If";
import {observer} from "mobx-react";
import moment from "moment";
import {ContextMenu, IContextMenuItemProps} from "../ContextMenu/ContextMenu";
import {contextMenu} from "react-contexify";
import {action} from "mobx";
import {store} from "../../../Models/Store";

export interface IDashboardTileProps {
	project: ProjectEntity;
    onToggleProjectArchived?: (project: ProjectEntity) => void;
    onClick?: () => void;
}

@observer
export default class DashboardTile extends React.Component<IDashboardTileProps> {

    public render() {
        const { project } = this.props;
        
        let contextMenuItems: IContextMenuItemProps[] = 
            [
                {
                    label: project.archived ? 'Activate' : 'Archive',
                    buttonClass: 'edit-details',
                    onClick: this.onArchiveClicked(),
                }
            ];

        const initials = project.projectContact.firstName.charAt(0) + project.projectContact.lastName.charAt(0);
        const lastOrderDate = (project.orderss.length > 0) ? 
            moment(project.orderss[0].orderDate).format('DD/MM/YYYY') : '';
        
        return (
            <>
                <div className='project-list-item' onClick={this.onProjectClicked}>
                    <p className="client-job-number">{project.clientJobNumber}</p>
                    <p className="project-name">{project.name}</p>
                    <div className="project-item-details">
                        <p className="owner-initials"> {initials.toUpperCase()} </p>
                        <If condition={!!lastOrderDate}>
                            <p className="last-order">Last order {lastOrderDate}</p>
                        </If>
                        <span className="icon-more-horizontal icon-only" onClick={this.onActionsButtonClick}/>
                    </div>
                </div>

                <ContextMenu location={'frontend'} actions={contextMenuItems} menuId={'dashboard-tile-' + project.id}/>
            </>
        )
    }

    private onActionsButtonClick: React.MouseEventHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        contextMenu.show({
            id: 'dashboard-tile-' + this.props.project.id,
            event: event,
        });
    }
    
    @action
    private onArchiveClicked = () => () => {
        if (this.props.onToggleProjectArchived) {
            this.props.onToggleProjectArchived(this.props.project);
        }
    }

    @action
    private onProjectClicked = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }

        if (store.routerHistory) {
            store.routerHistory.push(`/project/${this.props.project.id}`);
        }
    };
    
}