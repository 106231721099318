import React, { FormEvent } from "react";
import { RouteComponentProps } from "react-router";
import FileUpload from "../FileUpload/FileUpload";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import If from "../If/If";
import alert from "../../../Util/ToastifyUtils";
import axios from "axios";
import {Combobox, ComboboxOption} from "../Combobox/Combobox";
import gql from 'graphql-tag';
import {IProjectEntityAttributes, PriceVersionEntity} from "../../../Models/Entities";
import {store} from "../../../Models/Store";
import buildPriceVersionList from "../../../Util/PriceVersionUtils";

interface FormData {
	partsFile?: File
}

@observer
export default class UploadPartsList extends React.Component<RouteComponentProps> {

	@observable
	private formData: FormData = {};

	@observable
	private priceVersions: {[key: string]: PriceVersionEntity} = {};
	
	@observable
	private selectedPriceVersion: {priceVersionId: string} = {priceVersionId: ''};

	componentDidMount() {
		this.fetchPriceVersions();
	}

	protected onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		if (this.formData.partsFile) {
			// Do a check to ensure the file is the type we need
			let fileParts = this.formData.partsFile.name.split('.');
			if (fileParts.length > 1) {
				if (fileParts[1] !== 'csv' && fileParts[1] !== 'txt') {
					alert('File type needs to be a csv', 'error');
					return;
				}
			}

			const formData = new FormData();
			formData.append('csv', this.formData.partsFile);

			try {
				await axios.post(`/api/entity/PriceVersionEntity/upload`, formData, {
					headers: {
						'Content-Type': 'mulitipart/form-data',
					}
				});
				alert('Successfully uploaded file', 'success');
				this.fetchPriceVersions();
			} catch(e) {
				alert('Error uploading CSV file. Please reload the page and try again', 'error');
				console.error('Error while uploading csv', e);
			}
		} else {
			alert('Please select a file to upload', 'error');
		}
	};

	private fetchPriceVersions = () => {
		const query = gql`query fetchPriceVersions {
			priceVersionEntitys {
				${PriceVersionEntity.getAttributes()}
			}
		}`;

		if (store.apolloClient) {
			store.apolloClient.query({query, fetchPolicy: 'network-only'}).then(({data}) =>
				data['priceVersionEntitys'].forEach((details: Partial<IProjectEntityAttributes>) => {
					const priceVersion = new PriceVersionEntity(details);
					runInAction(() => {
						this.priceVersions[priceVersion.id] = priceVersion;
						this.selectedPriceVersion.priceVersionId = priceVersion.id;
					});
				})
			);
		}
	}

	public render() {
		let downloadCsv = `/api/entity/PriceVersionEntity/download/${this.selectedPriceVersion.priceVersionId}`;
		return (
			<div className="admin-home-container">
				<h1>Upload Part List</h1>
				<p>
					Upload a csv file containing a list of part codes and their costs, and the prices for those parts will be automatically updated.
				</p>

				<form className="form" onSubmit={this.onSubmit}>
					<FileUpload model={this.formData} modelProperty='partsFile' />
					<button type='submit'>Submit</button>
					<If condition={!!this.formData.partsFile}>
						{' '}File selected: <i>{this.formData.partsFile?.name}</i>
					</If>
				</form>

				<h1>Download Part List</h1>
				<Combobox
					label=''
					isClearable={false}
					searchable={false}
					isDisabled={false}
					model={this.selectedPriceVersion}
					modelProperty='priceVersionId'
					options={buildPriceVersionList(this.priceVersions)}/>
				<a className="icon-download icon-left" href={downloadCsv} download>Get Parts List</a>
			</div>
		);
	}

}