import React from 'react';
import { ProjectEntity } from '../../../../Models/Entities';
import { observer } from 'mobx-react';
import { ProjectInformationForm } from '../../ProjectDetails/ProjectInformationForm';
import ProjectContactForm from '../../ProjectDetails/ProjectContactForm';
import ProjectDeliveryDetailsForm from '../../ProjectDetails/ProjectDeliveryDetailsForm';
import { Button, Display } from '../../Button/Button';
import alert from '../../../../Util/ToastifyUtils';
import {action, observable, runInAction} from 'mobx';
import {store} from "../../../../Models/Store";
import gql from 'graphql-tag';
import axios from 'axios';
import { ProjectContext } from '../ProjectContext';

interface IDetailsTabProps {
	project: ProjectEntity;
}

@observer
export default class DetailsTab extends React.Component<IDetailsTabProps> {
	static contextType = ProjectContext;

	@observable
	private isCountryEditable = false;

	// Need to create a new project entity to prevent the UI from changing after updating an attribute
	// (country in this case)
	@observable
	private project = new ProjectEntity(this.props.project);
	
	componentDidMount() {
		if (store.hasBackendAccess) {
			this.fetchOrderCount();
		}
	}

	/**
	 * Fetch the number of orders attached to this project
	 *
	 * If there are no orders on the project, the admin is allowed to update the country
	 * Once an order has been made, no one is able to update the country
	 */
	private fetchOrderCount = () => {
		store.apolloClient
			.query({
				query: gql` query countOrders($projectId: [String]) {
            		countOrderEntitys(where: {path: "projectId", comparison: equal, value: $projectId}) {
                		number
            		}}`,
				variables: {
					"projectId": this.props.project.id
				},
				fetchPolicy: 'network-only',
			})
			.then(results => {
				const totalOrders = results.data.countOrderEntitys.number;
				if (totalOrders == 0) {
					runInAction(() => this.isCountryEditable = true);
				}
			}).catch(err => console.error(err));
	}

	@action
	private updateProject = async () => {
		let project = this.props.project;

		if (project.addressId === 'empty') {
			project.addressId = undefined;
		}

		await project.validate();
		if (project.hasValidationError) {
			alert('Project validation failed', 'error');
			console.error(this.props.project.validationErrors);
			return;
		}

		try {
			await this.project.save();

			if (this.project.country !== this.props.project.country) {
				await axios.post(`/api/entity/ProjectEntity/setDefaultPriceVersion/${this.project.id}/${this.project.priceVersionId}`);
				await this.context.setProjectContext(true);
			}
			alert('Successfully update project details', 'success');
		} catch (e) {

			// Rollback the country
			runInAction(() => this.project.country = this.props.project.country);
			await this.project.save();

			console.log(e);
			alert('Could not save project', 'error');
		}

		// Set the attributes to the props project (for rerendering)
		this.props.project.assignAttributes(this.project);
	};

	private isFormValid() {
		const project = this.props.project;
		return !!(project.name
			&& project.clientJobNumber
			&& project.postcode
			&& project.country
			&& project.projectContactId);
	}

	private renderProjectInformation() {
		return (
			<div className='form-section'>
				<div className='heading-row'>
					<h4>
						Project Information
					</h4>
					<Button
						display={Display.Solid}
						onClick={this.updateProject}
						disabled={!this.isFormValid()}
					>
						Update
					</Button>
				</div>
				<div className='form-fields'>
				<ProjectInformationForm project={this.project} isCountryEditable={this.isCountryEditable}/>
				</div>
			</div>
		);
	}

	private renderProjectContact() {
		return (
			<div className='form-section'>
				<h4>
					Project Contact
				</h4>
				<div className='form-fields'>
					<ProjectContactForm project={this.project}/>
				</div>
			</div>
		);
	}

	private renderDeliveryDetails() {
		return (
			<div className='form-section'>
				<h4>
					Delivery Details
				</h4>
				<div className='form-fields'>
					<ProjectDeliveryDetailsForm project={this.project}/>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className='details-tab'>
				<div className='form-area'>
					{this.renderProjectInformation()}
					<div className='form-row'>
						{this.renderProjectContact()}
						{this.renderDeliveryDetails()}
					</div>
				</div>
			</div>
		);
	}

}