import { ProjectEntity } from "../../../Models/Entities";
import React from "react";
import { Button, Colors, Display } from "../Button/Button";
import {action, observable, runInAction} from "mobx";
import { store } from "../../../Models/Store";
import If from "../If/If";
import {TextField} from "../TextBox/TextBox";
import {observer} from "mobx-react";
import DashboardTile from "./DashboardTile";
import {Alignment, ButtonGroup} from "../Button/ButtonGroup";
import classNames from "classnames";
import alert from '../../../Util/ToastifyUtils';
import ArchiveImage from '../../../Assets/images/archive.svg';
import SmartlookService from '../../../Services/SmartlookService';

type projectArchivedState = 'Active' | 'Archived';
export interface IDashboardProps {
	projectList: ProjectEntity[],
}

@observer
export default class Dashboard extends React.Component<IDashboardProps> {
	
	@observable 
	public selectedProject?: ProjectEntity;

	@observable
	private projectFilter: projectArchivedState = 'Active';

	@observable 
	private searchTerm: string = "";

	public render() {
		return !!this.props.projectList && this.props.projectList.length > 0
			? this.renderProjectList()
			: this.renderEmptyDashboard();
	}

	private renderEmptyDashboard = () => {
		return (
			<div className="empty-dashboard">
				<div className="empty-dashboard-content">
					<h1>Get started by setting up your first project.</h1>
					<p>Use this tool to order elements and parts for your projects.</p>
					<If condition={!!store.organisationId}>
						<Button onClick={this.newProject}
								className="new-project-btn"
								display={Display.Solid}
								colors={Colors.Primary}
								icon={{ icon: "plus", iconPos: 'icon-left' }}>
							New project
						</Button>
					</If>
				</div>
				<div className="spacing-div"/>
				<div className="empty-dashboard-background"/>
			</div>
		);
	}
	
	private renderProjectList() {
		return (
			<div className="dashboard page">
				<div className="project-selection-section">
					<div className="dashboard-header">
						<h1 className="dashboard-title">Projects</h1>
						<div className="dashboard-action-bar">
							<TextField
								inputProps={{
									className: "search-project-list",
									id: 'search-input-field',
									onClick: () => SmartlookService.triggerEvent('Search Bar Click')
								}}
								model={this}
								modelProperty="searchTerm"
								placeholder="Search" />
							<ButtonGroup alignment={Alignment.HORIZONTAL}>
								<Button onClick={() => this.applyFilter('Active')} className={classNames("filter-btn", {"selected": this.projectFilter === 'Active'})} colors={Colors.Secondary}>Active</Button>
								<Button onClick={() => this.applyFilter('Archived')} className={classNames("filter-btn", {"selected": this.projectFilter === 'Archived'})} colors={Colors.Secondary}>Archived</Button>
								<If condition={!store.hasBackendAccess}>
									<Button onClick={this.newProject} className="new-project-btn" display={Display.Solid} colors={Colors.Primary} icon={{ icon: "plus", iconPos: 'icon-left' }}>New project</Button>
								</If>
							</ButtonGroup>
						</div>
					</div>

					{this.filterProjectList()}
				</div>
			</div>
		);
	}
	
	private filterProjectList = () => {
		let filteredList = this.props.projectList
			.filter(project => {
				return (this.projectFilter === 'Archived' ? project.archived : !project.archived) &&
					(!this.searchTerm || 
						(project.name && project.name.toLowerCase().includes(this.searchTerm.toLowerCase())) || 
						(project.clientJobNumber && project.clientJobNumber.toLowerCase().includes(this.searchTerm.toLowerCase())));
			})
			.sort((one, two) => {
				return two.modified.valueOf() - one.modified.valueOf();
			}); 

		if (filteredList.length === 0) {
			let content;
			if (this.projectFilter === 'Archived' && this.searchTerm === '') {
				content = (
					<>
						<img src={ArchiveImage} />
						<h4>No archived projects yet</h4>
					</>
				);
			} else {
				content = <h4>No projects found</h4>;
			}

			return (
				<div className='no-projects-found'>
					{content}
				</div>
			);
		}

		const projectTiles = filteredList.map(project =>
			<DashboardTile
				project={project}
				onToggleProjectArchived={this.toggleProjectArchived}
				key={project.id}
				onClick={() => this.searchTerm.trim() !== ''
					&& SmartlookService.triggerEvent('Search Results Click')}
			/>
		);

		return (
			<div className="project-list">
				{projectTiles}
			</div>
			);
	}
	
	@action 
	private applyFilter = (projectState: projectArchivedState) => {
		this.projectFilter = projectState; 
	}

	@action 
	private newProject = async () => {
		store.routerHistory.push('/newproject');
	};

	@action
	private toggleProjectArchived = (project: ProjectEntity) => {
		project.archived = !project.archived;
		const newState = project.archived ? 'archived' : 'activated'; 
		project.save().then(() => {
			alert(`${project.name} has been ${newState}`, 'success');
		}).catch(error => {
			alert(`${project.name} cannot be ${newState}`, 'error');
		});
	}


}