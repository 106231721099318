import * as React from 'react';
import {observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {FormEvent} from "react";
import axios from 'axios';
import {action, observable, runInAction} from "mobx";
import OrganisationEntity from "Models/Entities/OrganisationEntity";
import {Combobox} from "Views/Components/Combobox/Combobox";
import {TextField} from "Views/Components/TextBox/TextBox";
import alert from '../../../Util/ToastifyUtils';

interface FormData {
    organisationId?: string,
    email: string,
}

@observer
export default class AddUserToOrganisation extends React.Component<RouteComponentProps> {

    @observable formData: FormData = {
        organisationId: undefined,
        email: "",
    };

    @observable organisationList: OrganisationEntity[];

    @action
    public componentDidMount(): void {
        OrganisationEntity.fetchOrganisations().then(results => {
            runInAction(() => this.organisationList = results);
        }).catch(error => {
            console.error('gql Error - fetching organisation entities:', error);
        })
    }

    public render() {

        if (!this.organisationList) {
            return null;
        }

        return (
            <>
                <div className="admin-home-container">

                    <form className="form" onSubmit={this.onSubmit}>
                        <h1>Invite Organisation Users</h1>
                        <p>Choose an organisation, and enter an email address. The given user will be invited
                            as a user for that organisation.</p>

                        <Combobox
                            className="organisation"
                            model={this.formData}
                            modelProperty="organisationId"
                            label="Organisation"
                            options={this.getOrganisationDropdownValues(this.organisationList)}
                            searchable={false}/>

                        <TextField
                            className="admin-email"
                            model={this.formData}
                            modelProperty="email"
                            label="User Email"/>

                        <button type="submit">Submit</button>
                    </form>
                </div>
            </>

        )
    }

    protected getOrganisationDropdownValues = (organisationList: OrganisationEntity[]) => {
        return organisationList.map(org => {
            return {
                display: org.name,
                value: org.id,
            }
        })
    };

    protected onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const result = await axios.post("/api/entity/InvitedUserEntity/invite", {
            Email: this.formData.email,
            OrganisationId: this.formData.organisationId,
            Role: 'ADMIN',
        });

        alert(result.data.message, result.data.success === true ? "success" : "error");
    };
}