import { observer } from "mobx-react";
import * as React from 'react';
import { action, computed, observable } from 'mobx';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Button, Colors, Display, QuickTypes } from '../../Button/Button';
import { Alignment, ButtonGroup } from '../../Button/ButtonGroup';
import classNames from 'classnames';
import moment from 'moment';
import If from '../../If/If';
import { EntityContextMenu } from '../../EntityContextMenu/EntityContextMenu';
import { ICollectionRowProps } from "../../Collection/CollectionRow";
import { TextField } from "../../TextBox/TextBox";
import { ProductEntity, ShutterEntity } from "../../../../Models/Entities";
import PartsListExpandedCollection from "./PartsListExpandedCollection";
import ElementPartEntity from "../../../../Models/Entities/ElementPartEntity";
import alert from "../../../../Util/ToastifyUtils";
import ShutterExpandedCollection from "./ShutterExpandedCollection";
import { IProjectContext, ProjectContext } from "../ProjectContext";
import SmartlookService from '../../../../Services/SmartlookService';
import { SERVER_URL } from '../../../../Constants';
import axios from 'axios';
import {Combobox} from "Views/Components/Combobox/Combobox";
import {store} from "../../../../Models/Store";

export interface IProductCollectionRowProps<T> extends ICollectionRowProps<T> {
	/** Callback for toggling edit mode */
	onEditModeUpdated: (editMode: boolean) => boolean;
    fetchWeight?: () => void;
}

/**
 * This is a row in a collection component
 */
@observer
class ProductCollectionRow<T> extends React.Component<IProductCollectionRowProps<T>, any> {

    static contextType = ProjectContext;
    context!: IProjectContext;
    
    @observable
    private editMode = this.props.editMode ?? false; 
    
    @observable
    private expanded = this.props.expanded ?? false;

    @observable
    private checked = {checked: this.props.checked}

    private moreMenuRef : EntityContextMenu<T> | null;

    /**
     * The dom for the expanded row
     */
    @computed
    private get expandDom() {
        if (this.expanded && this.props.item instanceof ProductEntity) {
            // The magic number is since we have an extra column for the checkbox and another for the actions
            const colSpanOffset = this.props.selectableItems ? 2 : 1;
            return (
                <tr className={classNames("collection__item",
                    "collection__item--is-expanded-child", (this.expanded ? '' : ' hide'))}
                >
                    <td colSpan={this.props.headers.length + colSpanOffset}>
                        {this.expandProductsList(this.props.item)}
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    }

    constructor(props: IProductCollectionRowProps<T>, context: any) {
        super(props, context);
        this.checked.checked = this.props.checked;
    }

    private expandProductsList = (product: ProductEntity) => {
        let contents;

        // Render expand collection for elements and shutters
        if (!!product.element) {
            contents =             
                <PartsListExpandedCollection
                    product={product}
                    onUpdatePartsList={(partsList) => this.elementPartsUpdated(product, partsList, )}
                    onEditModeUpdated={this.props.onEditModeUpdated}
                    editMode={this.props.editMode}
                    onCancelEdit={() => {
                        if (this.props.refetch) {
                            this.props.refetch();
                        }
                    }}
                    isReadOnly={this.props.isReadOnly}
                    fetchWeight={this.props.fetchWeight}
                />
        } else if (!!product.shutter) {
            contents = 
                <ShutterExpandedCollection
                    product={product}
                    onUpdateShutter={(shutter) => this.shutterUpdated(product, shutter)}
                    onEditModeUpdated={this.toggleEditMode}
                    editMode={this.props.editMode}
                    onCancelEdit={() => {
                        if (this.props.refetch) {
                            this.props.refetch();
                        }
                    }}
                    isReadOnly={this.props.isReadOnly}
                />
        }
        
        return contents; 
    }

    @action
    private elementPartsUpdated = async (product: ProductEntity, partsList: ElementPartEntity[]) => {
        product.element.partOrders = partsList;

        const relationPath = {
            element: {
                partOrders: {},
            },
        };

        try {
            const isNewElement = !product.elementId;
            await product.save(relationPath);
            if (this.props.refetch) {
                this.props.refetch();
            }

            // Status count should be updated if it is a new element
            if (isNewElement) {
                SmartlookService.triggerEvent('Save Element Added');
                
                if (this.context.setProjectContext) {
                    await this.context.setProjectContext(false, true);
                }
            }
            this.props.fetchWeight && this.props.fetchWeight();
            alert(`Successfully updated part details`, 'success');
            return true;
        } catch (err) {
            console.error(err);
            alert(`Could not save element ${product.element.elementId}`, 'error');
            return false;
        }
    }

    @action
    private shutterUpdated = async (product: ProductEntity, shutter: ShutterEntity) => {
        product.shutter = shutter;

        try {
            SmartlookService.triggerEvent('Shutters Added Saved');

            // Delete the sketch if needed
            if (product.shutter.deleteSketchId) {
                await axios.get(`${SERVER_URL}/api/files/delete/${product.shutter.deleteSketchId}`);
            }

            await product.saveShutterFromProduct();

            if (this.context.setProjectContext) {
                await this.context.setProjectContext(false, true);
            }

            if (this.props.refetch) {
                this.props.refetch();
            }
            this.props.fetchWeight && this.props.fetchWeight();
            alert(`Successfully updated shutter details`, 'success');
        } catch (err) {
            console.error(err);
            alert(`Could not save shutter ${product.shutter.shutterId}`, 'error');
        }
    }

    private buildPartsComboboxList = () => {
        const options: {
            display: string;
            value: string | undefined;
        }[] = [];

        for (let key in store.codeDict) {
            options.push({ display: key, value: key});
        }
        
        return options;
	};

    @action
    private toggleEditMode = () => {
        this.editMode = !this.editMode;
        store.elementShutterEditMode = this.editMode;
        
        return this.props.onEditModeUpdated(this.editMode);
    }

    public render() {
        const columns = [];

        // The checkbox at the start of the row
        if (!!this.props.selectableItems) {
            columns.push(
                <td key="0" className="select-box">
                    <Checkbox
                        model={{}}
                        modelProperty=""
                        name="select"
                        inputProps={{
                            checked: this.props.checked,
                            onChange: event => {
                                if (this.props.onChecked) {
                                    this.props.onChecked(event, event.target.checked, this.props.item);
                                }
                            }
                        }}
                    />
                </td>,
            );
        }

        // The columns from the item to display
        columns.push(this.props.headers.map((column, itemIdx) => {
            let displayValue: any;

            if (column.transformItemWithEdit) {
                displayValue = column.transformItemWithEdit(this.props.item, this.editMode);
            } else if (column.transformItem) {
                displayValue = column.transformItem(this.props.item, column.name);
            } else if (this.props.item[column.name] || this.props.item[column.name] === 0) {
                if (typeof(this.props.item[column.name]['toLocaleDateString']) === 'function') {
                    displayValue = moment(this.props.item[column.name]).format('DD/MM/YYYY');
                } else if (typeof(this.props.item[column.name]['toString']) === 'function') {
                    displayValue = this.props.item[column.name]['toString']();
                } else {
                    displayValue = this.props.item;
                }
            } else {
                displayValue = column.nullValue || 'None';
            }
            
            if (column.editable && this.editMode) {
                const item = this.props.item;

                if (item instanceof ProductEntity) {
                    if (!!item.element) {
                        displayValue = <TextField model={item.element} modelProperty={column.name} defaultToUndefined={true}/>;
                    } else if (!!item.shutter) {
                        const disabled = !item.shutter.shouldFieldBeEditable(column.name);
                        
                        displayValue = (
                            <TextField
                                model={item.shutter}
                                modelProperty={column.name}
                                defaultToUndefined={true}
                                isDisabled={disabled}
                            />
                        );
                    }
                } else {
                    let disabled = false;
                    if (item instanceof ElementPartEntity) {
                        // Check if this field should be editable based on the column name
                        disabled = !item.shouldFieldBeEditable(column.name);
                    }
                    
                    if (column.name === "partCode") {
                        // Part Code field requires a combo box
                        displayValue = <Combobox
                                            model={item}
                                            modelProperty={column.name}
                                            label=""
                                            isClearable={true}
                                            placeholder="No Part Selected"
                                            getOptionValue={part => part ? part : undefined}
                                            options={this.buildPartsComboboxList()}
                                            isDisabled={disabled}
                                        />
                    } else {
                        // for element and shutter expanded row
                        displayValue = <TextField model={item}
                                            modelProperty={column.name}
                                            defaultToUndefined={true}
                                            isDisabled={disabled} />;
                    }
                }
            }

            return (
                <td key={itemIdx + 1} className={classNames(this.props.className, column.columnSize)}> 
                    {displayValue}
                </td>
            );
        }));

        // The action buttons
        let actionButtons: JSX.Element[] = [];

        if (typeof(this.props.actions) === 'function') {
            actionButtons = this.props.actions(this.props.item)
                .map((action, actIdx) => {
                    if (!action.customButton) {
                        const icon = action.showIcon && action.icon && action.iconPos ? {icon: action.icon, iconPos: action.iconPos} : undefined;
                        return <Button
                            key={actIdx}
                            className={action.buttonClass}
                            icon={icon}
                            buttonProps={ {onClick: event => {action.action(this.props.item, event);}} } >
                            {action.label}
                        </Button>;
                    }

                    return <React.Fragment key={actIdx}>{action.customButton(this.props.item)}</React.Fragment>
                });
        } else if (Array.isArray(this.props.actions)) {
            actionButtons = this.props.actions
                .map((action, actIdx) => {
                    if (!action.customButton) {
                        const icon = action.showIcon && action.icon && action.iconPos ? {icon: action.icon, iconPos: action.iconPos} : undefined;
                        return <Button
                            key={actIdx}
                            className={action.buttonClass}
                            icon={icon}
                            buttonProps={ {onClick: event => {action.action(this.props.item, event);}} } >
                            {action.label}
                        </Button>;
                    }

                    return <React.Fragment key={actIdx}>{action.customButton(this.props.item)}</React.Fragment>
                });
        }

        // The expand button if needed
        let expandButton = null;
        if (!this.props.showExpandButton || this.props.showExpandButton(this.props.item) && !this.editMode) {
            expandButton = (
                <Button
                    quickTypes={QuickTypes.Secondary}
                    colors={Colors.Secondary}
                    icon={this.expanded ? { icon: 'chevron-up', iconPos: 'icon-top' } : { icon: 'chevron-down', iconPos: 'icon-top' }}
                    buttonProps={{ onClick: action(() => this.expanded = !this.expanded) }}
                />
            );
        }

        if (expandButton || this.props.actions || (this.props.actionsMore && this.props.actionsMore.length > 0)) {
            columns.push(
                <td className="list__items--actions" key={this.props.headers.length + 1}>
                    <ButtonGroup alignment={Alignment.HORIZONTAL}>
                        {actionButtons}
                        {expandButton}
                        <If condition={!!this.props.actionsMore && !!this.props.actionsMore.length}>
                            <EntityContextMenu
                                location={'frontend'}
                                menuId={this.props.keyValue}
                                actions={this.props.actionsMore || []}
                                ref={(ref) => { this.moreMenuRef = ref || null }}
                                entity={this.props.item}
                            />
                            <span 
                                className="icon-more-horizontal icon-only" 
                                onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
                                    if (this.moreMenuRef) {
                                        this.moreMenuRef.handleContextMenu(event);
                                    }
                                }}
                            />
                        </If>
                    </ButtonGroup>
                </td>,
            );
        }

        let prefixedDataFields = {};
        if (this.props.dataFields) {
            const dataFields = this.props.dataFields(this.props.item);
            Object.keys(dataFields).forEach(key => {
                prefixedDataFields[`data-${key}`] = dataFields[key];
            });
        }

        return (
            <>
                <tr
                    className={
                        classNames(
                            "collection__item",
                            (this.expanded? 'collection__item--has-expanded-child' : null),
                            this.checked.checked ? "collection__item--selected" : null)
                    }
                    data-id={this.props.idColumn ? this.props.item[this.props.idColumn] : undefined}
                    {...prefixedDataFields}
                >
                    {columns}
                </tr>
                {this.expandDom}
            </>
        );
    }
}

export default ProductCollectionRow;