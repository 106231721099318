import * as React from 'react';
import {observer} from 'mobx-react';
import {action, computed, observable, runInAction} from 'mobx';
import * as uuid from 'uuid';
import classNames from 'classnames';
import {ICollectionHeaderProps, ICollectionHeaderPropsPrivate} from "../../Collection/CollectionHeaders";
import ProductCollectionRow from "./ProductCollectionRow";
import {ElementPartEntity, ProductEntity} from "../../../../Models/Entities";
import {Alignment, ButtonGroup} from "../../Button/ButtonGroup";
import {Button, Colors} from "../../Button/Button";
import alert from "../../../../Util/ToastifyUtils";
import {store} from "../../../../Models/Store";
import axios from "axios";
import formatPrice from '../../../../Util/PriceUtils';
import If from '../../If/If';
import {IProjectContext, ProjectContext} from "../ProjectContext";


export interface IPartsListCollectionProps {
    product: ProductEntity; 
    
    /** Pass through for any props to pass to the top level section */
    innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    
    onUpdatePartsList: (partsList: ElementPartEntity[]) => Promise<boolean>;
    
    editMode?: boolean;

    isReadOnly?: boolean;

    onEditModeUpdated: (editMode: boolean) => boolean;
    
    onCancelEdit: () => void; 

    fetchWeight?: () => void;
}

/**
 * Displays a collection of element parts
 * element parts to render are passed in as props (part of product)
 * some attributes such as quantity dimensions are editable.
 * When changes are saved, it is passed back up to ProductCollectionRow to handle 
 */
@observer
export default class PartsListExpandedCollection extends React.Component<IPartsListCollectionProps> {

    static contextType = ProjectContext;
    context!: IProjectContext;
    
    @observable
    private partsList = this.props.product.element.partOrders;

    @observable
    private editMode = this.props.editMode ?? false;

    private deleteElementParts: string[] = [];

    private partsListEssentialHeaders: Array<ICollectionHeaderProps<ElementPartEntity>> = [
        {
            name: 'partCode',
            displayName: 'Aptus Code',
            sortable: false,
            transformItem: model => model.partCode,
            editable: true,
            columnSize: 'big',
        },
        {
            name: 'description',
            displayName: 'Description',
            sortable: false,
            transformItem: model => {
                // Little easter egg :)
                if (!!model.partCode && model.partCode.toLowerCase() === 'discount code') {
                    alert('None for you', 'error');
                    runInAction(() => model.price = 1_000_000);
                }

                let content;

                if (!!store.codeDict) {
                    content = model.getDescription() ?? 'INVALID';
                } else {
                    content = 'ERROR';
                }

                return (
                    <div className='part-description'>
                        {content}
                    </div>
                );
            },
        },
        {
            name: 'quantity',
            displayName: 'Qty',
            sortable: false,
            transformItem: model => model.quantity,
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'dimensionA',
            displayName: 'A',
            sortable: false,
            transformItem: model => model.dimensionA,
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'dimensionB',
            displayName: 'B',
            sortable: false,
            transformItem: model => model.dimensionB,
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'dimensionC',
            displayName: 'C',
            sortable: false,
            transformItem: model => model.dimensionC,
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'dimensionD',
            displayName: 'D',
            sortable: false,
            transformItem: model => model.dimensionD,
            editable: true,
            columnSize: 'small',
        },
        {
            name: 'pinDiameter',
            displayName: 'Pin',
            sortable: false,
            transformItem: model => model.pinDiameter,
            editable: true,
            columnSize: 'small',
        },
    ];

    private partsListAdditionalHeaders: Array<ICollectionHeaderProps<ElementPartEntity>> = [
        {
            name: 'length',
            displayName: 'Length',
            sortable: false,
            transformItem: model => this.editMode || model.length === 0 ? '' : model.length,
        },
        {
            name: 'price',
            displayName: 'Cost',
            sortable: false,
            transformItem: model => this.editMode ? '-' : formatPrice(model.price),
        },
    ];

    private partsListEditOnlyHeaders: Array<ICollectionHeaderProps<ElementPartEntity>> = [
        {
            name: '',
            displayName: '',
            sortable: false,
            transformItem: (model: ElementPartEntity) => (
                    <Button
                        onClick={() => this.removePart(model)}
                        className={classNames("action-btn", 'icon-middle', 'more-actions')}
                        colors={Colors.Secondary}
                        icon={{ icon: "bin-empty" }}
                    />
                ),
            columnSize: 'small'
        }
    ];

    /**
     * The headers of the collection
     */
    @computed
    private get headers(): Array<ICollectionHeaderPropsPrivate<ElementPartEntity>> {
        let headers = this.editMode ? this.partsListEssentialHeaders.concat(this.partsListEditOnlyHeaders) :
            this.partsListEssentialHeaders.concat(this.partsListAdditionalHeaders);
        
        return headers.map(header => {
            const computedHeader: ICollectionHeaderPropsPrivate<ElementPartEntity> = {...header};

            if (typeof header.displayName === 'string') {
                computedHeader.headerName = header.displayName;
            } else if (typeof header.displayName === 'function') {
                computedHeader.headerName = header.displayName(header.name);
            }

            return computedHeader;
        });
    }

    public render() {
        return (
            <section
                {...this.props.innerProps}
            >
                <div className='parts-list'>
                    {this.list()}
                </div>
                <If condition={!this.props.isReadOnly}>
                    <div className='parts-actions'>
                        {this.renderButtonGroups()}
                    </div>
                </If>
            </section>
        );
    }

    /**
     * The table list component
     */
    private list = () => {
        const collectionId = uuid.v4();
        const className = classNames('collection__list');
        return (
            <section aria-label="collection list" className={className}>
                <table>
                    {this.header()}
                    <tbody>
                    {this.row({id: collectionId}) }
                    </tbody>
                </table>
            </section>
        );
    }

    /**
     * The table row component
     * @param props Contains the id of the row
     */
    private row = (props: {id: string}) => {
        return (
            <>
                {this.partsList.map((item, idx) => {
                    return (
                        <ProductCollectionRow
                            item={item}
                            headers={this.headers}
                            key={`${idx}-${props.id}`}
                            keyValue={`${idx}-${props.id}`}
                            className={'expand__item'}
                            onEditModeUpdated={this.props.onEditModeUpdated}
                            editMode={this.editMode}
                            showExpandButton={() => false}
                        />
                    );
                })}
            </>
        );
    }

    /**
     * The header row component
     */
    private header = () => {
        return (
            <thead>
                <tr className="parts-list__header">
                    {this.headers.map((header, idx) => {
                        return (
                            <th key={idx} scope="col" >
                                {header.headerName}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }

    private renderButtonGroups = () => {
        const hasBeenOrdered = this.props.product.orderId != null;
        const isAdmin = store.hasBackendAccess;

        let contents;
        if (this.editMode) {
            contents =
                <>
                    <Button className="action-btn" colors={Colors.Secondary} icon={{ icon: "plus", iconPos: 'icon-left' }} onClick={this.addNewPart}>Add row</Button>
                    <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group">
                        <Button colors={Colors.Secondary} onClick={this.cancelEdit}>Cancel</Button>
                        <Button colors={Colors.Tertiary} onClick={this.updateParts}>Save</Button>
                    </ButtonGroup>
                </>;
        } else {
            contents =
                <ButtonGroup alignment={Alignment.HORIZONTAL} className="action-btn-group">
                    <Button colors={Colors.Tertiary} onClick={this.onEdit} disabled={hasBeenOrdered && !isAdmin}>
                        Edit
                    </Button>
                </ButtonGroup>;
                
        }

        return contents;
    }

    @action 
    private addNewPart = () => {
        this.partsList.push(new ElementPartEntity()); 
    }

    @action
    private removePart = async (model: ElementPartEntity) => {
        this.partsList = this.partsList.filter(part => part !== model);
        this.props.product.element.partOrders = this.props.product.element
            .partOrders.filter(part => part !== model);

        if (model.id) {
            this.deleteElementParts.push(model.id);
        }
    }

    private updateParts = async () => {
        if (!this.props.product.element.elementId) {
            alert(`Element Id needs to be filled in`, 'error');
            return;
        }
            
        let duplicateCheck = await this.validateDuplicateElementId();
        if (!duplicateCheck) {
            alert(`${this.props.product.element.elementId} already exists. Please use a different Id.`, 'error');
            return;
        }

        for (const part of this.partsList) {
            await part.validate();

            if (part.hasValidationError) {
                alert(`Aptus code needs to be filled in`, 'error');
                console.error(part.validationErrors);
                return;
            }

            if (part.hasInvalidDimensions()) {
                alert(`${part.partCode} has invalid dimensions`, 'error');
                return;
            }

            if (!this.validateAptusPart(part)) {
                alert(`${part.partCode} is not a valid aptus part`, 'error');
                return;
            }

            if (this.isPinDiameterRequired(part)) {
                alert(`${part.partCode} needs pin diameter`, 'error');
                return;
            }

            runInAction(() => {
                if (part.id == null) {
                    part.elementId = this.props.product.element.id;
                }

                // Make the partCode uppercase before saving
                part.partCode = part.partCode.toUpperCase()

            });

        }

        await (new ElementPartEntity()).deleteWhere([], this.deleteElementParts);

        const success = await this.props.onUpdatePartsList(this.partsList);
        if (success) {
            this.toggleEditMode();
        }
    }

    private validateDuplicateElementId = async () => {
        try {
            let { data } = await axios.post(`/api/entity/ProjectEntity/checkElementDuplicates/`, {
                ProjectId: this.props.product.projectId,
                NewElementIds: [this.props.product.element.elementId],
                OriginalElementId: this.props.product.elementId,
            });

            const duplicatesFound: string[] = data['foundDuplicates'];
            return duplicatesFound === null || duplicatesFound.length === 0;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private validateAptusPart = (part: ElementPartEntity) => {
        const description = part.getDescription();
        return !!description;
    }
    
    private isPinDiameterRequired = (part: ElementPartEntity) => {
        if (this.context.projectCountry === 'AUSTRALIA') {
            return false;
        }
        
        const dimensionsCount = part.getDimensionCount();
        return dimensionsCount > 1 && !part.pinDiameter;
    }
    
    @action
    private toggleEditMode = () => {
        this.editMode = !this.editMode;
        store.elementShutterEditMode = this.editMode;
        
        this.props.onEditModeUpdated(this.editMode);
    }

    @action
    private onEdit = () => {
        if (!store.showToastInEditMode("element")) {
            this.toggleEditMode();
        }
    }

    @action
    private cancelEdit = () => {
        this.toggleEditMode();

        this.props.onCancelEdit();
    }
}