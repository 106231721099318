import * as React from 'react';
import {observer} from 'mobx-react';
import {action, observable} from "mobx";
import {store} from "Models/Store";
import {Alignment, ButtonGroup} from "Views/Components/Button/ButtonGroup";
import {Button, Colors, Display} from "Views/Components/Button/Button";
import {getAttributeComponent} from "Views/Components/CRUD/Attributes/AttributeFactory";
import {EntityFormMode} from "Views/Components/Helpers/Common";
import {isRequired} from "Util/EntityUtils";
import alert from "Util/ToastifyUtils";
import AddressEntity from "../../../Models/Entities/AddressEntity";
import {RequestState} from "../../../Models/Enums";

@observer
export default class Company extends React.Component {
    
    @observable
    private addresses: AddressEntity[]; 

    @observable
    private requestState: RequestState = 'loading';

    public componentDidMount() {
        AddressEntity.fetch<AddressEntity>({args: [[{path: 'organisationId', comparison: "equal", value: store.organisationId}]]})
            .then(d => this.changeRequestState('done', d.sort(sortAddress)))
            .catch(e => this.changeRequestState('error'));
    }

    @action
    private changeRequestState = (state: RequestState, addresses?: AddressEntity[]) => {
        if (addresses) {
            this.addresses = addresses;
        }
        this.requestState = state;
    };

    public render() {
        let contents = null;

        if (this.requestState === 'loading') {
            contents = <div></div>;
        } else if (this.requestState === 'error' || !this.addresses) {
            contents = (
                <div className="update-profile">
                    <h3>There has been an error</h3>
                    <p>Unable to load the profile page at this time. Please contact us if the problem persists.</p>
                </div>
            );
        } else {
            contents = this.renderProfile();
        }

        return contents;
    }

    private renderProfile = () => {
        const entityAttrs = this.getUpdateFields();
        return (
            <form onSubmit={this.onSubmitUpdateClicked}>
                <div className="header">
                    <h4>Company</h4>
                    <ButtonGroup alignment={Alignment.HORIZONTAL}>
                        <Button type='submit'
                                className="submit-update-profile"
                                display={Display.Solid}
                                colors={Colors.Primary}>
                            Update
                        </Button>
                    </ButtonGroup>
                </div>
                {entityAttrs}
                <ButtonGroup alignment={Alignment.HORIZONTAL} className="btn-group--end">
                    <Button
                        type='submit'
                        className="submit-update-profile"
                        display={Display.Solid}
                        colors={Colors.Primary}>
                        Update
                    </Button>
                    <Button
                        className="btn-reset-password"
                        onClick={this.addDeliveryAddress}
                        display={Display.Outline}
                        colors={Colors.Secondary}
                        icon={{ icon: "plus", iconPos: 'icon-left' }}>
                        Add Additional Delivery Address
                    </Button>
                </ButtonGroup>
            </form>
        );
    };

    private getUpdateFields = () => {
        // Used to show a custom list of fields, and to set the order they display in
        const registrationFieldList: string[] = [
            'addressLine1',
            'addressLine2',
            'suburb',
            'postcode',
            'state',
            'country',
        ];
        
        if (this.addresses.length === 0) {
            const emptyAddress = new AddressEntity({
                name:  'Head Office Address',
                organisationId: store.organisationId,
            });
            this.addresses.push(emptyAddress);
        }
        
        let attributeOptions = this.addresses[0].getAttributeCRUDOptions();
        
        return this.addresses.map(model => {
            let attr = attributeOptions
                .filter(attributeOption =>
                    registrationFieldList.indexOf(attributeOption.attributeName) >= 0)
                .sort((a, b) =>
                    registrationFieldList.indexOf(a.attributeName) - registrationFieldList.indexOf(b.attributeName))
                .map(attributeOption =>
                    getAttributeComponent(
                        attributeOption,
                        model,
                        model.getErrorsForAttribute(attributeOption.attributeName),
                        EntityFormMode.EDIT,
                        isRequired(model, attributeOption.attributeName),
                        undefined,
                        undefined
                    ));
            
            return this.getSingleAddress(attr, model.name); 
        }); 
        
    };
    
    private getSingleAddress = (addressAttribute: any, name: string) => {
        return (
            <div className="company-address" key={name}>
                <h5>{name}</h5>
                <div className="entity-attrs">
                    {addressAttribute}
                </div>
            </div>
        )
    }

    @action
    private onSubmitUpdateClicked = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        let allValidated = true; 
        for(const address of this.addresses) {
            await address.validate();

            if (address.hasValidationError) {
                alert(`Required fields need to be filled in for ${address.name}`, 'error');
                allValidated = false;
                break; 
            } else {
                address.save();
            }
        }
        
        if (allValidated) {
            alert(`Addresses has been updated`, 'success');
        }
    };
    
    @action
    private addDeliveryAddress = () => {
        const emptyAddress = new AddressEntity({
            name: 'Delivery Address #' + this.addresses.length,
            organisationId: store.organisationId,
        });
        
        this.addresses.push(emptyAddress); 
    };

}

export const sortAddress = (a: AddressEntity, b: AddressEntity): number => {
    const aNumber = a.name.split(' #');
    const bNumber = a.name.split(' #');

    // Either a or b is the head office
    if (aNumber.length == 0 || bNumber.length == 0) {
        return aNumber.length > bNumber.length ? -1 : 1;
    }

    return aNumber[1] > bNumber[1] ? -1 : 1;
};

