import React, { ReactNode } from "react";
import { RequestState } from "../../../Models/Enums";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import Spinner from '../Spinner/Spinner';

interface IRequestWrapProps<T, K> {
	// Render prop that is shown once the request has finished
	children: (data: K) => ReactNode,

	// Perform the request
	request: () => Promise<T>,

	// Process the data before saving it in the object
	processData: (data: T) => K,

	// Override the default error message displayed to the user
	errorHandler?: () => ReactNode,
}

@observer
export default class RequestWrap<T, K> extends React.Component<IRequestWrapProps<T, K>> {

	@observable
	private requestState: RequestState = 'loading';

	@observable
	private result: K;

	componentDidMount() {
		this.props.request()
			.then(data => this.changeRequestState('done', data))
			.catch(_ => this.changeRequestState('error'));
	}

	@action
	private changeRequestState = (state: RequestState, result?: T) => {
		if (result) {
			this.result = this.props.processData(result);
		}
		this.requestState = state;
	};

	private static renderError() {
		return (
			<div className='error-msg'>
				<h3>There has been an error</h3>
				<p>Unable to load the page at this time. Please contact us if the problem persists.</p>
			</div>
		);
	}

	public render() {
		let contents: ReactNode = <Spinner />;

		switch (this.requestState) {
			case 'error':
				contents = RequestWrap.renderError();
				break;
			case 'done':
				contents = this.props.children(this.result);
				break;
		}
		return contents;
	}

}