import smartlook from 'smartlook-client';
import { store } from '../Models/Store';

export type EventTypes = 'Nav Projects'
	| 'Nav Orders'
	| 'Nav Profile'
	| 'Nav Logout'
	| 'Elements Bulk Import'
	| 'Downloaded Bulk Order Template'
	| 'Upload Bulk Import File'
	| 'Added Bulk Import File to Project'
	| 'Add Element'
	| 'Save Element Added'
	| 'Element Duplicated'
	| 'Saved Duplicated Elements'
	| 'Add to Order'
	| 'Delivery Instructions'
	| 'Order Request Submitted'
	| 'Single Element Add to Order'
	| 'Shutter Added'
	| 'Shutter Sketch Added'
	| 'Shutters Added Saved'
	| 'Shutter Bulk Add to Order'
	| 'Single List Shutter Add'
	| 'Aptus Code Guide'
	| 'Excel Export'
	| 'Search Bar Click'
	| 'Search Results Click';

export default class SmartlookService {
	static init() {
		if (this.isDisabled()) {
			return;
		}

		try {
			smartlook.init('98595b4b682ee7a532949af473bfa91f3030577d');
		} catch (e) {
			console.error('Smartlook already initialized');
		}
	}

	static identify() {
		if (store.loggedIn && !this.isDisabled()) {
			const userId = store.userId ?? '';
			const email = store.email ?? '';

			smartlook.identify(userId, {
				'email': email,
				'isAdmin': store.hasBackendAccess,
				'category': 'Ordering Tool'
			});
		}
	}

	static triggerEvent(eventType: EventTypes) {
		if (this.isDisabled()) {
			return;
		}

		smartlook.track(eventType, {});
	}

	static isDisabled() {
		return window.location.href.indexOf('localhost') !== -1;
	}
}