import React from 'react';
import Modal from "../../Modal/Modal";
import img_aptus_code_guide from "../../../../Assets/images/aptus-code-guide.jpg";
import {Button, Display} from "../../Button/Button";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import SmartlookService from '../../../../Services/SmartlookService';

@observer
export default class AptusCodeGuide extends React.Component {
    
    @observable
    private modalState:boolean = false;

    @action
    private onUpdateModalState = (display: boolean) => {
        if (display) {
            SmartlookService.triggerEvent('Aptus Code Guide');
        }

        this.modalState = display;
    };
    
    render() {
        return (
            <>
                <Modal
                    className='aptus-code-modal'
                    label="Aptus Code Guide"
                    isOpen={this.modalState}
                    onRequestClose={() => this.onUpdateModalState(false)}
                >
                    <img src={img_aptus_code_guide} className="modal__content"/>
                    <div key="actions" className="modal__actions">
                        <Button key="cancel" onClick={() => this.onUpdateModalState(false)} display={Display.None}>Close</Button>
                    </div>
                </Modal>
                <img src={img_aptus_code_guide} onClick={() => this.onUpdateModalState(true)}/>
            </>
        );
    }
    

}