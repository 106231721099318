/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from "Views/Components/Helpers/Common";
import { SecurityService } from "Services/SecurityService";
import { getModelDisplayName } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] off begin
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for AddressEntity here] off begin
		path: '/admin/addressentity',
		label: getModelDisplayName(Models.AddressEntity),
		entity: Models.AddressEntity,
		isMember: false
		// % protected region % [Override navigation link for AddressEntity here] end
	},
	{
		// % protected region % [Override navigation link for AptusAdminEntity here] off begin
		path: '/admin/aptusadminentity',
		label: getModelDisplayName(Models.AptusAdminEntity),
		entity: Models.AptusAdminEntity,
		isMember: true
		// % protected region % [Override navigation link for AptusAdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for InvitedUserEntity here] off begin
		path: '/admin/inviteduserentity',
		label: getModelDisplayName(Models.InvitedUserEntity),
		entity: Models.InvitedUserEntity,
		isMember: false
		// % protected region % [Override navigation link for InvitedUserEntity here] end
	},
	{
		// % protected region % [Override navigation link for OrderEntity here] off begin
		path: '/admin/orderentity',
		label: getModelDisplayName(Models.OrderEntity),
		entity: Models.OrderEntity,
		isMember: false
		// % protected region % [Override navigation link for OrderEntity here] end
	},
	{
		// % protected region % [Override navigation link for OrganisationEntity here] off begin
		path: '/admin/organisationentity',
		label: getModelDisplayName(Models.OrganisationEntity),
		entity: Models.OrganisationEntity,
		isMember: false
		// % protected region % [Override navigation link for OrganisationEntity here] end
	},
	{
		// % protected region % [Override navigation link for PartEntity here] off begin
		path: '/admin/partentity',
		label: getModelDisplayName(Models.PartEntity),
		entity: Models.PartEntity,
		isMember: false
		// % protected region % [Override navigation link for PartEntity here] end
	},
	{
		// % protected region % [Override navigation link for PriceVersionEntity here] off begin
		path: '/admin/priceversionentity',
		label: getModelDisplayName(Models.PriceVersionEntity),
		entity: Models.PriceVersionEntity,
		isMember: false
		// % protected region % [Override navigation link for PriceVersionEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProjectEntity here] off begin
		path: '/admin/projectentity',
		label: getModelDisplayName(Models.ProjectEntity),
		entity: Models.ProjectEntity,
		isMember: false
		// % protected region % [Override navigation link for ProjectEntity here] end
	},
	{
		// % protected region % [Override navigation link for UserEntity here] off begin
		path: '/admin/userentity',
		label: getModelDisplayName(Models.UserEntity),
		entity: Models.UserEntity,
		isMember: true
		// % protected region % [Override navigation link for UserEntity here] end
	},
	{
		// % protected region % [Override navigation link for CountryPartEntity here] off begin
		path: '/admin/countrypartentity',
		label: getModelDisplayName(Models.CountryPartEntity),
		entity: Models.CountryPartEntity,
		isMember: false
		// % protected region % [Override navigation link for CountryPartEntity here] end
	},
	{
		// % protected region % [Override navigation link for ElementEntity here] off begin
		path: '/admin/elemententity',
		label: getModelDisplayName(Models.ElementEntity),
		entity: Models.ElementEntity,
		isMember: false
		// % protected region % [Override navigation link for ElementEntity here] end
	},
	{
		// % protected region % [Override navigation link for ElementPartEntity here] off begin
		path: '/admin/elementpartentity',
		label: getModelDisplayName(Models.ElementPartEntity),
		entity: Models.ElementPartEntity,
		isMember: false
		// % protected region % [Override navigation link for ElementPartEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProductEntity here] off begin
		path: '/admin/productentity',
		label: getModelDisplayName(Models.ProductEntity),
		entity: Models.ProductEntity,
		isMember: false
		// % protected region % [Override navigation link for ProductEntity here] end
	},
	{
		// % protected region % [Override navigation link for ShutterEntity here] off begin
		path: '/admin/shutterentity',
		label: getModelDisplayName(Models.ShutterEntity),
		entity: Models.ShutterEntity,
		isMember: false
		// % protected region % [Override navigation link for ShutterEntity here] end
	},
	// % protected region % [Add any extra page links here] off begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return <Navigation
			className='nav__admin'
			orientation={Orientation.VERTICAL}
			linkGroups={this.getAdminNavLinks()}
			{...this.props} />;
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		let userLinks = links.filter(link => link.isMember).filter(this.filter);
		let entityLinks = links.filter(link => ! link.isMember).filter(this.filter);

		let linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] off begin
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{ path: '/admin', label: 'Home', icon: 'home', iconPos: 'icon-left' },
			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{path: "/admin/user", label: "All Users"},
						...userLinks.map(link => ({path: link.path, label: link.label}))
					]
				}
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						}
					})
				}
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] on begin
		const frontendLinks: ILink[] = [];
		frontendLinks.push(
			{
				path: '/order',
				label: 'Frontend',
				icon: 'arrow-right-up',
				iconPos: 'icon-left',
			}
		);
		linkGroups.push(frontendLinks);
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];

		// % protected region % [Add any additional links to otherLinkGroup here] off begin
		// % protected region % [Add any additional links to otherLinkGroup here] end

		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			// % protected region % [Modify the utility link group here] off begin
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			}
			// % protected region % [Modify the utility link group here] end
		);

		// % protected region % [Update the logout link here] off begin
		bottomlinkGroup.push(
			{
				path: '/logout',
				label: 'Logout',
				icon: 'room',
				iconPos: 'icon-left'
			}
		);
		// % protected region % [Update the logout link here] end

		// % protected region % [Add any additional links to bottomlinkGroup here] off begin
		// % protected region % [Add any additional links to bottomlinkGroup here] end

		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] off begin
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}