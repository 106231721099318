/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

export type orderStatus =
	// % protected region % [Override orderStatus keys here] off begin
	'REQUESTED' |
		'CONFIRMED' |
		'HOLD' |
		'DISPATCHED';
	// % protected region % [Override orderStatus keys here] end

export const orderStatusOptions: { [key in orderStatus]: string } = {
	// % protected region % [Override orderStatus display fields here] off begin
	REQUESTED: 'Requested',
	CONFIRMED: 'Confirmed',
	HOLD: 'Hold',
	DISPATCHED: 'Dispatched',
	// % protected region % [Override orderStatus display fields here] end
};

export type countries =
	// % protected region % [Override countries keys here] off begin
	'AUSTRALIA' |
		'CANADA' |
		'EUROPE' |
		'NEW_ZEALAND' |
		'UNITED_KINGDOM' |
		'UNITED_STATES_OF_AMERICA';
	// % protected region % [Override countries keys here] end

export const countriesOptions: { [key in countries]: string } = {
	// % protected region % [Override countries display fields here] off begin
	AUSTRALIA: 'Australia',
	CANADA: 'Canada',
	EUROPE: 'Europe',
	NEW_ZEALAND: 'New Zealand',
	UNITED_KINGDOM: 'United Kingdom',
	UNITED_STATES_OF_AMERICA: 'United States of America',
	// % protected region % [Override countries display fields here] end
};

export type shutterType =
	// % protected region % [Override shutterType keys here] off begin
	'TOP' |
		'BOTTOM' |
		'SITE_TEMPLATE';
	// % protected region % [Override shutterType keys here] end

export const shutterTypeOptions: { [key in shutterType]: string } = {
	// % protected region % [Override shutterType display fields here] off begin
	TOP: 'Top',
	BOTTOM: 'Bottom',
	SITE_TEMPLATE: 'Site Template',
	// % protected region % [Override shutterType display fields here] end
};

export type state =
	// % protected region % [Override state keys here] off begin
	'WISHLIST' |
		'CART' |
		'ORDER';
	// % protected region % [Override state keys here] end

export const stateOptions: { [key in state]: string } = {
	// % protected region % [Override state display fields here] off begin
	WISHLIST: 'Wishlist',
	CART: 'Cart',
	ORDER: 'Order',
	// % protected region % [Override state display fields here] end
};

// % protected region % [Add any extra enums here] on begin
export type RequestState = 'loading' | 'error' | 'done';

export const currencyOptions: { [key in countries]: string } = {
	AUSTRALIA: 'AUD',
	CANADA: 'CAD',
	EUROPE: 'EUR',
	NEW_ZEALAND: 'NZD',
	UNITED_KINGDOM: 'GBP',
	UNITED_STATES_OF_AMERICA: 'USD',
};
// % protected region % [Add any extra enums here] end
