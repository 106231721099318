/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsPriceVersionEntity } from 'Models/Security/Acl/VisitorsPriceVersionEntity';
import { AptusAdminPriceVersionEntity } from 'Models/Security/Acl/AptusAdminPriceVersionEntity';
import { UserPriceVersionEntity } from 'Models/Security/Acl/UserPriceVersionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPriceVersionEntityAttributes extends IModelAttributes {
	version: number;

	orderss: Array<Models.OrderEntity | Models.IOrderEntityAttributes>;
	partss: Array<Models.PartEntity | Models.IPartEntityAttributes>;
	projectPriceVersions: Array<Models.ProjectEntity | Models.IProjectEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PriceVersionEntity', 'Price Version')
// % protected region % [Customise your entity metadata here] end
export default class PriceVersionEntity extends Model implements IPriceVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPriceVersionEntity(),
		new AptusAdminPriceVersionEntity(),
		new UserPriceVersionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public version: number;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Orders'] off begin
		name: "Orderss",
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.OrderEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'orderss',
			oppositeEntity: () => Models.OrderEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Orders'] end
	})
	public orderss: Models.OrderEntity[] = [];

	/**
	 * Part price list for different versions
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parts'] off begin
		name: "Partss",
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.PartEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'partss',
			oppositeEntity: () => Models.PartEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Parts'] end
	})
	public partss: Models.PartEntity[] = [];

	/**
	 * A project must have a price version
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project Price Version'] off begin
		name: "Project Price Versions",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.ProjectEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'projectPriceVersions',
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Project Price Version'] end
	})
	public projectPriceVersions: Models.ProjectEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPriceVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPriceVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.orderss !== undefined && Array.isArray(attributes.orderss)) {
				for (const model of attributes.orderss) {
					if (model instanceof Models.OrderEntity) {
						this.orderss.push(model);
					} else {
						this.orderss.push(new Models.OrderEntity(model));
					}
				}
			}
			if (attributes.partss !== undefined && Array.isArray(attributes.partss)) {
				for (const model of attributes.partss) {
					if (model instanceof Models.PartEntity) {
						this.partss.push(model);
					} else {
						this.partss.push(new Models.PartEntity(model));
					}
				}
			}
			if (attributes.projectPriceVersions !== undefined && Array.isArray(attributes.projectPriceVersions)) {
				for (const model of attributes.projectPriceVersions) {
					if (model instanceof Models.ProjectEntity) {
						this.projectPriceVersions.push(model);
					} else {
						this.projectPriceVersions.push(new Models.ProjectEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		orderss {
			${Models.OrderEntity.getAttributes().join('\n')}
		}
		partss {
			${Models.PartEntity.getAttributes().join('\n')}
		}
		projectPriceVersions {
			${Models.ProjectEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			orderss: {},
			partss: {},
			projectPriceVersions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'orderss',
							'partss',
							'projectPriceVersions',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.version.toString();
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PriceVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(PriceVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
