import * as Enums from "../Models/Enums";

let defaultCountry: Enums.countries | undefined = undefined;

/**
 * Format the incoming price
 */
export default function formatPrice(price: number | undefined, country?: Enums.countries): string {
	if (price == undefined) {
		return '$0.00';
	}

	// If a country has not been passed in, default to the last country used
	// This is to handle some cases where the code did not have access to the last used country (this just simplifies
	// passing the country through)
	if (country == null) {
		country = defaultCountry;
	} else {
		defaultCountry = country;
	}

	const decimalPlace = price == Math.round(price) ? 0 : 2;
	const currencyName = country ? Enums.currencyOptions[country] : '';
	
	let currencySymbol; 
	switch (country) {
		case "EUROPE": currencySymbol = '€'; break; 
		case "UNITED_KINGDOM": currencySymbol = '£'; break;
		default: currencySymbol = '$'; break;
	}
	
	return `${currencySymbol}${price.toFixed(decimalPlace)} ${currencyName}`;
}