/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsCountryPartEntity } from 'Models/Security/Acl/VisitorsCountryPartEntity';
import { AptusAdminCountryPartEntity } from 'Models/Security/Acl/AptusAdminCountryPartEntity';
import { UserCountryPartEntity } from 'Models/Security/Acl/UserCountryPartEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICountryPartEntityAttributes extends IModelAttributes {
	assemblyCode: string;
	price: number;
	country: Enums.countries;
	rebarType: string;
	rebarPrice: number;

	partId?: string;
	part?: Models.PartEntity | Models.IPartEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CountryPartEntity', 'Country Part')
// % protected region % [Customise your entity metadata here] end
export default class CountryPartEntity extends Model implements ICountryPartEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsCountryPartEntity(),
		new AptusAdminCountryPartEntity(),
		new UserCountryPartEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Assembly Code'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Assembly Code',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public assemblyCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Assembly Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Country'] off begin
	/**
	 * The country this part is associated with
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Country',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.countriesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.countriesOptions),
		displayFunction: (attribute: Enums.countries) => Enums.countriesOptions[attribute],
	})
	public country: Enums.countries;
	// % protected region % [Modify props to the crud options here for attribute 'Country'] end

	// % protected region % [Modify props to the crud options here for attribute 'Rebar Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Rebar Type',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public rebarType: string;
	// % protected region % [Modify props to the crud options here for attribute 'Rebar Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Rebar Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Rebar Price',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public rebarPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Rebar Price'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Part'] off begin
		name: 'Part',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.PartEntity,
		// % protected region % [Modify props to the crud options here for reference 'Part'] end
	})
	public partId?: string;
	@observable
	@attribute({isReference: true})
	public part: Models.PartEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICountryPartEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICountryPartEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.assemblyCode !== undefined) {
				this.assemblyCode = attributes.assemblyCode;
			}
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.country !== undefined) {
				this.country = attributes.country;
			}
			if (attributes.rebarType !== undefined) {
				this.rebarType = attributes.rebarType;
			}
			if (attributes.rebarPrice !== undefined) {
				this.rebarPrice = attributes.rebarPrice;
			}
			if (attributes.partId !== undefined) {
				this.partId = attributes.partId;
			}
			if (attributes.part !== undefined) {
				if (attributes.part === null) {
					this.part = attributes.part;
				} else {
					if (attributes.part instanceof Models.PartEntity) {
						this.part = attributes.part;
						this.partId = attributes.part.id;
					} else {
						this.part = new Models.PartEntity(attributes.part);
						this.partId = this.part.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		part {
			${Models.PartEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.assemblyCode;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CountryPartEntity.prototype, 'created');
CRUD(modifiedAttr)(CountryPartEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
