/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsOrganisationEntity } from 'Models/Security/Acl/VisitorsOrganisationEntity';
import { AptusAdminOrganisationEntity } from 'Models/Security/Acl/AptusAdminOrganisationEntity';
import { UserOrganisationEntity } from 'Models/Security/Acl/UserOrganisationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {store} from "../Store";
import gql from 'graphql-tag';
// % protected region % [Add any further imports here] end

export interface IOrganisationEntityAttributes extends IModelAttributes {
	name: string;

	invitedUserss: Array<Models.InvitedUserEntity | Models.IInvitedUserEntityAttributes>;
	organisationAddressess: Array<Models.AddressEntity | Models.IAddressEntityAttributes>;
	organisationUserss: Array<Models.UserEntity | Models.IUserEntityAttributes>;
	ownedProjectss: Array<Models.ProjectEntity | Models.IProjectEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('OrganisationEntity', 'Organisation')
// % protected region % [Customise your entity metadata here] end
export default class OrganisationEntity extends Model implements IOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsOrganisationEntity(),
		new AptusAdminOrganisationEntity(),
		new UserOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Organisation name
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	/**
	 * Users must be invited to register
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Invited Users'] off begin
		name: "Invited Userss",
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.InvitedUserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'invitedUserss',
			oppositeEntity: () => Models.InvitedUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Invited Users'] end
	})
	public invitedUserss: Models.InvitedUserEntity[] = [];

	/**
	 * Organisation can have multiple addresses (Head office, Yard address)
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation Addresses'] off begin
		name: "Organisation Addressess",
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.AddressEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'organisationAddressess',
			oppositeEntity: () => Models.AddressEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisation Addresses'] end
	})
	public organisationAddressess: Models.AddressEntity[] = [];

	/**
	 * Organisation has many users
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation Users'] off begin
		name: "Organisation Userss",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.UserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'organisationUserss',
			oppositeEntity: () => Models.UserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisation Users'] end
	})
	public organisationUserss: Models.UserEntity[] = [];

	/**
	 * Organisation has many projects
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Owned Projects'] off begin
		name: "Owned Projectss",
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.ProjectEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ownedProjectss',
			oppositeEntity: () => Models.ProjectEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Owned Projects'] end
	})
	public ownedProjectss: Models.ProjectEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.invitedUserss !== undefined && Array.isArray(attributes.invitedUserss)) {
				for (const model of attributes.invitedUserss) {
					if (model instanceof Models.InvitedUserEntity) {
						this.invitedUserss.push(model);
					} else {
						this.invitedUserss.push(new Models.InvitedUserEntity(model));
					}
				}
			}
			if (attributes.organisationAddressess !== undefined && Array.isArray(attributes.organisationAddressess)) {
				for (const model of attributes.organisationAddressess) {
					if (model instanceof Models.AddressEntity) {
						this.organisationAddressess.push(model);
					} else {
						this.organisationAddressess.push(new Models.AddressEntity(model));
					}
				}
			}
			if (attributes.organisationUserss !== undefined && Array.isArray(attributes.organisationUserss)) {
				for (const model of attributes.organisationUserss) {
					if (model instanceof Models.UserEntity) {
						this.organisationUserss.push(model);
					} else {
						this.organisationUserss.push(new Models.UserEntity(model));
					}
				}
			}
			if (attributes.ownedProjectss !== undefined && Array.isArray(attributes.ownedProjectss)) {
				for (const model of attributes.ownedProjectss) {
					if (model instanceof Models.ProjectEntity) {
						this.ownedProjectss.push(model);
					} else {
						this.ownedProjectss.push(new Models.ProjectEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		invitedUserss {
			${Models.InvitedUserEntity.getAttributes().join('\n')}
		}
		organisationAddressess {
			${Models.AddressEntity.getAttributes().join('\n')}
		}
		organisationUserss {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		ownedProjectss {
			${Models.ProjectEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			invitedUserss: {},
			organisationAddressess: {},
			organisationUserss: {},
			ownedProjectss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'invitedUserss',
							'organisationAddressess',
							'organisationUserss',
							'ownedProjectss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public static async fetchOrganisations() {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						organisationEntitys {
							id
							name
						}
					}`,
				fetchPolicy: 'network-only',
			});
		return results.data['organisationEntitys'].map((r: any) => new this(r));
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(OrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
