import React from 'react';
import { ProjectEntity } from '../../../../Models/Entities';
import { observer } from 'mobx-react';
import {action, observable} from 'mobx';
import {RouteComponentProps} from "react-router/index";
import {ApiQueryParams} from "../../ModelCollection/ModelAPIQuery";
import {ShutterCollection} from "../ProjectCollection/ShutterCollection";
import {OrderCollection} from "../ProjectCollection/OrderCollection";

interface IOrderListTileProps extends RouteComponentProps {
    project: ProjectEntity;
}

@observer
export default class OrdersTab extends React.Component<IOrderListTileProps> {

    render() {
        return (
            <div className='orders-tab'>
                <div className='orders-list-block'>
                    <OrderCollection
                        customFilter={{
                            projectId: this.props.project.id,
                        }}
                    />
                </div>
            </div>
        );
    };

}