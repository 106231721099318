import React from 'react';
import RequestWrap from '../Components/RequestWrap/RequestWrap';
import { OrderEntity } from '../../Models/Entities';
import { store } from '../../Models/Store';
import { Button, Colors} from '../Components/Button/Button';
import { RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import Navigation, { Orientation } from '../Components/Navigation/Navigation';
import { getFrontendNavLinks } from '../FrontendNavLinks';
import SecuredPage from '../Components/Security/SecuredPage';

interface OrderSuccessPageRouteProps {
	id: string;
}

export default class OrderSuccessPage extends React.Component<RouteComponentProps<OrderSuccessPageRouteProps>> {

	render() {
		return (
			<SecuredPage groups={['Super Administrators', 'AptusAdmin', 'User']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				<div className="body-content">
					<RequestWrap request={this.performRequest} processData={this.processData}>
						{order => <OrderSuccess order={order} />}
					</RequestWrap>
				</div>
			</SecuredPage>
		);
	}

	private performRequest = async () => {
		const orderId = this.props.match.params.id;
		const orderQuery = gql`query fetchOrder($args: [WhereExpressionGraph]) {
            orderEntity(where: $args) {
				${OrderEntity.getAttributes()}
            }
        }`;

		const queryArgs = [{path: "id", comparison: "equal", value: orderId}];

		const results = await store.apolloClient
			.query({
				query: orderQuery,
				variables: {
					"args": queryArgs
				},
				fetchPolicy: 'network-only',
			});

		return new OrderEntity(results.data['orderEntity']);
	};

	private processData = (entity: OrderEntity) => entity;
}

export function OrderSuccess({ order }: { order: OrderEntity }) {
	const returnToProject = () =>
		store.routerHistory.push(`/project/${order.projectId}`);

	return (
		<div className="empty-dashboard">
			<div className="empty-dashboard-content order-success">
				<h1>Order request submitted</h1>
				<h5>PO# {order.orderNumber}</h5>
				<p>
					The Aptus team will review your order and send you an Order Confirmation
					shortly.
				</p>
				<p>In the meantime, email us at
					<a href='mailto:orders@aptus.systems'> orders@aptus.systems </a>
					for order inquires.
				</p>
				<Button
					onClick={returnToProject}
					className="new-project-btn"
					colors={Colors.Primary}>
					Return to Project
				</Button>
			</div>
			<div className="spacing-div"/>
			<div className="empty-dashboard-background"/>
		</div>
	);
}